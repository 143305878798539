package com.wicupp.beaver.js.core.connectivity

import com.wicupp.beaver.core.connectivity.ConnectivityChange

class ConnectivityChangeImpl: ConnectivityChange {
    val onConnectivityChangeList = mutableListOf<(connected: Boolean) -> Unit>()
    val connectivityChangeJSImpl = ConnectivityChangeJSImpl { connected ->
        onConnectivityChangeList.forEach {
            it(connected)
        }
    }

    override fun subscribeToConnectivityChange(onConnectivityChange: (connected: Boolean) -> Unit) {
        onConnectivityChangeList.add(onConnectivityChange)
    }
}