package com.wicupp.safetymap.dashboard.toolbar

import com.wicupp.beaver.web.WebCore
import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.component.ImageView
import com.wicupp.beaver.web.view.component.SmartIcon
import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.FrameLayout
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.layout.LinearLayout
import com.wicupp.safetymap.dashboard.menu.MobileMenuView

class ToolbarView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "app-toolbar-toolbar-view"
    }

    override fun layoutName() = "view-toolbar"
    var listener: Listener? = null

    override fun onCreate() {
        super.onCreate()

        val menuContainer = findViewById<FrameLayout>("view-toolbar-menu")

        val menu = createLayout(MobileMenuView::class.js)

        val configButton = findViewById<LinearLayout>("view-toolbar-configuration-btn").apply {
            onClick {
                menu.show()
            }
        }

        findViewById<ImageView>("view-project-add").onClick {
            listener?.onAddToProjectRequested()
        }

        val projectSettingsButton = findViewById<ButtonView>("view-toolbar-project-settings").apply {
            onClick {
                listener?.onProjectSettingsRequested()
            }
        }
        if (WebCore.isMobileScreen()) {
            menuContainer.appendView(menu)
        } else {
            configButton.setVisibility(View.GONE)
            projectSettingsButton.setVisibility(View.GONE)
        }

        menu.listener = object : MobileMenuView.Listener {

            override fun onSettingsRequested() {
                menu.hide()
                listener?.onSettingsRequested()
            }

            override fun onLogoutRequested() {
                menu.hide()
                listener?.onLogoutRequested()
            }

            override fun onLoginRequested() {
                menu.hide()
                listener?.onLoginRequested()
            }

            override fun onLegalsRequested() {
                menu.hide()
                listener?.onLegalsRequested()
            }

            override fun onHelpCenterRequested() {
                menu.hide()
                listener?.onHelpCenterRequested()
            }

            override fun onRefugesRequested() {
                menu.hide()
                listener?.onRefugesRequested()
            }

            override fun onReportIncidentsRequested() {
                menu.hide()
                listener?.onReportIncidentsRequested()
            }

            override fun onApiRequested() {
                menu.hide()
                listener?.onApiRequested()
            }

            override fun onProjectSettingsRequested() {
                menu.hide()
                listener?.onProjectSettingsRequested()
            }
        }
    }

    interface Listener {
        fun onLogoutRequested()
        fun onLoginRequested()
        fun onSettingsRequested()
        fun onLegalsRequested()
        fun onHelpCenterRequested()
        fun onRefugesRequested()
        fun onReportIncidentsRequested()
        fun onApiRequested()
        fun onAddToProjectRequested()
        fun onProjectSettingsRequested()
    }
}