package com.wicupp.safetymap.dashboard.utils

import com.wicupp.safetymap.dashboard.data.model.Refuge

val Refuge.Category.stringRes: String
    get() = when (this) {
        Refuge.Category.BAR -> "refuge-bar"
        Refuge.Category.POLICE_STATION -> "refuge-police-station"
        Refuge.Category.RESTAURANT -> "refuge-restaurant"
        Refuge.Category.NIGHT_CLUB -> "refuge-night-club"
        Refuge.Category.MARKET -> "refuge-store"
        Refuge.Category.HOTEL -> "refuge-hotel"
        Refuge.Category.OTHERS,
        Refuge.Category.UNKNOWN -> "refuge-others"
    }

val Refuge.Category.iconRes: Pair<String, String>
    get() = when (this) {
        Refuge.Category.BAR -> Pair("beaver-icons", "\\e013")
        Refuge.Category.POLICE_STATION -> Pair("beaver-icons", "\\e018")
        Refuge.Category.RESTAURANT -> Pair("beaver-icons", "\\e019")
        Refuge.Category.NIGHT_CLUB -> Pair("beaver-icons", "\\e016")
        Refuge.Category.MARKET -> Pair("beaver-icons", "\\e015")
        Refuge.Category.HOTEL -> Pair("beaver-icons", "\\e014")
        Refuge.Category.OTHERS,
        Refuge.Category.UNKNOWN -> Pair("beaver-icons", "\\e017")
    }