package com.wicupp.safetymap.dashboard.board.refuge.edit.opening

import com.wicupp.beaver.web.view.component.SmartIcon
import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.layout.LinearLayout
import com.wicupp.safetymap.dashboard.utils.Day
import com.wicupp.safetymap.dashboard.utils.OpeningHoursPeriod

class OpeningHoursItemView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "open-hours-item-view"
    }

    override fun layoutName() = "view-open-hours-item"

    private lateinit var dayText: TextView
    private lateinit var zoneText: TextView

    var openingHours: OpeningHoursPeriod? = null
    var listener: Listener? = null

    override fun onCreate() {
        super.onCreate()

        dayText = findViewById("view-open-hours-item-day")
        zoneText = findViewById("view-open-hours-item-zone")
        openingHours?.let {
            dayText.setText(getString(Day.getByOpeningDay(it.open.day).stringKey))
            zoneText.setText(getZone(it))
        }
        findViewById<SmartIcon>("view-open-hours-item-delete").onClick {
            listener?.onDeleteRequested()
        }
    }

    private fun getZone(openingHours: OpeningHoursPeriod): String {
        val endDay = if (openingHours.open.day != openingHours.close!!.day) {
            " ${getString(Day.getByOpeningDay(openingHours.close.day).stringKey)}"
        } else ""
        return "from ${openingHours.open.time.format24hTime} to$endDay ${openingHours.close.time.format24hTime}"
    }

    interface Listener {
        fun onDeleteRequested()
    }
}