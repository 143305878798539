package com.wicupp.beaver.flux

open class RootNotifier(private val initModel: CoreModel): Notifier() {
    var oldModel = initModel

    override fun updateModel(oldModel: CoreModel, newModel: CoreModel) {
        this.oldModel = newModel
    }

    override fun clean() {
        this.oldModel = this.initModel
    }

    override fun get(): CoreModel {
        return oldModel
    }
}