package com.wicupp.safetymap.dashboard.settings.email

import com.wicupp.artifice.otp.OtpView
import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.component.EditText
import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.layout.LinearLayout
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory
import com.wicupp.safetymap.dashboard.settings.email.EditEmailContract

class EditEmailView(cv: ContextView): Layout(cv), EditEmailContract.View {

    companion object {
        const val TAG_NAME = "app-edit-email-view"
    }

    override fun layoutName() = "view-edit-email"
    var listener: Listener? = null
    var email: String? = null
        set(value) {
            descriptionView?.setText(
                getString("verification-code-sent-to", value)
            )
            field = value
        }

    private val presenter: EditEmailContract.Presenter = Inject.get()
    private var verificationCodeLayout: LinearLayout? = null
    private var enterEmailLayout: LinearLayout? = null
    private var emailConfirmationLayout: LinearLayout? = null
    private var nextButton: ButtonView? = null
    private var confirmButton: ButtonView? = null
    private var descriptionView: TextView? = null

    override fun onCreate() {
        super.onCreate()

        descriptionView = findViewById<TextView>("view-edit-email-code-description").apply {
            email?.let {
                setText(
                    getString("verification-code-sent-to", it)
                )
            }
        }

        verificationCodeLayout = findViewById<LinearLayout>("view-edit-email-enter-code")
        enterEmailLayout = findViewById<LinearLayout>("view-edit-email-enter-email")
        emailConfirmationLayout = findViewById<LinearLayout>("view-edit-email-confirmation")

        nextButton = findViewById<ButtonView>("view-edit-email-next").apply {
            onClick {
                presenter.requestVerificationCode(
                    findViewById<EditText>("view-edit-email-input").getValue()
                )
            }
        }

        confirmButton = findViewById<ButtonView>("view-edit-email-button").apply {
            onClick {
                presenter.setVerificationCode(
                    findViewById<OtpView>("view-edit-email-code-input").getValue().toInt()
                )
            }
        }

        findViewById<ButtonView>("view-edit-email-ok-button").onClick {
            listener?.onEmailEdited()
        }
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
    }

    override fun onDetached() {
        presenter.detachView(this)
        super.onDetached()
    }

    override fun showLoadingIndicator(show: Boolean) {
        if (show) {
            nextButton?.startLoading()
            confirmButton?.startLoading()
        } else {
            nextButton?.stopLoading()
            confirmButton?.stopLoading()
        }
    }

    override fun showVerificationCodeView() {
        enterEmailLayout?.setVisibility(View.GONE, true)
        emailConfirmationLayout?.setVisibility(View.GONE, true)
        verificationCodeLayout?.setVisibility(View.VISIBLE, true)
    }

    override fun showConfirmation() {
        enterEmailLayout?.setVisibility(View.GONE, true)
        emailConfirmationLayout?.setVisibility(View.VISIBLE, true)
        verificationCodeLayout?.setVisibility(View.GONE, true)
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    interface Listener {
        fun onEmailEdited()
    }
}