package com.wicupp.safetymap.dashboard.data.api

import com.wicupp.beaver.request.ApiRequest
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.beaver.request.Parameter
import com.wicupp.beaver.utils.JsonUtils
import com.wicupp.safetymap.dashboard.data.model.SubscriptionPlan
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject

class SubscriptionApi(private val apiRequest: ApiRequest) {

    fun openCheckoutSession(
        subscriptionPlan: SubscriptionPlan,
        listener: ApiListener<String>
    ) {
        val parameter = Parameter.Builder()
        parameter.addParameter("plan", subscriptionPlan.value)
        apiRequest.post(
            ApiUrl.OPEN_CHECKOUT_SESSION,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    val url = body.jsonObject["url"]
                    listener.onSuccess(
                        OpenCheckoutSessionResponse(
                            JsonUtils.getString(
                                url
                            )
                        )
                    )
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }
            }, parameter.build(), null)
    }

    fun confirmSubscription(
        sessionId: String,
        listener: ApiListener<Unit>
    ) {
        val parameter = Parameter.Builder()
        parameter.addParameter("sessionId", sessionId)
        apiRequest.post(
            ApiUrl.CONFIRM_SUBSCRIPTION,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    listener.onSuccess(UnitResponse())
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }
            }, parameter.build(), null)
    }

    fun getSubscriptionDashboard(
        listener: ApiListener<String>
    ) {
        apiRequest.get(
            ApiUrl.GET_SUBSCRIPTION_DASHBOARD,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    val url = body.jsonObject["url"]
                    listener.onSuccess(
                        GetDashboardResponse(
                            JsonUtils.getString(
                                url
                            )
                        )
                    )
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }
            }, Parameter.Builder().build(),
            null,
            isCacheEnabled = false,
            version = 2
        )
    }

    class OpenCheckoutSessionResponse(override var body: String) : ApiResponse<String>()
    class GetDashboardResponse(override var body: String) : ApiResponse<String>()
}