package com.wicupp.safetymap.dashboard

import com.wicupp.beaver.core.BuildConfig
import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.core.routing.PendingRoute
import com.wicupp.beaver.js.core.Application
import com.wicupp.beaver.js.core.logger.DefaultLogConsumer
import com.wicupp.beaver.js.core.routing.RouteUrlImpl
import com.wicupp.beaver.js.storage.KeyValueStorageImpl
import com.wicupp.beaver.js.stripe.StripeConfiguration
import com.wicupp.beaver.storage.KeyValueStorage
import com.wicupp.beaver.web.WebContextImpl
import com.wicupp.safetymap.dashboard.routing.AppRouteConfig
import com.wicupp.safetymap.dashboard.board.MainActivity
import com.wicupp.safetymap.dashboard.storage.StorageKey

fun main() {
    SafeMapApplication().run()
}

class SafeMapApplication : Application() {
    override val launchActivity = MainActivity::class.js
    override val supportedLanguages: Array<String> = arrayOf("fr", "en", "es")
    override val appConfig = SafetymapConfig
    private lateinit var storage: KeyValueStorage
    private val tables = arrayOf("requestsKeyValue", "imagesKeyValue", "usersKeyValue", StorageKey.USER_STORAGE_KEY + "KeyValue")

    override fun onConfigure() {
        webContext = WebContextImpl()
        storage = KeyValueStorageImpl()

        PendingRoute.provide(AppRouteConfig(), RouteUrlImpl())
        StripeConfiguration.configure(
            BuildConfig.getField("globalStripeClientId"),
            BuildConfig.getField("globalStripePk"))

        super.onConfigure()
    }

    override fun onDefineStorage(storageInitialized: () -> Unit) {
        super.onDefineStorage {
            storage.init(tables) {
                storageInitialized()
            }
        }
    }

    override fun onDefineLayout() = AppLayoutDefinition()

    override fun onPrepareInjection() {
        AppModule(context, activityContext, webContext, storage)
    }

    override fun onCreated() {
        Logger.addConsumer(DefaultLogConsumer())
    }
}