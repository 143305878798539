package com.wicupp.beaver.core

import kotlin.native.concurrent.ThreadLocal

class BuildConfig {

    @ThreadLocal
    companion object: BuildConfigInterface {

        private var instance: BuildConfigInterface? = null

        fun provideImpl(instance: BuildConfigInterface) {
            this.instance = instance
        }

        override fun debug(): Boolean {
            return getInstance().debug()
        }

        override fun release(): Boolean {
            return getInstance().release()
        }

        override fun getField(key: String): String {
            return getInstance().getField(key)
        }

        private fun getInstance(): BuildConfigInterface {
            return instance ?: throw IllegalStateException("Missing implementation for BuildConfig")
        }
    }
}