package com.wicupp.safetymap.dashboard.ui

import com.wicupp.beaver.web.view.component.ImageView
import com.wicupp.beaver.web.view.component.SmartIcon
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout

class CategoryIconView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "category-icon-view"
    }

    override fun layoutName() = "view-category-icon"

    private var smartIcon: SmartIcon? = null

    var iconRes: Pair<String, String>? = null
        set(value) {
            value?.let {
                smartIcon?.setFont(it.first)
                smartIcon?.setIcon(it.second)
            }
            field = value
        }

    override fun onCreate() {
        super.onCreate()

        smartIcon = findViewById("view-category-icon-font")
        iconRes?.let {
            smartIcon?.setFont(it.first)
            smartIcon?.setIcon(it.second)
        }
    }
}