package com.wicupp.safetymap.dashboard.subscription.cancel

import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory

class SubscriptionCancelView(cv: ContextView): Layout(cv), SubscriptionCancelContract.View {

    companion object {
        const val TAG_NAME = "subscription-cancel-view"
    }

    override fun layoutName() = "view-subscription-cancel"

    private val presenter: SubscriptionCancelContract.Presenter = Inject.get()
    var listener: Listener? = null

    override fun onCreate() {
        super.onCreate()
        findViewById<ButtonView>("view-subscription-cancel-button").onClick {
            listener?.onFinishRequested()
        }
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
    }

    override fun onDetached() {
        presenter.detachView(this)
        super.onDetached()
    }

    override fun showLoadingIndicator(show: Boolean) {
        // TODO
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    interface Listener {
        fun onFinishRequested()
    }
}