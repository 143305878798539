package com.wicupp.safetymap.dashboard.board

import com.wicupp.beaver.core.BuildConfig
import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.WebContext
import com.wicupp.beaver.web.WebCore
import com.wicupp.beaver.web.view.activity.Activity
import com.wicupp.beaver.web.view.activity.ActivityContext
import com.wicupp.beaver.web.view.activity.Intent
import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.layout.FrameLayout
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.smartview.AlertDialog
import com.wicupp.safetymap.dashboard.SafetymapConfig
import com.wicupp.safetymap.dashboard.data.model.SubscriptionPlan
import com.wicupp.safetymap.dashboard.menu.MenuView
import com.wicupp.safetymap.dashboard.onboarding.OnboardingActivity
import com.wicupp.safetymap.dashboard.subscription.SubscriptionActivity
import com.wicupp.safetymap.dashboard.sign.SignActivity
import com.wicupp.safetymap.dashboard.toolbar.ToolbarView

class MainActivity(activityContext: ActivityContext): Activity(activityContext,
    "activity-main",
    "main") {

    companion object {

        fun startActivity(activity: Activity) {
            activity.startActivity(Intent(MainActivity::class.js).apply {
                cleanHistory()
                putExtra(SignActivity.NO_HISTORY_EXTRA, true)
            })
        }
    }

    private var mainView: MainView? = null
    private val webContext: WebContext = Inject.get()

    override fun onCreate(activityContext: ActivityContext) {
        super.onCreate(activityContext)
        val toolbarView = findViewById<ToolbarView>("activity-main-toolbar")

        val menuContainerView = findViewById<FrameLayout>("activity-main-view-menu")
        val menuView = Layout.createLayout(MenuView::class.js).apply {
            addClass("fit-width")
            addClass("fit-height")
        }

        if (!WebCore.isMobileScreen()) {
            menuContainerView.appendView(menuView)
        } else {
            menuContainerView.setVisibility(View.GONE)
        }

        mainView = findViewById("activity-main-view")

        mainView?.listener = object : MainView.Listener {
            override fun onSigninRequested() {
                SignActivity.startActivityWithoutHistory(this@MainActivity)
            }

            override fun onPricingRequested() {
                if (BuildConfig.debug()) {
                    SignActivity.startActivity(this@MainActivity)
                } else {
                    webContext.getWebView().open(
                        "https://www.safetymap.io/project/overview",
                        target = "_self"
                    )
                }
            }

            override fun onSubscriptionRequested(subscriptionPlan: SubscriptionPlan) {
                SubscriptionActivity.startActivityWithPlan(this@MainActivity, subscriptionPlan)
            }

            override fun onCheckoutSuccessRequested(sessionId: String) {
                SubscriptionActivity.startActivityWithSuccess(this@MainActivity, sessionId)
            }

            override fun onCheckoutCancelRequested() {
                SubscriptionActivity.startActivityWithCancel(this@MainActivity)
            }

            override fun onOnboardingRequested() {
                OnboardingActivity.startActivity(this@MainActivity)
            }
        }

        toolbarView.listener = object : ToolbarView.Listener {
            override fun onLogoutRequested() {
                mainView?.requestLogout()
            }

            override fun onLoginRequested() {
                SignActivity.startActivity(this@MainActivity)
            }

            override fun onSettingsRequested() {
                mainView?.showSettings()
            }

            override fun onLegalsRequested() {
                mainView?.showLegals()
            }

            override fun onHelpCenterRequested() {
                webContext.getWebView().open(SafetymapConfig.faqUrl)
            }

            override fun onRefugesRequested() {
                mainView?.routeToRefuges()
            }

            override fun onReportIncidentsRequested() {
                mainView?.routeToReportIncidents()
            }

            override fun onApiRequested() {
                mainView?.routeToApi()
            }

            override fun onAddToProjectRequested() {
                mainView?.onAddToProjectRequested()
            }

            override fun onProjectSettingsRequested() {
                mainView?.routeToProjectSettings()
            }
        }

        menuView.listener = object : MenuView.Listener {
            override fun onLogoutRequested() {
                mainView?.requestLogout()
            }

            override fun onLoginRequested() {
                SignActivity.startActivity(this@MainActivity)
            }

            override fun onSettingsRequested() {
                mainView?.showSettings()
            }

            override fun onLegalsRequested() {
                mainView?.showLegals()
            }

            override fun onHelpCenterRequested() {
                webContext.getWebView().open(SafetymapConfig.faqUrl)
            }

            override fun onRefugesRequested() {
                mainView?.routeToRefuges()
            }

            override fun onReportIncidentsRequested() {
                mainView?.routeToReportIncidents()
            }

            override fun onApiRequested() {
                mainView?.routeToApi()
            }

            override fun onProjectSettingsRequested() {
                mainView?.routeToProjectSettings()
            }
        }
    }

    override fun onResume() {
        super.onResume()
        Logger.d("onResume")
    }

    override fun onPause() {
        Logger.d("onPause")
        super.onPause()
    }

    override fun onDestroy() {
        Logger.d("onDestroy")
        super.onDestroy()
    }

    override fun onBack() {
        super.onBack()
        Logger.d("onBack")
        mainView?.requestRouting()
    }
}