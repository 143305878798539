package com.wicupp.safetymap.dashboard.sign

import com.wicupp.artifice.otp.OtpView
import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.component.EditText
import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout

class SignVerifCodeView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "app-sign-verif-code-view"
    }

    override fun layoutName() = "view-sign-verif-code"
    var listener: Listener? = null
    var email: String? = null
        set(value) {
            descriptionView?.setText(
                getString("verification-code-sent-to", value)
            )
            field = value
        }

    private var buttonView: ButtonView? = null
    private var descriptionView: TextView? = null

    override fun onCreate() {
        super.onCreate()

        descriptionView = findViewById<TextView>("view-sign-verif-code-description")

        buttonView = findViewById<ButtonView>("view-sign-verif-code-button").apply {
            onClick {
                listener?.onCodeProvided(
                    findViewById<OtpView>("view-sign-verif-code-input").getValue()
                )
            }
        }

        findViewById<ButtonView>("view-sign-verif-code-cancel-button").onClick {
            listener?.onCancel()
        }
    }

    fun showLoadingIndicator(value: Boolean) {
        if (value) {
            buttonView?.startLoading()
        } else {
            buttonView?.stopLoading()
        }
    }

    interface Listener {
        fun onCancel()
        fun onCodeProvided(code: String)
    }
}