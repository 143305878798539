package com.wicupp.safetymap.dashboard.settings

import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.WebContext
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.component.*
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.layout.LinearLayout
import com.wicupp.beaver.web.view.smartview.AlertDialog
import com.wicupp.beaver.web.view.smartview.BottomSheet
import com.wicupp.safetymap.dashboard.data.model.User
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory
import com.wicupp.safetymap.dashboard.settings.delete.DeleteAccountView
import com.wicupp.safetymap.dashboard.settings.email.EditEmailView

class SettingsView(cv: ContextView): Layout(cv), SettingsContract.View {

    companion object {
        const val TAG_NAME = "app-settings-view"
    }

    override fun layoutName() = "view-settings"

    var listener: Listener? = null

    private val presenter: SettingsContract.Presenter = Inject.get()
    private val webContext: WebContext = Inject.get()
    private var editEmail: TextView? = null
    private var container: LinearLayout? = null
    private var loaderView: SpinnerBar? = null

    private var newsletterSwitch: SwitchView? = null
    private var helpUsSwitch: SwitchView? = null
    private var currentEmail: String? = null

    override fun onCreate() {
        super.onCreate()

        container = findViewById<LinearLayout>("view-settings-container")
        loaderView = findViewById<SpinnerBar>("view-settings-loader")

        editEmail = findViewById<TextView>("view-settings-edit-email")
        editEmail?.onClick {
            showEditEmail()
        }
        newsletterSwitch = findViewById<SwitchView>("view-event-settings-newsletter")
        newsletterSwitch?.onChanged {
            updateSettingOptions()
        }
        helpUsSwitch = findViewById<SwitchView>("view-event-settings-help-us")
        helpUsSwitch?.onChanged {
            updateSettingOptions()
        }
        findViewById<TextView>("view-settings-delete-account").onClick {
            presenter.deleteAccount()
        }

        findViewById<TextView>("view-settings-subscription").onClick {
            presenter.requestSubscriptionDashboard()
        }
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
        presenter.registerToUser()
        presenter.requestUserData()
    }

    override fun onDetached() {
        presenter.detachView(this)
        presenter.unregisterFromUser()
        super.onDetached()
    }

    override fun showUser(user: User) {
        enableSwitch(newsletterSwitch, user.acceptNewsletters)
        enableSwitch(helpUsSwitch, user.isTrackingEnabled)
        currentEmail = user.email
    }

    override fun showLoadingIndicator(show: Boolean) {
        if (show) {
            container?.setVisibility(View.GONE)
            loaderView?.setVisibility(View.VISIBLE)
        } else {
            container?.setVisibility(View.VISIBLE)
            loaderView?.setVisibility(View.GONE)
        }
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    override fun showEditDone() {
        // Do nothing
    }

    override fun showDeleteAccount() {
        val deleteAccountView = createLayout(DeleteAccountView::class.js)

        val bottomSheet = BottomSheet.from(this, deleteAccountView)
        bottomSheet.show()

        deleteAccountView.listener = object : DeleteAccountView.Listener {
            override fun onAccountDeleted() {
                bottomSheet.hide {
                    showDeleteAccountDone()
                }
            }
        }
    }

    override fun showSubscriptionDashboard(url: String) {
        webContext.getWebView().open(url)
    }

    private fun showDeleteAccountDone() {
        AlertDialog.Builder(this)
            .setTitle(getString("delete-account"))
            .setMessage(getString("delete-account-confirmation"))
            .setPositiveButton(getString("common-ok")) { dialog ->
                dialog.hide()
                listener?.onLogoutRequested()
            }
            .create()
            .show()
    }

    private fun enableSwitch(view: SwitchView?, enable: Boolean?) {
        enable?.let {
            view?.enable(it)
        }
    }

    private fun getSwitchValue(view: SwitchView?): Boolean {
        view?.let {
            return it.value()
        }
        return false
    }

    private fun updateSettingOptions() {
        presenter.setSettingOptions(
            getSwitchValue(newsletterSwitch),
            getSwitchValue(helpUsSwitch)
        )
    }

    private fun showEditEmail() {
        val editEmailView = createLayout(EditEmailView::class.js)

        val bottomSheet = BottomSheet.from(this, editEmailView)
        bottomSheet.show()

        editEmailView.email = currentEmail
        editEmailView.listener = object : EditEmailView.Listener {
            override fun onEmailEdited() {
                bottomSheet.hide()
            }
        }
    }

    interface Listener {
        fun onLogoutRequested()
    }
}