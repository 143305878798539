package com.wicupp.safetymap.dashboard.data.api

class ApiUrl {
    companion object {
        const val USER = "/user"
        const val REGISTER_PUSH = "/user/app/push/register"
        const val REQUEST_VERIFICATION_CODE = "/auth/sign/magiclink"
        const val SET_LANGUAGE = "/user/language"
        const val SET_SETTING_OPTIONS = "/user/options"
        const val REQUEST_NEW_EMAIL = "/oauth/request-new-email"
        const val SET_EMAIL = "/oauth/edit-email"
        const val SEARCH_STREET = "/search/street"
        const val GET_PLACE_INFORMATION = "/place/information"
        const val DELETE_ACCOUNT = "/auth/email/delete"
        const val CONFIRM_DELETE_ACCOUNT = "/auth/email/delete/confirmation"
        const val OPEN_CHECKOUT_SESSION = "/subscription/checkout/open"
        const val CONFIRM_SUBSCRIPTION = "/subscription/confirm"
        const val GET_SUBSCRIPTION_DASHBOARD = "/subscription/dashboard"
        const val CREATE_DASHBOARD_PROJECT = "/project/create"
        const val UPDATE_DASHBOARD_PROJECT = "/project/update/{id}"
        const val REPORT_INCIDENT = "/project/incident/report"
        const val PROJECT_REFUGES = "/project/refuges"
        const val PROJECT_CREATE_REFUGE = "/project/refuges/create"
        const val PROJECT_UPDATE_REFUGE = "/project/refuges/update"
        const val PROJECT_DELETE_REFUGE = "/project/refuges/delete/{id}"
    }
}