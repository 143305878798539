package com.wicupp.safetymap.dashboard.loading

import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout

class LoadingView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "app-loading-view"
    }

    override fun layoutName() = "view-loading"
}