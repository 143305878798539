package com.wicupp.safetymap.dashboard.onboarding

import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.WebContext
import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.component.EditText
import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.layout.LinearLayout
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory

class OnboardingView(cv: ContextView): Layout(cv), OnboardingContract.View {

    companion object {
        const val TAG_NAME = "app-onboarding-view"
    }

    override fun layoutName() = "view-onboarding"

    var listener: Listener? = null

    private val presenter: OnboardingContract.Presenter = Inject.get()

    private var createView: LinearLayout? = null
    private var successView: LinearLayout? = null

    override fun onCreate() {
        super.onCreate()

        createView = findViewById("view-onboarding-create")
        successView = findViewById("view-onboarding-success")

        val nameEditText = findViewById<EditText>("view-onboarding-project-name")
        findViewById<ButtonView>("view-onboarding-next-button").onClick {
            presenter.createProject(nameEditText.getValue())
        }
        findViewById<ButtonView>("view-onboarding-success-button").onClick {
            listener?.onOnboardingCompleted()
        }
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
    }

    override fun onDetached() {
        presenter.detachView(this)
        super.onDetached()
    }

    override fun showLoadingIndicator(show: Boolean) {
        // TODO
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    override fun showOnboardingCompleted() {
        createView?.setVisibility(View.GONE)
        successView?.setVisibility(View.VISIBLE)
    }

    interface Listener {
        fun onOnboardingCompleted()
    }
}