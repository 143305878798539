package com.wicupp.beaver.core

class Platform {
    companion object {
        private var platformType: Type? = null

        val isIos: Boolean
            get() = platformType == Type.IOS

        val isAndroid: Boolean
            get() = platformType == Type.ANDROID

        val isWeb: Boolean
            get() = platformType == Type.WEB

        val isCustom: Boolean
            get() = platformType == Type.CUSTOM

        fun define(type: Type) {
            platformType = type
        }
    }

    enum class Type(value: String) {
        IOS("IOS"),
        ANDROID("ANDROID"),
        WEB("WEB"),
        CUSTOM("CUSTOM")
    }
}