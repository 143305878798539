package com.wicupp.safetymap.dashboard.board.refuge.edit

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.safetymap.dashboard.data.api.AdminApi
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.flux.notifier.RefugesNotifier
import com.wicupp.safetymap.dashboard.data.model.Refuge
import com.wicupp.safetymap.dashboard.error.ErrorManager

/**
 * [EditRefugeContract.Presenter] implementation.
 */
class EditRefugePresenterImpl(
    private val adminApi: AdminApi,
    private val handler: Handler,
    private val refugesNotifier: RefugesNotifier
) : EditRefugeContract.Presenter {

    private var view: EditRefugeContract.View? = null
    private var refugeId: String? = null

    override fun attachView(view: EditRefugeContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
        this.refugeId = null
    }

    override fun detachView(view: EditRefugeContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun requestRefuge(refugeId: String) {
        view?.showLoadingIndicator(true)
        this.refugeId = refugeId
        refugesNotifier.get(refugeId)?.let { refuge ->
            view?.showRefuge(refuge)
            view?.showLoadingIndicator(false)
        }
    }

    override fun updateRefuge(refuge: Refuge) {
        view?.showLoadingIndicator(true)
        adminApi.updateRefuge(
            refuge,
            object : ApiListener<Unit> {
                override fun onSuccess(apiResponse: ApiResponse<Unit>) {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        view?.showSuccess(refuge.name)
                        // TODO add refuge in local through handler
                    }
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        ErrorManager.formatRequestError(status, error)?.let {
                            view?.showError(it)
                        }
                    }
                    return true
                }
            }
        )
    }

    override fun createRefuge(
        projectId: String,
        refuge: Refuge
    ) {
        view?.showLoadingIndicator(true)
        adminApi.createRefuge(
            projectId,
            refuge,
            object : ApiListener<String> {
                override fun onSuccess(apiResponse: ApiResponse<String>) {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        view?.showSuccess(refuge.name)
                        // TODO add refuge in local through handler
                    }
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        ErrorManager.formatRequestError(status, error)?.let {
                            view?.showError(it)
                        }
                    }
                    return true
                }
            }
        )
    }

}
