package com.wicupp.safetymap.dashboard.onboarding

import com.wicupp.beaver.core.task.Handler
import com.wicupp.safetymap.dashboard.data.api.SubscriptionApi
import com.wicupp.safetymap.dashboard.data.api.UserApi
import com.wicupp.safetymap.dashboard.data.flux.GlobalDispatcher
import com.wicupp.safetymap.dashboard.data.flux.action.CreateProjectAction
import com.wicupp.safetymap.dashboard.error.ErrorManager

/**
 * [OnboardingContract.Presenter] implementation.
 */
class OnboardingPresenterImpl(
    private val handler: Handler,
    private val globalDispatcher: GlobalDispatcher,
    private val subscriptionApi: SubscriptionApi,
    private val userApi: UserApi
): OnboardingContract.Presenter {

    private var view: OnboardingContract.View? = null

    override fun attachView(view: OnboardingContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
    }

    override fun detachView(view: OnboardingContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun createProject(name: String) {
        view?.showLoadingIndicator(true)

        globalDispatcher.prepare()
            .onSuccess {
                handler.post {
                    view?.showLoadingIndicator(false)
                    view?.showOnboardingCompleted()
                }
            }
            .onError { status, error ->
                handler.post {
                    view?.showLoadingIndicator(false)
                    ErrorManager.formatRequestError(status, error)?.let {
                        view?.showError(it)
                    }
                }
            }
            .dispatch(CreateProjectAction(name))
    }

}
