package com.wicupp.safetymap.dashboard.sign

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.web.view.activity.Activity
import com.wicupp.beaver.web.view.activity.ActivityContext
import com.wicupp.beaver.web.view.activity.Intent
import com.wicupp.safetymap.dashboard.board.MainActivity

class SignActivity(activityContext: ActivityContext)
    : Activity(activityContext, "activity-signin", "signin") {

    companion object {
        const val NO_HISTORY_EXTRA = "NO_HISTORY_EXTRA"

        fun startActivity(activity: Activity) {
            Logger.d("Start sign")
            val intent = Intent(SignActivity::class.js).apply {
                putExtra(NO_HISTORY_EXTRA, false)
            }
            activity.startActivity(intent)
        }

        fun startActivityWithoutHistory(activity: Activity) {
            Logger.d("Start sign without history")
            val intent = Intent(SignActivity::class.js).apply {
                cleanHistory()
                putExtra(NO_HISTORY_EXTRA, true)
            }
            activity.startActivity(intent)
        }
    }

    override fun onCreate(activityContext: ActivityContext) {
        super.onCreate(activityContext)

        val noHistory = getBooleanExtra(NO_HISTORY_EXTRA) ?: false

        findViewById<SignView>("view-sign-view").listener = object : SignView.Listener {
            override fun onSignInDone() {
                if (noHistory) {
                    showDashboard()
                } else {
                    back()
                }
            }

            override fun onSignUpDone() {
                if (noHistory) {
                    showDashboard()
                } else {
                    back()
                }
            }
        }
    }

    private fun showDashboard() {
        val intent = Intent(MainActivity::class.js).apply {
            cleanHistory()
        }
        startActivity(intent)
    }
}