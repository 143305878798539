package com.wicupp.safetymap.dashboard.onboarding

import com.wicupp.beaver.web.view.activity.Activity
import com.wicupp.beaver.web.view.activity.ActivityContext
import com.wicupp.beaver.web.view.activity.Intent
import com.wicupp.safetymap.dashboard.board.MainActivity

class OnboardingActivity(activityContext: ActivityContext)
    : Activity(activityContext, "activity-onboarding", "onboarding") {

    companion object {

        fun startActivity(activity: Activity) {
            activity.startActivity(Intent(OnboardingActivity::class.js).apply {
                cleanHistory()
            })
        }
    }

    override fun onCreate(activityContext: ActivityContext) {
        super.onCreate(activityContext)

        val onboardingView = findViewById<OnboardingView>("view-onboarding-view")
        onboardingView.listener = object : OnboardingView.Listener {
            override fun onOnboardingCompleted() {
                showDashboard()
            }
        }
    }

    private fun showDashboard() {
        val intent = Intent(MainActivity::class.js).apply {
            cleanHistory()
        }
        startActivity(intent)
    }
}