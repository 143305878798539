package com.wicupp.safetymap.dashboard.board.refuge.edit

import com.wicupp.artifice.toast.Toast
import com.wicupp.artifice.toast.ToastDuration
import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.view.component.*
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.layout.LinearLayout
import com.wicupp.beaver.web.view.smartview.ButtonOption
import com.wicupp.beaver.web.view.smartview.SmartView
import com.wicupp.beaver.web.view.smartview.SmartViewType
import com.wicupp.safetymap.dashboard.board.refuge.edit.address.AddAddressView
import com.wicupp.safetymap.dashboard.board.refuge.edit.address.model.Address
import com.wicupp.safetymap.dashboard.board.refuge.edit.categories.RefugeCategoriesView
import com.wicupp.safetymap.dashboard.board.refuge.edit.opening.AddOpeningHoursItemView
import com.wicupp.safetymap.dashboard.board.refuge.edit.opening.OpenHoursAdapter
import com.wicupp.safetymap.dashboard.data.model.Refuge
import com.wicupp.safetymap.dashboard.data.model.RefugeKey
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory
import com.wicupp.safetymap.dashboard.loading.LoadingView
import com.wicupp.safetymap.dashboard.utils.OpenHoursUtils.canBeInserted
import com.wicupp.safetymap.dashboard.utils.OpeningHoursPeriod

class EditRefugeView(cv: ContextView): Layout(cv), EditRefugeContract.View {

    companion object {
        const val TAG_NAME = "edit-refuge-view"
    }

    override fun layoutName() = "view-edit-refuge"
    var listener: Listener? = null
    var projectId: String? = null
    var refugeId: String? = null
        set(value) {
            value?.let {
                presenter.requestRefuge(it)
            }
            field = value
        }

    private val presenter: EditRefugeContract.Presenter = Inject.get()
    private var refugeContainer: LinearLayout? = null
    private var loadingView: SpinnerBar? = null
    private var refugeNameEditText: EditText? = null
    private var refugeStreetEditText: EditText? = null
    private var refugeCategoriesView: RefugeCategoriesView? = null
    private var openingHoursList: ListView? = null
    private var addOpeningHoursButton: ButtonView? = null

    private var openHoursAdapter: OpenHoursAdapter = OpenHoursAdapter()
    var refuge: Refuge? = null

    override fun onCreate() {
        super.onCreate()

        refugeContainer = findViewById("view-edit-refuge-container")
        loadingView = findViewById("view-edit-refuge-loading")

        refugeNameEditText = findViewById("view-edit-refuge-name")
        refugeNameEditText?.onChanged {
            Logger.d("refugeNameEditText changed")
            refuge = refuge?.toBuilder()?.run {
                name = it
                build()
            }
        }
        refugeStreetEditText = findViewById("view-edit-refuge-street")
        refugeStreetEditText?.onClick {
            showAddAddress()
        }
        refugeCategoriesView = findViewById("view-edit-refuge-categories")
        refugeCategoriesView?.listener = object : RefugeCategoriesView.Listener {
            override fun onChange(selectedCategories: List<Refuge.Category>) {
                Logger.d("selectedCategories changed")
                refuge = refuge?.toBuilder()?.run {
                    categories = selectedCategories
                    build()
                }
            }
        }
        openingHoursList = findViewById("view-edit-refuge-opening-periods-list")
        addOpeningHoursButton = findViewById("view-edit-refuge-add-hours-button")
        addOpeningHoursButton?.onClick {
            showAddOpeningHours()
        }

        openHoursAdapter.listener = object : OpenHoursAdapter.Listener {
            override fun onDeleteRequested(openingHoursPeriod: OpeningHoursPeriod) {
                Logger.d("Remove openingHoursPeriod.")
                refuge = refuge?.toBuilder()?.run {
                    openingPeriods = openingPeriods?.toMutableList()?.apply {
                        remove(openingHoursPeriod)
                    }
                    build()
                }
                refuge?.openingPeriods?.let {
                    openHoursAdapter.setOpeningHours(it)
                    openingHoursList?.setAdapter(openHoursAdapter)
                }
            }
        }
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
        if (refuge == null) {
            refugeId?.let {
                presenter.requestRefuge(it)
            }
        } else if (refuge?.id?.isEmpty() == true) { // Default refuge
            refuge?.let { showRefuge(it) }
            showLoadingIndicator(false)
        }
    }

    override fun onDetached() {
        presenter.detachView(this)
        super.onDetached()
    }

    override fun showLoadingIndicator(show: Boolean) {
        refugeContainer?.setVisibility(if(show) View.GONE else View.VISIBLE)
        loadingView?.setVisibility(if(show) View.VISIBLE else View.GONE)
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    override fun showRefuge(refuge: Refuge) {
        Logger.d("refuge : ${refuge.name}")
        this.refuge = refuge
        refugeNameEditText?.setValue(refuge.name)
        refugeStreetEditText?.setValue(refuge.street)
        refugeCategoriesView?.selectedCategories = refuge.categories
        openHoursAdapter.setOpeningHours(refuge.openingPeriods)
        openingHoursList?.setAdapter(openHoursAdapter)
    }

    override fun showSuccess(refugeName: String) {
        listener?.onRefugeUpdated()
    }

    fun createRefuge() {
        refuge?.let { refuge ->
            projectId?.let { projectId ->
                presenter.createRefuge(projectId, refuge)
            }
        }
    }

    fun editRefuge() {
        refuge?.let {
            presenter.updateRefuge(it)
        }
    }

    private fun showAddOpeningHours() {
        val addOpeningHoursItemView = createLayout(AddOpeningHoursItemView::class.js)
        val smartView = SmartView(
            this,
            getString("project-opening-hours"),
            addOpeningHoursItemView
        )
            .setType(SmartViewType.DEFAULT)
            .create()
            .show()

        addOpeningHoursItemView.listener = object : AddOpeningHoursItemView.Listener {
            override fun onNewOpenHours(openingHoursPeriods: List<OpeningHoursPeriod>) {
                openingHoursPeriods.forEach {
                    if (refuge?.openingPeriods?.canBeInserted(it) != true) {
                        Toast().show(
                            getString("project-refuges-hours-incompatible-error"),
                            ToastDuration.SHORT
                        )
                        return
                    }
                }
                smartView.hide {
                    refuge = refuge?.toBuilder()?.run {
                        this.openingPeriods = this.openingPeriods?.toMutableList()?.apply {
                            openingHoursPeriods.forEach {
                                add(it)
                            }
                        }
                        build()
                    }

                    refuge?.openingPeriods?.let {
                        openHoursAdapter.setOpeningHours(it)
                        openingHoursList?.setAdapter(openHoursAdapter)
                    }
                }
            }
        }
    }

    private fun showAddAddress() {
        val addAddressView = createLayout(AddAddressView::class.js)
        val smartView = SmartView(
            this,
            "add-address-view-modal",
            addAddressView
        )
            .hideTitle()
            .setType(SmartViewType.DEFAULT)
            .create()
            .show()

        addAddressView.listener = object : AddAddressView.Listener {
            override fun onAddressDefined(address: Address) {
                smartView.hide {
                    if (address.placeInformation != null) {
                        //TODO Do you wants to apply new data ? All your previous information will be lost.
                        /*address = address.toBuilder().run {
                            placeInformation = null
                            build()
                        }*/
                    }
                    refuge = refuge?.let {
                        address.updateRefuge(it)
                    }?.also {
                        showRefuge(it)
                    }
                }
            }
        }
    }

    interface Listener {
        fun onCancel()
        fun onRefugeUpdated()
    }
}