package com.wicupp.beaver.web.view.component

interface View {
    companion object {
        val GONE: String = "GONE"
        val VISIBLE: String = "VISIBLE"
        val INVISIBLE: String = "INVISIBLE"
    }

    @JsName("isVisible")
    fun isVisible(): Boolean

    @JsName("attach")
    fun attach()

    @JsName("detach")
    fun detach()

    @JsName("setVisibility")
    fun setVisibility(value: String)

    fun setVisibility(value: String, askFading: Boolean)

    fun setVisibility(value: String, askFading: Boolean, onFinished: () -> Unit)

    @JsName("onClick")
    fun onClick(onClick: () -> Unit)

    @JsName("addClass")
    fun addClass(className: String)

    @JsName("removeClass")
    fun removeClass(className: String)

    @JsName("findJsViewById")
    fun findJsViewById(id: String): JSView

    @JsName("findViewById")
    fun <T> findViewById(id: String): T

    @JsName("collapseHeight")
    fun collapseHeight(collapse: Boolean, animationDelay: Int)

    @JsName("OnClickListener")
    interface OnClickListener {

        @JsName("onClick")
        fun onClick()
    }
}