package com.wicupp.safetymap.dashboard.legals

import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.WebContext
import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.safetymap.dashboard.SafetymapConfig

class LegalView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "app-legal-view"
    }

    private val webContext: WebContext = Inject.get()

    override fun layoutName() = "view-legal"

    override fun onCreate() {
        super.onCreate()

        val tosButton = findViewById<TextView>("view-legal-tos-button")
        tosButton.onClick {
            webContext.getWebView().open(SafetymapConfig.tosUrl)
        }

        val privacyButton = findViewById<TextView>("view-legal-privacy-button")
        privacyButton.onClick {
            webContext.getWebView().open(SafetymapConfig.privacyUrl)
        }

        val noticesButton = findViewById<TextView>("view-legal-notices-button")
        noticesButton.onClick {
            webContext.getWebView().open(SafetymapConfig.legalNoticesUrl)
        }
    }
}