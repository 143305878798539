package com.wicupp.safetymap.dashboard.error

enum class AppErrorId {
    NETWORK_LIMIT_REACHED,
    EMAIL_NOT_CONFIRMED,
    EMAIL_NOT_VALID,
    SIGN_IN_ERROR,
    RATE_TOO_LARGE_AREA,
    REGION_NOT_AVAILABLE,
    SERVER_ERROR,
    CONNECTION_ISSUE,
    BAD_AUTHENT_ERROR,
    UNKNOWN_NETWORK_ERROR,
    UNKNOWN_NETWORK_ERROR_WITH_CODE,
    UNKNOWN_ERROR
}