package com.wicupp.beaver.storage

interface KeyValueStorage {
    fun init(tables: Array<String>, onSuccess: () -> Unit)

    fun removeAll(onSuccess: () -> Unit)

    fun removeAllItems(table: String,
                       onCompleted: (error: Boolean) -> Unit)

    fun setItemToTable(table: String,
                       key: String,
                       value: String?,
                       expirationSeconds: Long?,
                       onCompleted: ((error: Boolean) -> Unit)? = null)

    fun getItemFromTable(table: String,
                         key: String,
                         onCompleted: (value: String?, error: Boolean) -> Unit)
}