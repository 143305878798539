package com.wicupp.safetymap.dashboard.board.refuge

import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.layout.ListViewHolder
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.LinearLayout
import com.wicupp.beaver.web.view.smartview.ButtonOption
import com.wicupp.beaver.web.view.smartview.SmartView
import com.wicupp.beaver.web.view.smartview.SmartViewType
import com.wicupp.safetymap.dashboard.data.model.Refuge
import com.wicupp.safetymap.dashboard.ui.CategoryIconView
import com.wicupp.safetymap.dashboard.utils.iconRes

class RefugeItemView(cv: ContextView): ListViewHolder(cv) {

    companion object {
        const val TAG_NAME = "app-refuge-item"
    }

    override fun layoutName() = "view-refuge-item"
    var refuge: Refuge? = null
    var listener: Listener? = null

    override fun onCreate() {
        super.onCreate()

        val nameView = findViewById<TextView>("view-refuge-name")
        nameView.setText(refuge?.name)

        val addressView = findViewById<TextView>("view-refuge-street")
        addressView.setText(refuge?.street)

        findViewById<View>("view-refuge-btn").onClick {
            showRefugeMenu()
        }

        findViewById<LinearLayout>("view-refuge-categories").apply {
            refuge?.categories?.forEach {
                appendView(createLayout(CategoryIconView::class.js).apply {
                    iconRes = it.iconRes
                })
            }
        }
    }

    private fun showRefugeMenu() {
        val refugeMenuView = createLayout(RefugeMenuView::class.js)
        val smartView = SmartView(
            this,
            "refuge-menu-dialog-view",
            refugeMenuView
        )
            .hideTitle()
            .setType(SmartViewType.BOTTOM_SHEET)
            .setCloseButton(
                ButtonOption.Builder()
                    .icon("none")
                    .build()) {
                // Do nothing
            }
            .hideTopBar(true)
            .create()
            .show()

        refugeMenuView.listener = object : RefugeMenuView.Listener {
            override fun onEditRefugeRequested() {
                smartView.hide {
                    listener?.onEditRequested()
                }
            }

            override fun onDeleteRefugeRequested() {
                smartView.hide {
                    listener?.onDeleteRequested()
                }
            }

        }
    }

    interface Listener {
        fun onEditRequested()
        fun onDeleteRequested()
    }
}