package com.wicupp.safetymap.dashboard.board.refuge.edit.address.model

import com.wicupp.safetymap.dashboard.data.model.PlaceInformation
import com.wicupp.safetymap.dashboard.data.model.Refuge
import com.wicupp.safetymap.dashboard.utils.OpeningHoursPeriod
import kotlinx.datetime.TimeZone
import kotlinx.serialization.Serializable

@Serializable
class Address private constructor(
    val street: String,
    val latitude: Double,
    val longitude: Double,
    var timezone: String,
    val placeInformation: PlaceInformation?
) {
    companion object {
        fun builder(): Builder {
            return Builder(null, null, null)
        }
    }

    data class Builder(
        var street: String? = null,
        var latitude: Double? = null,
        var longitude: Double? = null,
        var timezone: String? = null,
        var placeInformation: PlaceInformation? = null
    ) {
        fun build() = Address(street!!, latitude!!, longitude!!, timezone!!, placeInformation)
    }

    fun toBuilder() = Builder(street, latitude, longitude, timezone, placeInformation)

    fun updateRefuge(refuge: Refuge): Refuge {
        return refuge.toBuilder().run {
            street = this@Address.street
            longitude = this@Address.longitude
            latitude = this@Address.latitude
            timezone = this@Address.timezone
            this@Address.placeInformation?.let {
                if (it.openingHoursPeriods.isNotEmpty()) {
                    openingPeriods = it.openingHoursPeriods
                }

                if (it.refugeCategories.isNotEmpty()) {
                    categories = it.refugeCategories
                }

                if (it.name.isNotEmpty()) {
                    name = it.name
                }
            }
            build()
        }
    }
}