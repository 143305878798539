package com.wicupp.beaver.js.share

import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.smartview.BottomSheet

class ShareImpl: Share {
    override fun share(layout: Layout,
                       data: Share.ShareData,
                       onShareViewShown: (() -> Unit)?) {
        val shareView = Layout.createLayout(ShareView::class.js)

        shareView.link = data.link
        shareView.description = data.description
        shareView.facebookId = data.facebookId

        BottomSheet.from(layout, shareView)
            .show()
        onShareViewShown?.invoke()
    }
}