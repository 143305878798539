package com.wicupp.safetymap.dashboard.utils

enum class Day(
        val stringKey: String,
        val openingDay: Int,
        val dataKey: String,
        val shortStringKey: String
) {
    MONDAY("common-monday", 1, "monday", "common-monday-short"),
    TUESDAY("common-tuesday", 2, "tuesday", "common-tuesday-short"),
    WEDNESDAY("common-wednesday", 3, "wednesday", "common-wednesday-short"),
    THURSDAY("common-thursday", 4, "thursday", "common-thursday-short"),
    FRIDAY("common-friday", 5, "friday", "common-friday-short"),
    SATURDAY("common-saturday", 6, "saturday", "common-saturday-short"),
    SUNDAY("common-sunday", 7, "sunday", "common-sunday-short");

    companion object {
        fun getByStringKey(stringKey: String): Day {
            return entries.firstOrNull { it.stringKey == stringKey } ?: throw IllegalStateException("$stringKey not found.")
        }

        fun getByOpeningDay(openingDay: Int): Day {
            return entries.firstOrNull { it.openingDay == openingDay } ?: throw IllegalStateException("$openingDay not found.")
        }

        fun getByDataKey(dataKey: String): Day {
            return entries.firstOrNull { it.dataKey == dataKey } ?: throw IllegalStateException("$dataKey not found.")
        }
    }

    val nextDay: Day
        get() {
            val newOpeningDay = if (openingDay == 7) 0 else openingDay + 1
            return getByOpeningDay(newOpeningDay)
        }
}