package com.wicupp.beaver.core

import kotlin.native.concurrent.ThreadLocal

class Local {

    @ThreadLocal
    companion object {
        var language: Language? = null

        internal fun provideLanguage(language: Language) {
            this.language = language
        }

        fun getDefault(): Language {
            return this.language ?: throw IllegalStateException("Language must be provided")
        }
    }
}