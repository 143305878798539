package com.wicupp.beaver.core.utils

class ReentrantLock {
    private val queue = mutableMapOf<String, Item>()

    fun lock(id: String, onExecute: () -> Unit) {
        call(id, onExecute)
    }

    fun call(id: String, onExecute: () -> Unit) {
        if (queue[id]?.isLocked == true) {
            queue[id]?.onExecuteList?.add(onExecute)
        } else {
            queue[id] = Item(isLocked = true)
            onExecute()
        }
    }

    fun callOnce(id: String,
                 onExecuteOnce: () -> Unit,
                 onExecute: () -> Unit) {
        if (queue[id]?.isLocked == true) {
            queue[id]?.onExecuteList?.add(onExecute)
        } else {
            queue[id] = Item(isLocked = true)
            onExecuteOnce()
        }
    }

    fun unlock(id: String) {
        queue[id]?.apply {
            if (onExecuteList.isNotEmpty()) {
                val onExecute = onExecuteList[onExecuteList.lastIndex]
                onExecuteList.removeLast()
                onExecute()
            } else {
                isLocked = false
            }
        }
    }

    data class Item(val onExecuteList: MutableList<() -> Unit> = mutableListOf(),
                    var isLocked: Boolean = false)
}