package com.wicupp.safetymap.dashboard.data.model

import kotlinx.serialization.Serializable

@Serializable
class User private constructor(
    val userId: String,
    val name: String,
    val email: String,
    val language: String,
    val acceptNewsletters: Boolean,
    val isTrackingEnabled: Boolean,
    val subscription: SubscriptionPlan,
    val projects: List<Project>
) {

    data class Builder(
        var userId: String = "",
        var name: String = "",
        var email: String = "",
        var language: String = "",
        var acceptNewsletters: Boolean = false,
        var isTrackingEnabled: Boolean = false,
        var subscription: SubscriptionPlan = SubscriptionPlan.NONE,
        var projects: List<Project> = listOf()
    ) {
        fun name(name: String) = apply { this.name = name }

        fun email(email: String) = apply { this.email = email }

        fun language(language: String) = apply { this.language = language }

        fun subscription(subscription: SubscriptionPlan) = apply { this.subscription = subscription }

        fun build() = User(userId, name, email, language, acceptNewsletters, isTrackingEnabled, subscription, projects)
    }

    fun toBuilder(): Builder {
        return Builder(userId, name, email, language, acceptNewsletters, isTrackingEnabled, subscription, projects)
    }
}