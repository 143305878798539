package com.wicupp.safetymap.dashboard.board.project

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.WebCore
import com.wicupp.beaver.web.view.component.*
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.smartview.AlertDialog
import com.wicupp.safetymap.dashboard.data.model.Project
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory
import com.wicupp.safetymap.dashboard.ui.EditableTextView

class ProjectSettingsView(cv: ContextView): Layout(cv), ProjectSettingsContract.View {

    companion object {
        const val TAG_NAME = "app-project-settings"
        const val QR_CODE_SIZE = 140
    }

    override fun layoutName() = "view-project-settings"

    private val presenter: ProjectSettingsContract.Presenter = Inject.get()
    private var loadingView: SpinnerBar? = null
    private var editableTextView: EditableTextView? = null
    private var linkEditText: EditText? = null
    private var qrCodeView: QRCodeView? = null

    override fun onCreate() {
        super.onCreate()

        loadingView = findViewById("view-project-settings-loading")

        editableTextView = findViewById("view-project-settings-name")
        editableTextView?.listener = object : EditableTextView.Listener {
            override fun onChanged(text: String) {
                presenter.updateProjectName(text)
            }
        }

        linkEditText = findViewById("view-project-settings-link")
        qrCodeView = findViewById("view-project-settings-qrcode")
        findViewById<SmartIcon>("view-project-settings-copy").onClick {
            linkEditText?.let {
                WebCore.copyTextToClipBoard(it.getValue())
                it.select()
            }
        }
        findViewById<SmartIcon>("view-project-settings-download-qrcode").onClick {
            qrCodeView?.download()
        }
        findViewById<SmartIcon>("view-project-settings-link-info").onClick {
            showLinkExplanation()
        }
        findViewById<SmartIcon>("view-project-settings-qrcode-info").onClick {
            showQRCodeExplanation()
        }
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
        presenter.requestProject()
    }

    override fun onDetached() {
        presenter.detachView(this)
        super.onDetached()
    }

    override fun showLoadingIndicator(show: Boolean) {
        loadingView?.setVisibility(if (show) View.VISIBLE else View.GONE)
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    override fun showProject(project: Project) {
        editableTextView?.value = project.name
        editableTextView?.placeholder = getString("project-settings-name")
        val link = "https://www.safetymap.io/share/project/${project.id}"
        linkEditText?.setValue(link)
        qrCodeView?.drawQRCode(link, QR_CODE_SIZE, getColor("color-accent"))
    }

    private fun showLinkExplanation() {
        AlertDialog.Builder(this)
            .setTitle("Information")
            .setMessage(getString("project-share-link-help"))
            .setPositiveButton(getString("common-ok")) {
                it.hide()
            }
            .create()
            .show()
    }

    private fun showQRCodeExplanation() {
        AlertDialog.Builder(this)
            .setTitle("Information")
            .setMessage(getString("project-share-link-help"))
            .setPositiveButton(getString("common-ok")) {
                it.hide()
            }
            .create()
            .show()
    }
}