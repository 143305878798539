package com.wicupp.beaver.request

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.request.http.Http
import com.wicupp.beaver.request.http.Request
import com.wicupp.beaver.storage.KeyValueStorage
import com.wicupp.beaver.core.utils.ReentrantLock
import com.wicupp.beaver.core.utils.Utils
import com.wicupp.beaver.request.http.RequestBody
import com.wicupp.beaver.utils.JsonUtils.Companion.getInt
import com.wicupp.beaver.utils.JsonUtils.Companion.getIntOrNull
import com.wicupp.beaver.utils.JsonUtils.Companion.getString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject

class OAuthV2Impl(
    private val oAuthConfig: OAuthConfig,
    val storage: KeyValueStorage,
    val http: Http
): OAuth {

    companion object {
        private const val VERIFICATION_CODE_GRANT = "verification_code"

        private const val ACCESS_TOKEN_STORAGE_KEY = "access_token"
        private const val REFRESH_TOKEN_STORAGE_KEY = "refresh_token"

        fun formatGetParams(parameter: Parameter): String {
            val params = mutableListOf<String>()
            for ((key, value) in parameter.parameters) {
                if (value is String) {
                    params.add("${Utils.encodeURI(key)}=${Utils.encodeURI(value)}")
                } else {
                    params.add("${Utils.encodeURI(key)}=${value}")
                }
            }
            return params.joinToString(separator = "&")
        }
    }

    val reentrantLock = ReentrantLock()
    var shouldRefreshToken = true
    var onUnexpectedDisconnectionListeners: MutableList<() -> Unit> = mutableListOf()

    override fun logout(listener: OAuth.Listener) {
        storage.getItemFromTable("users", REFRESH_TOKEN_STORAGE_KEY) { refreshToken, _ ->
            val parameterBuilder = Parameter.Builder()

            parameterBuilder.addParameter("client_id", oAuthConfig.clientId)
            parameterBuilder.addParameter("client_secret", oAuthConfig.secretId)
            parameterBuilder.addParameter("refresh_token", refreshToken)

            val urlFormated = oAuthConfig.apiUrl + oAuthConfig.logoutUrl

            val httpRequest = Request.Builder(urlFormated)
                .post(RequestBody.create("application/x-www-form-urlencoded", formatGetParams(parameterBuilder.build())))
                .build()
            http.newAsyncCall(httpRequest) {
                if (it.httpStatus == 204) {
                    listener.onSuccess("")
                } else {
                    val errorRequest = try {
                        ApiRequest.parseError(Json.parseToJsonElement(it.body))
                    } catch (e: Exception) {
                        ErrorRequest(-1, "Unknown Error")
                    }
                    listener.onError(it.httpStatus, errorRequest)
                }
            }
        }
    }

    override fun login(username: String,
                       verificationCode: String,
                       listener: OAuth.Listener) {
        val parameterBuilder = Parameter.Builder()

        parameterBuilder.addParameter("client_id", oAuthConfig.clientId)
        parameterBuilder.addParameter("client_secret", oAuthConfig.secretId)
        parameterBuilder.addParameter("grant_type", VERIFICATION_CODE_GRANT)
        parameterBuilder.addParameter("username", username)
        parameterBuilder.addParameter("verification_code", verificationCode)

        val urlFormated = oAuthConfig.apiUrl + oAuthConfig.loginUrl

        val httpRequest = Request.Builder(urlFormated)
            .post(RequestBody.create("application/x-www-form-urlencoded", formatGetParams(parameterBuilder.build())))
            .build()
        http.newAsyncCall(httpRequest) {
            if (it.httpStatus == 200) {
                updateToken(Json.parseToJsonElement(it.body)) { _ ->
                    listener.onSuccess(it.body)
                }
            } else {
                val errorRequest = try {
                    ApiRequest.parseError(Json.parseToJsonElement(it.body))
                } catch (e: Exception) {
                    ErrorRequest(-1, "Unknown Error")
                }
                listener.onError(it.httpStatus, errorRequest)
            }
        }
    }

    override fun isLogged(onReturn: (isLogged: Boolean) -> Unit) {
        storage.getItemFromTable("users", REFRESH_TOKEN_STORAGE_KEY) { refreshToken, _ ->
            onReturn(refreshToken != null)
        }
    }

    override fun refreshToken(listener: OAuth.RefreshTokenListener) {
        requestNewAccessToken( object : OAuth.RefreshTokenListener {
            override fun onSuccess(accessToken: String) {
                listener.onSuccess(accessToken)
            }

            override fun onError(status: Int, error: ErrorRequest): Boolean {
                return listener.onError(status, error)
            }
        })
    }

    override fun getAccessToken(listener: OAuth.RefreshTokenListener) {
        if (shouldRefreshToken) {
            shouldRefreshToken = false
            requestNewAccessToken(listener)
            return
        }

        reentrantLock.lock("refreshToken") {
            storage.getItemFromTable("users", ACCESS_TOKEN_STORAGE_KEY) { accessToken, _ ->
                if (accessToken != null) {
                    reentrantLock.unlock("refreshToken")
                    listener.onSuccess(accessToken)
                } else {
                    reentrantLock.unlock("refreshToken")
                    requestNewAccessToken(listener)
                }
            }
        }
    }

    override fun onUnexpectedDisconnection(onDisconnected: () -> Unit) {
        onUnexpectedDisconnectionListeners.add(onDisconnected)
    }

    private fun updateToken(body: JsonElement, onUpdated: (accessToken: String) -> Unit) {
        this.storage.setItemToTable("users",
            ACCESS_TOKEN_STORAGE_KEY,
            getString(body.jsonObject["access_token"]),
            getInt(body.jsonObject["expires_in"]).toLong()) {
            this.storage.setItemToTable("users", REFRESH_TOKEN_STORAGE_KEY,
                getString(body.jsonObject["refresh_token"]),
                getIntOrNull(body.jsonObject["refresh_expires_in"])?.toLong() ?: 7_890_000
            ) {
                onUpdated(getString(body.jsonObject["access_token"]))
            }
        }
    }

    private fun requestNewAccessToken(listener: OAuth.RefreshTokenListener) {
        Logger.d("services: token expired")
        reentrantLock.callOnce("refreshToken", {
            storage.getItemFromTable("users", REFRESH_TOKEN_STORAGE_KEY) { refreshToken, _ ->
                Logger.d("refreshToken: $refreshToken")
                if (refreshToken != null) {

                    val parameterBuilder = Parameter.Builder()
                    parameterBuilder.addParameter("client_id", oAuthConfig.clientId)
                    parameterBuilder.addParameter("client_secret", oAuthConfig.secretId)
                    parameterBuilder.addParameter("grant_type", "refresh_token")
                    parameterBuilder.addParameter("refresh_token", refreshToken)

                    val urlFormated = oAuthConfig.apiUrl + oAuthConfig.loginUrl

                    val httpRequest = Request.Builder(urlFormated)
                        .post(RequestBody.create("application/x-www-form-urlencoded", formatGetParams(parameterBuilder.build())))
                        .build()

                    http.newAsyncCall(httpRequest) {
                        if (it.httpStatus == 200) {
                            updateToken(Json.parseToJsonElement(it.body)) { accessToken ->
                                reentrantLock.unlock("refreshToken")
                                listener.onSuccess(accessToken)
                            }
                        } else {
                            reentrantLock.unlock("refreshToken")
                            Logger.e("Security request failed: oauth/v2/token, error: ${it.httpStatus}")
                            if (it.httpStatus == 401) {
                                storage.removeAll { // Logout
                                    onUnexpectedDisconnectionListeners.forEach { listener -> listener.invoke() }
                                }
                            } else {
                                // Can be connectivity error
                            }
                        }
                    }
                } else {
                    reentrantLock.unlock("refreshToken")
                    Logger.e("refreshToken not found in pref")
                    listener.onError(9999, ErrorRequest(6000, "refreshToken not found in pref"))
                    storage.removeAll { // Logout
                        onUnexpectedDisconnectionListeners.forEach { listener -> listener.invoke() }
                    }
                }
            }}
        ) {
            reentrantLock.unlock("refreshToken")
            getAccessToken(listener)
        }
    }
}