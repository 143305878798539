package com.wicupp.safetymap.dashboard.utils

import com.soywiz.klock.DateFormat
import com.soywiz.klock.DateTime
import com.wicupp.beaver.core.logger.Logger
import kotlinx.datetime.*
import kotlinx.serialization.Serializable

object OpenHoursUtils {

    fun List<OpeningHoursPeriod>.canBeInserted(
        newOpeningPeriod: OpeningHoursPeriod
    ): Boolean {
        val openDay = newOpeningPeriod.open.day
        val openTime = newOpeningPeriod.open.time

        val closeDay = newOpeningPeriod.close?.day
        val closeTime = newOpeningPeriod.close?.time

        forEach {
            val zoneOpenDay = it.open.day
            val zoneOpenTime = it.open.time
            val zoneCloseDay = it.close?.day
            val zoneCloseTime = it.close?.time
            Logger.d("compare : openDay: $openDay:$openTime, closeDay: $closeDay:$closeTime")
            Logger.d("with : zoneOpenDay: $zoneOpenDay:$zoneOpenTime, zoneCloseDay: $zoneCloseDay:$zoneCloseTime")

            if (closeDay == null || closeTime == null) {
                return false
            } else if (zoneCloseDay == null || zoneCloseTime == null) {
                return false
            } else if (openDay >= zoneOpenDay && currentAfter(openTime, zoneOpenTime) && openDay <= zoneCloseDay && currentAfter(zoneCloseTime, openTime)) {
                return false
            } else if (closeDay >= zoneOpenDay && currentAfter(closeTime, zoneOpenTime) && closeDay <= zoneCloseDay && currentAfter(zoneCloseTime, closeTime)) {
                return false
            }
        }
        Logger.d("valid !")
        return true
    }

    fun isOpened(
        timezoneOffset: Int,
        openingPeriods: Array<OpeningHoursPeriod>
    ): Boolean {
        val currentHour = getCurrentHour(timezoneOffset)
        val currentDay = getCurrentDay(timezoneOffset)

        openingPeriods.forEach {
            if ((currentDay == it.open.day && currentAfter(currentHour, it.open.time)) &&
                (it.close == null || currentDay != it.close.day || !currentAfter(currentHour, it.close.time))) {
                return true
            }
        }
        return false
    }

    private fun getCurrentDay(timezoneOffset: Int): Int {
        val dateTimeWithOffset = getDateTimeWithOffset(timezoneOffset)
        val dateFormat = DateFormat("EEEE") //EEE, dd MMM yyyy HH:mm:ss z
        return dayOfWeekToInt(
            dateTimeWithOffset.format(dateFormat)
        )
    }

    private fun getCurrentHour(timezoneOffset: Int): String {
        val dateTimeWithOffset = getDateTimeWithOffset(timezoneOffset)
        val dateFormat = DateFormat("HHmm") //EEE, dd MMM yyyy HH:mm:ss z
        return dateTimeWithOffset.format(dateFormat)
    }

    private fun currentAfter(
        currentHour: String,
        hour: String
    ): Boolean {
        val currentHourArray = getHourIntFromString(currentHour)
        val hourArray = getHourIntFromString(hour)
        return currentHourArray[0] > hourArray[0] ||
                (currentHourArray[0] == hourArray[0] && currentHourArray[1] > hourArray[1])
    }

    /**
     * [hour,minute]
     */
    private fun getHourIntFromString(value: String): Array<Int> {
        return arrayOf(
            value.substring(0, 2).toInt(),
            value.substring(2, 4).toInt()
        )
    }

    private fun getDateTimeWithOffset(timezoneOffset: Int): DateTime {
        val localDateTime = Instant.fromEpochSeconds(DateTime.now().unixMillisLong / 1000, 0)
            .toLocalDateTime(TimeZone.UTC)
        return DateTime.fromUnix(
            localDateTime.toInstant(TimeZone.UTC).toEpochMilliseconds() + timezoneOffset
        )
    }

    private fun dayOfWeekToInt(value: String): Int {
        return when (value) {
            "Monday" -> 1
            "Tuesday" -> 2
            "Wednesday" -> 3
            "Thursday" -> 4
            "Friday" -> 5
            "Saturday" -> 6
            "Sunday" -> 7
            else -> {
                Logger.e("The day of week $value is unknown.")
                -1
            }
        }
    }
}

@Serializable
class OpeningHoursPeriod(
    val open: OpeningHoursPeriodDetail,
    val close: OpeningHoursPeriodDetail? = null
)

@Serializable
class OpeningHoursPeriodDetail(
    val day: Int, // A number from 0 - 6 (ex: 2 means Tuesday)
    val time: String // 24-hour hhmm format
)