package com.wicupp.beaver.js.core.build_config

import com.wicupp.beaver.core.BuildConfigInterface
import com.wicupp.beaver.web.WebBuildConfig

class BuildConfigImpl: BuildConfigInterface {
    override fun debug(): Boolean {
        return WebBuildConfig.debug()
    }

    override fun release(): Boolean {
        return WebBuildConfig.release()
    }

    override fun getField(key: String): String {
        return WebBuildConfig.getField(key)
    }
}