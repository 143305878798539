package com.wicupp.safetymap.dashboard.settings.email

import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.api.UserApi
import com.wicupp.safetymap.dashboard.data.flux.notifier.UserNotifier
import com.wicupp.safetymap.dashboard.error.AppError
import com.wicupp.safetymap.dashboard.error.AppErrorId
import com.wicupp.safetymap.dashboard.error.ErrorManager
import com.wicupp.safetymap.dashboard.utils.StringUtils

/**
 * [EditEmailContract.Presenter] implementation.
 */
class EditEmailPresenterImpl(
    private val userApi: UserApi,
    private val userNotifier: UserNotifier,
    private val handler: Handler
) : EditEmailContract.Presenter {

    private var view: EditEmailContract.View? = null
    private var email: String = ""
    private var token: String = ""

    override fun attachView(view: EditEmailContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
        email = ""
    }

    override fun detachView(view: EditEmailContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun requestVerificationCode(email: String) {
        if (!StringUtils.isValidEmailAddress(email)) {
            view?.showError(AppError(AppErrorId.EMAIL_NOT_VALID))
            return
        }
        this.email = email

        userNotifier.get()?.let {
            handler.post { view?.showLoadingIndicator(true) }
            userApi.editEmailRequest(it.email, object : ApiListener<String> {
                override fun onSuccess(apiResponse: ApiResponse<String>) {
                    this@EditEmailPresenterImpl.token = apiResponse.body
                    handler.post {
                        view?.showLoadingIndicator(false)
                        view?.showVerificationCodeView()
                    }
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        ErrorManager.formatRequestError(status, error)?.let {
                            view?.showError(it)
                        }
                    }
                    return true
                }
            })
        }
    }

    override fun setVerificationCode(code: Int) {
        view?.showLoadingIndicator(true)

        userApi.setEmail(email, token, code, object : ApiListener<Unit> {
            override fun onSuccess(apiResponse: ApiResponse<Unit>) {
                handler.post {
                    view?.showConfirmation()
                    view?.showLoadingIndicator(false)
                }
            }

            override fun onError(status: Int, error: ErrorRequest): Boolean {
                handler.post {
                    view?.showLoadingIndicator(false)
                    ErrorManager.formatRequestError(status, error)?.let {
                        view?.showError(it)
                    }
                }
                return true
            }
        })
    }
}
