package com.wicupp.beaver.utils

import kotlinx.serialization.json.*

class JsonUtils {
    companion object {

        fun getString(jsonElement: JsonElement?): String {
            if (jsonElement !is JsonNull) {
                jsonElement?.jsonPrimitive?.content?.let {
                    return it
                }
            }
            throw IllegalArgumentException("json key should not be null.")
        }

        fun getStringOrNull(jsonElement: JsonElement?): String? {
            if (jsonElement !is JsonNull) {
                return jsonElement?.jsonPrimitive?.content
            }
            return null
        }

        fun getInt(jsonElement: JsonElement?): Int {
            if (jsonElement !is JsonNull) {
                jsonElement?.jsonPrimitive?.int?.let {
                    return it
                }
            }
            throw IllegalArgumentException("json key should not be null.")
        }

        fun getIntOrNull(jsonElement: JsonElement?): Int? {
            if (jsonElement !is JsonNull) {
                return jsonElement?.jsonPrimitive?.int
            }
            return null
        }

        fun getFloat(jsonElement: JsonElement?): Float {
            if (jsonElement !is JsonNull) {
                jsonElement?.jsonPrimitive?.float?.let {
                    return it
                }
            }
            throw IllegalArgumentException("json key should not be null.")
        }

        fun getFloatOrNull(jsonElement: JsonElement?): Float? {
            if (jsonElement !is JsonNull) {
                return jsonElement?.jsonPrimitive?.floatOrNull
            }
            return null
        }

        fun getBoolean(jsonElement: JsonElement?): Boolean {
            if (jsonElement !is JsonNull) {
                jsonElement?.jsonPrimitive?.boolean?.let {
                    return it
                }
            }
            throw IllegalArgumentException("json key should not be null.")
        }

        fun getBooleanOrNull(jsonElement: JsonElement?): Boolean? {
            if (jsonElement !is JsonNull) {
                return jsonElement?.jsonPrimitive?.booleanOrNull
            }
            return null
        }

        fun getDouble(jsonElement: JsonElement?): Double {
            if (jsonElement !is JsonNull) {
                jsonElement?.jsonPrimitive?.double?.let {
                    return it
                }
            }
            throw IllegalArgumentException("json key should not be null.")
        }

        fun getDoubleOrNull(jsonElement: JsonElement?): Double? {
            if (jsonElement !is JsonNull) {
                return jsonElement?.jsonPrimitive?.doubleOrNull
            }
            return null
        }

        fun getArray(jsonElement: JsonElement?): JsonArray {
            if (jsonElement !is JsonNull) {
                jsonElement?.jsonArray?.let {
                    return it
                }
            }
            throw IllegalArgumentException("json key should not be null.")
        }

        fun getArrayOrNull(jsonElement: JsonElement?): JsonArray? {
            if (jsonElement !is JsonNull) {
                return jsonElement?.jsonArray
            }
            return null
        }

        fun getObjectOrNull(jsonElement: JsonElement?): JsonObject? {
            if (jsonElement !is JsonNull && jsonElement is JsonObject) {
                return jsonElement.jsonObject
            }
            return null
        }
    }
}