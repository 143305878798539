package com.wicupp.safetymap.dashboard.data.api

import com.wicupp.safetymap.dashboard.data.model.PlaceInformation
import com.wicupp.safetymap.dashboard.data.model.Street

interface SearchApi {

    fun searchStreet(
        street: String,
        listener: ApiListener<List<Street>>
    )

    fun getPlaceInformation(
        description: String,
        listener: ApiListener<PlaceInformation>
    )

    class StreetsResponse(override var body: List<Street>) : ApiResponse<List<Street>>()
    class PlaceInformationResponse(override var body: PlaceInformation) : ApiResponse<PlaceInformation>()
}