package com.wicupp.safetymap.dashboard.settings.delete

import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.component.EditText
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.layout.LinearLayout
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory
import com.wicupp.safetymap.dashboard.settings.delete.DeleteAccountContract

class DeleteAccountView(cv: ContextView): Layout(cv), DeleteAccountContract.View {

    companion object {
        const val TAG_NAME = "delete-account-view"
    }

    override fun layoutName() = "view-delete-account"
    var listener: Listener? = null
    private val presenter: DeleteAccountContract.Presenter = Inject.get()
    private var verificationCodeLayout: LinearLayout? = null
    private var confirmationLayout: LinearLayout? = null
    private var emailConfirmationLayout: LinearLayout? = null
    private var nextButton: ButtonView? = null
    private var confirmButton: ButtonView? = null

    override fun onCreate() {
        super.onCreate()

        confirmationLayout = findViewById<LinearLayout>("view-delete-account-confirmation")
        verificationCodeLayout = findViewById<LinearLayout>("view-delete-account-enter-code")

        nextButton = findViewById<ButtonView>("view-delete-account-next").apply {
            onClick {
                presenter.requestVerificationCode()
            }
        }

        confirmButton = findViewById<ButtonView>("view-delete-account-delete-button").apply {
            onClick {
                presenter.setVerificationCode(
                    findViewById<EditText>("view-delete-account-code-input").getValue().toInt()
                )
            }
        }
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
    }

    override fun onDetached() {
        presenter.detachView(this)
        super.onDetached()
    }

    override fun showLoadingIndicator(show: Boolean) {
        if (show) {
            nextButton?.startLoading()
            confirmButton?.startLoading()
        } else {
            nextButton?.stopLoading()
            confirmButton?.stopLoading()
        }
    }

    override fun showVerificationCodeView() {
        confirmationLayout?.setVisibility(View.GONE, true)
        emailConfirmationLayout?.setVisibility(View.GONE, true)
        verificationCodeLayout?.setVisibility(View.VISIBLE, true)
    }

    override fun showConfirmation() {
        listener?.onAccountDeleted()
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    interface Listener {
        fun onAccountDeleted()
    }
}