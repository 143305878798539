package com.wicupp.beaver.js.core.position

import com.wicupp.beaver.core.position.DeviceLocation

class DeviceLocationImpl: DeviceLocation {

    val positionJSImpl = DeviceLocationJSImpl()

    override fun getDeviceLocation(listener: DeviceLocation.Listener) {
        positionJSImpl.getDeviceLocation(object : DeviceLocationJSImpl.Listener {
            override fun onSuccess(latitude: Double, longitude: Double, radius: Double) {
                listener.onSuccess(latitude, longitude, radius)
            }

            override fun onError(error: String) {
                listener.onError(error)
            }
        })
    }
}