package com.wicupp.safetymap.dashboard.board.refuge

import com.wicupp.beaver.web.view.component.QRCodeView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout

class RefugeNetworkSharingView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "refuge-network-sharing-view"
        const val QR_CODE_SIZE = 140
    }

    override fun layoutName() = "view-refuge-network-sharing"

    private lateinit var qrCodeView: QRCodeView

    var projectId: String? = null

    override fun onCreate() {
        super.onCreate()

        qrCodeView = findViewById("view-refuge-network-sharing-qrcode")

        projectId?.let {
            // TODO create a real payload
            qrCodeView.drawQRCode(it, QR_CODE_SIZE, getColor("color-accent"))
        }
    }
}