package com.wicupp.safetymap.dashboard.board.refuge.edit.categories

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.web.view.layout.Adapter
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.safetymap.dashboard.data.model.Refuge
import com.wicupp.safetymap.dashboard.ui.SwitchItemView
import com.wicupp.safetymap.dashboard.utils.stringRes

class RefugeCategoriesAdapter : Adapter() {

    var listener: Listener? = null

    private var views: Map<Refuge.Category, SwitchItemView> = mapOf()

    fun setCategories(
        categories: List<Refuge.Category>,
        selectedCategories: List<Refuge.Category>
    ) {
        removeAllViews()
        views = buildMap {
            for (category: Refuge.Category in categories) {
                val view = buildView(category, selectedCategories.contains(category))
                addView(view)
                put(category, view)
            }
        }
    }

    fun getCategories(): List<Refuge.Category> {
        return views.filter {
            it.value.selected
        }.map {
            it.key
        }
    }

    private fun buildView(
        category: Refuge.Category,
        selected: Boolean
    ): SwitchItemView {
        val item = Layout.createLayout(SwitchItemView::class.js)
        item.text = item.getString(category.stringRes)
        item.selected = selected
        item.listener = object : SwitchItemView.Listener {
            override fun onChanged(selected: Boolean) {
                Logger.d("onChanged is : $selected")
                listener?.onChanged(category, selected)
            }

            override fun onClickWhenDisabled() {
                listener?.onClickWhenDisabled(category)
            }
        }
        return item
    }

    interface Listener {
        fun onChanged(
            category: Refuge.Category,
            selected: Boolean
        )

        fun onClickWhenDisabled(category: Refuge.Category)
    }
}