package com.wicupp.beaver.request.http

class RequestBody private constructor(val contentType: String,
                                      val postBody: String) {

    companion object {
        fun create(contentType: String, postBody: String): RequestBody {
            return RequestBody(contentType, postBody)
        }
    }
}