package com.wicupp.safetymap.dashboard.board.refuge

import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.view.component.*
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.layout.LinearLayout
import com.wicupp.beaver.web.view.smartview.AlertDialog
import com.wicupp.beaver.web.view.smartview.ButtonOption
import com.wicupp.beaver.web.view.smartview.SmartView
import com.wicupp.beaver.web.view.smartview.SmartViewType
import com.wicupp.safetymap.dashboard.board.refuge.edit.EditRefugeView
import com.wicupp.safetymap.dashboard.board.refuge.edit.address.AddAddressView
import com.wicupp.safetymap.dashboard.board.refuge.edit.address.model.Address
import com.wicupp.safetymap.dashboard.data.model.Refuge
import com.wicupp.safetymap.dashboard.data.model.RefugeKey
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory
import com.wicupp.safetymap.dashboard.ui.EditableTextView

class RefugesView(cv: ContextView): Layout(cv), RefugesContract.View {

    companion object {
        const val TAG_NAME = "app-refuges"
    }

    override fun layoutName() = "view-refuges"

    private var refugeList: ListView? = null
    private var adapter: RefugeAdapter = RefugeAdapter()
    private val presenter: RefugesContract.Presenter = Inject.get()
    private var loadingView: SpinnerBar? = null
    private var emptyView: RefugesEmptyView? = null
    private var contentView: LinearLayout? = null

    override fun onCreate() {
        super.onCreate()

        loadingView = findViewById("view-refuges-loading")

        refugeList = findViewById("view-refuges-list")

        emptyView = findViewById("view-refuges-empty")
        emptyView?.listener = object : RefugesEmptyView.Listener {
            override fun onCreateRefugeRequested() {
                presenter.requestCreateRefugeView()
            }
        }

        contentView = findViewById("view-refuges-content")

        findViewById<ButtonView>("view-refuges-add-btn").onClick {
            presenter.requestCreateRefugeView()
        }

        adapter.listener = object : RefugeAdapter.Listener {
            override fun onRefugeSelected(refuge: Refuge) {
                showRefugeEdition(refugeId = refuge.id)
            }

            override fun onDeleteRequested(refuge: Refuge) {
                showRefugeDeletionConfirmation(refuge)
            }
        }
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
        presenter.requestRefuges()
    }

    override fun onDetached() {
        presenter.detachView(this)
        super.onDetached()
    }

    override fun showLoadingIndicator(show: Boolean) {
        loadingView?.setVisibility(if (show) View.VISIBLE else View.GONE)
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    override fun showRefuges(
        refugeNetworkName: String,
        refuges: List<Refuge>
    ) {
        adapter.setRefuges(refuges)
        refugeList?.setAdapter(adapter)
    }

    override fun showEmptyView(show: Boolean) {
        if (show) {
            emptyView?.setVisibility(View.VISIBLE)
            contentView?.setVisibility(View.GONE)
        } else {
            emptyView?.setVisibility(View.GONE)
            contentView?.setVisibility(View.VISIBLE)
        }
    }

    override fun showSharingView(projectId: String) {
        val refugeNetworkSharingView = createLayout(RefugeNetworkSharingView::class.js)
        refugeNetworkSharingView.projectId = projectId
        val smartView = SmartView(
            this,
            "refuge-network-sharing-dialog-view",
            refugeNetworkSharingView
        )
            .hideTitle()
            .setType(SmartViewType.DEFAULT)
            .setCloseButton(
                ButtonOption.Builder()
                    .icon("none")
                    .build()) {
                // Do nothing
            }
            .hideTopBar(true)
            .create()
            .show()
    }

    override fun showCreateRefugeView(projectId: String) {
        showCreateRefuge(projectId)
    }

    private fun showCreateRefuge(projectId: String) {

        val addAddressView = createLayout(AddAddressView::class.js)
        val smartView = SmartView(
            this,
            getString("project-add-refuge"),
            addAddressView
        )
            .setType(SmartViewType.DEFAULT)
            .create()
            .show()

        addAddressView.listener = object : AddAddressView.Listener {
            override fun onAddressDefined(address: Address) {
                smartView.hide {
                    showRefugeEdition(
                        projectId = projectId,
                        preFilledRefuge = address.updateRefuge(Refuge.emptyRefuge())
                    )
                }
            }

        }
    }

    fun requestCreateRefugeView() {
        presenter.requestCreateRefugeView()
    }

    fun requestSharingView() {
        presenter.requestSharingView()
    }

    private fun showRefugeEdition(
        projectId: String? = null,
        refugeId: String? = null,
        preFilledRefuge: Refuge? = null
    ) {
        val createRefuge = if (refugeId == null) true else false
        val editRefugeView = createLayout(EditRefugeView::class.js)
        editRefugeView.projectId = projectId
        editRefugeView.refugeId = refugeId
        editRefugeView.refuge = preFilledRefuge
        val smartView = SmartView(
            this,
            if (createRefuge) getString("project-add-refuge") else getString("project-edit-refuge"),
            editRefugeView
        )
            .setType(SmartViewType.DEFAULT)
            .setPositiveButton(ButtonOption.builder().run {
                if (createRefuge) text(getString("common-add")) else text(getString("common-edit"))
                build()
            }) {
                if (createRefuge) {
                    editRefugeView.createRefuge()
                } else {
                    editRefugeView.editRefuge()
                }
                true
            }
            .disableCancelOnBackground()
            .create()
            .show()

        editRefugeView.listener = object : EditRefugeView.Listener {
            override fun onCancel() {
                smartView.hide()
            }

            override fun onRefugeUpdated() {
                smartView.hide()
            }
        }
    }

    private fun showRefugeDeletionConfirmation(refuge: Refuge) {
        AlertDialog.Builder(this)
            .setTitle(getString("common-delete"))
            .setMessage(getString("project-delete-refuge-confirmation"))
            .setPositiveButton(getString("common-delete")) {
                it.hide {
                    presenter.deleteRefuge(refuge.id)
                }
            }
            .setNegativeButton(getString("common-cancel")) {
                it.hide()
            }
            .create()
            .show()
    }
}