package com.wicupp.safetymap.dashboard

import com.wicupp.beaver.core.Context
import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.js.core.connectivity.ConnectivityChangeImpl
import com.wicupp.beaver.js.core.position.DeviceLocationImpl
import com.wicupp.beaver.js.core.task.HandlerImpl
import com.wicupp.beaver.js.push.PushImpl
import com.wicupp.beaver.js.request.http.HttpImpl
import com.wicupp.beaver.js.share.ShareImpl
import com.wicupp.beaver.request.ApiRequest
import com.wicupp.beaver.request.OAuthV2Impl
import com.wicupp.beaver.storage.KeyValueStorage
import com.wicupp.beaver.web.WebContext
import com.wicupp.beaver.web.view.activity.ActivityContext
import com.wicupp.safetymap.dashboard.data.flux.GlobalDispatcher
import com.wicupp.safetymap.dashboard.data.flux.notifier.UserNotifier
import com.wicupp.safetymap.dashboard.menu.MenuPresenterImpl
import com.wicupp.safetymap.dashboard.routing.RoutingManager
import com.wicupp.safetymap.dashboard.board.MainPresenterImpl
import com.wicupp.safetymap.dashboard.board.api.ApiPresenterImpl
import com.wicupp.safetymap.dashboard.board.project.ProjectSettingsPresenterImpl
import com.wicupp.safetymap.dashboard.board.refuge.RefugesPresenterImpl
import com.wicupp.safetymap.dashboard.board.refuge.edit.EditRefugePresenterImpl
import com.wicupp.safetymap.dashboard.board.refuge.edit.address.AddAddressPresenterImpl
import com.wicupp.safetymap.dashboard.board.report.ReportIncidentPresenterImpl
import com.wicupp.safetymap.dashboard.data.api.*
import com.wicupp.safetymap.dashboard.data.flux.notifier.RefugesNotifier
import com.wicupp.safetymap.dashboard.onboarding.OnboardingPresenterImpl
import com.wicupp.safetymap.dashboard.subscription.SubscriptionPresenterImpl
import com.wicupp.safetymap.dashboard.settings.SettingsPresenterImpl
import com.wicupp.safetymap.dashboard.settings.delete.DeleteAccountPresenterImpl
import com.wicupp.safetymap.dashboard.settings.email.EditEmailPresenterImpl
import com.wicupp.safetymap.dashboard.sign.SignPresenterImpl
import com.wicupp.safetymap.dashboard.subscription.cancel.SubscriptionCancelPresenterImpl
import com.wicupp.safetymap.dashboard.subscription.success.SubscriptionSuccessPresenterImpl
import com.wicupp.timezone.TimeZoneFinderImpl

class AppModule(
    context: Context,
    activityContext: ActivityContext,
    webContext: WebContext,
    storage: KeyValueStorage
) {

    init {
        Inject.provide { context }
        Inject.provide { activityContext }
        Inject.provide { webContext }
        Inject.provide { storage }
        Inject.provide { PushImpl() }
        Inject.provide { HandlerImpl() }
        Inject.provide { ConnectivityChangeImpl() }
        Inject.provide { DeviceLocationImpl() }
        Inject.provide { HttpImpl() }
        Inject.provide { OAuthConfigImpl() }
        Inject.provide { OAuthV2Impl(it.get(), it.get(), it.get()) }
        Inject.provide { ApiRequest(SafetymapConfig.apiUrl, it.get(), it.get(), it.get(), it.get(), it.get()) }

        Inject.provide { TimeZoneFinderImpl() }

        Inject.provide { UserApi(it.get()) }
        Inject.provide { AdminApiImpl(it.get()) }
        Inject.provide { SubscriptionApi(it.get()) }
        Inject.provide { SearchApiImpl(it.get()) }

        Inject.provide { UserNotifier() }
        Inject.provide { RefugesNotifier() }
        Inject.provide {
            GlobalDispatcher(it.get(), it.get(), it.get(), it.get())
        }
        Inject.provide { RoutingManager(it.get(), it.get(), it.get(), it.get(), it.get(), it.get(), it.get()) }

        Inject.provide { ShareImpl() }

        Inject.provide { MenuPresenterImpl(it.get(), it.get(), it.get()) }
        Inject.provide { SignPresenterImpl(it.get(), it.get(), it.get(), it.get()) }
        Inject.provide { SettingsPresenterImpl(it.get(), it.get(), it.get(), it.get(), it.get()) }
        Inject.provide { EditEmailPresenterImpl(it.get(), it.get(), it.get()) }
        Inject.provide { MainPresenterImpl(it.get(), it.get(), it.get(), it.get(), it.get(), it.get()) }

        Inject.provide { DeleteAccountPresenterImpl(it.get(), it.get()) }
        Inject.provide { EditRefugePresenterImpl(it.get(), it.get(), it.get()) }
        Inject.provide { AddAddressPresenterImpl(it.get(), it.get(), it.get()) }
        Inject.provide { RefugesPresenterImpl(it.get(), it.get(), it.get(), it.get()) }
        Inject.provide { ApiPresenterImpl(it.get(), it.get()) }
        Inject.provide { SubscriptionPresenterImpl(it.get(), it.get(), it.get(), it.get()) }
        Inject.provide { SubscriptionCancelPresenterImpl(it.get(), it.get(), it.get()) }
        Inject.provide { SubscriptionSuccessPresenterImpl(it.get(), it.get(), it.get()) }
        Inject.provide { ReportIncidentPresenterImpl(it.get(), it.get(), it.get(), it.get()) }
        Inject.provide { OnboardingPresenterImpl(it.get(), it.get(), it.get(), it.get()) }
        Inject.provide { ProjectSettingsPresenterImpl(it.get(), it.get(), it.get()) }
    }
}