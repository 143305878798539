package com.wicupp.safetymap.dashboard.board.refuge.edit.address

import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.safetymap.dashboard.board.refuge.edit.address.model.Address
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.api.SearchApi
import com.wicupp.safetymap.dashboard.data.model.PlaceInformation
import com.wicupp.safetymap.dashboard.data.model.Street
import com.wicupp.safetymap.dashboard.error.ErrorManager
import com.wicupp.timezone.TimeZoneFinder
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlin.coroutines.resumeWithException
import kotlin.coroutines.suspendCoroutine

/**
 * [AddAddressContract.Presenter] implementation.
 */
class AddAddressPresenterImpl(
    private val searchApi: SearchApi,
    private val handler: Handler,
    private val timeZoneFinder: TimeZoneFinder
) : AddAddressContract.Presenter {

    private var view: AddAddressContract.View? = null

    override fun attachView(view: AddAddressContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
    }

    override fun detachView(view: AddAddressContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun searchAddress(address: String) {
        view?.showLoadingIndicator(true)

        searchApi.searchStreet(
            address,
            object : ApiListener<List<Street>> {
                override fun onSuccess(apiResponse: ApiResponse<List<Street>>) {
                    CoroutineScope(Dispatchers.Main).launch {
                        val streets = getStreets(apiResponse)
                        handler.post {
                            view?.showLoadingIndicator(false)
                            view?.showAddresses(streets)
                        }
                    }
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        ErrorManager.formatRequestError(status, error)?.let {
                            view?.showError(it)
                        }
                    }
                    return true
                }
            }
        )
    }

    override fun requestOpeningHours(address: Address) {
        view?.showLoadingIndicator(true)

        searchApi.getPlaceInformation(
            address.street,
            object : ApiListener<PlaceInformation> {
                override fun onSuccess(apiResponse: ApiResponse<PlaceInformation>) {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        view?.showSelectedAddress(address.toBuilder().run {
                            placeInformation = apiResponse.body
                            build()
                        })
                    }
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        if (status == 404) {
                            view?.showSelectedAddress(address)
                        } else {
                            ErrorManager.formatRequestError(status, error)?.let {
                                view?.showError(it)
                            }
                        }
                    }
                    return true
                }
            }
        )
    }

    suspend fun getStreets(
        apiResponse: ApiResponse<List<Street>>
    ): List<Address> {
        return apiResponse.body.map {
            Address.builder().run {
                street = it.street
                latitude = it.latitude
                longitude = it.longitude
                timezone = getTimezone(
                    it.latitude,
                    it.longitude
                )
                placeInformation = null
                build()
            }
        }
    }
    
    suspend fun getTimezone(
        latitude: Double,
        longitude: Double
    ): String = suspendCoroutine { continuation ->
        timeZoneFinder.fromLatLong(
            latitude,
            longitude
        ) { hadError, timezone ->  
            if (hadError) {
                continuation.resumeWithException(IllegalStateException("Timezone not handled"))
            } else {
                continuation.resumeWith(Result.success(timezone))
            }
        }
    }

}
