package com.wicupp.beaver.flux

import kotlin.reflect.KClass

abstract class Dispatcher {

    var isExecuting = false
    var handlers = mutableListOf<() -> Unit>()
    var onModelChangedList = mutableListOf<() -> Unit>()
    var currentModel: CoreModel? = null

    abstract fun createModelInit(): CoreModel

    fun executeHandlerWhenAvailable(onAvailable: () -> Unit) {
        if (isExecuting) {
            handlers.add(onAvailable)
        } else {
            isExecuting = true
            onAvailable()
        }
    }

    fun subscribeToStore(onModelChanged: () -> Unit) {
        onModelChangedList.add(onModelChanged)
    }

    fun getStateFromStore(): CoreModel? {
        return currentModel
    }

    fun dispatch(cls: KClass<out Action>, coreModel: CoreModel) {
        //this.store.dispatch({type: 'Model', actionClass: cls, model: coreModel})
        currentModel = reducer(coreModel)
        // TODO execute only when model changed

        onModelChangedList.forEach {
            it()
        }
    }

    fun finishExecution() {
        if (handlers.isNotEmpty()) {
            val onAvailable = this.handlers.removeLast()
            onAvailable()
        } else {
            isExecuting = false
        }
    }

    private fun reducer(newModel: CoreModel): CoreModel {
        // TODO reduce
        return newModel
    }
}