package com.wicupp.safetymap.dashboard.data.flux.handler

import com.wicupp.beaver.flux.*
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.safetymap.dashboard.data.api.AdminApi
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.flux.action.GetRefugesAction
import com.wicupp.safetymap.dashboard.data.model.Model
import com.wicupp.safetymap.dashboard.data.model.Refuge

class GetRefugesHandler(
    dispatcher: Dispatcher,
    rootNotifier: RootNotifier,
    private val adminApi: AdminApi
) : Handler<GetRefugesAction, Model>(dispatcher, rootNotifier) {

    override fun execute(model: Model, action: GetRefugesAction): GetRefugesHandler {

        adminApi.getRefuges(
            action.projectId,
            object : ApiListener<List<Refuge>> {
                override fun onSuccess(apiResponse: ApiResponse<List<Refuge>>) {
                    success(GetRefugesAction::class, model.toBuilder()
                        .refuges(apiResponse.body)
                        .build())
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    // Do nothing, executed on background
                    error(status, error)
                    return true
                }
            })

        return this
    }
}