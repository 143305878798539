package com.wicupp.safetymap.dashboard.subscription

import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.beaver.request.OAuth
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.api.SubscriptionApi
import com.wicupp.safetymap.dashboard.data.api.UserApi
import com.wicupp.safetymap.dashboard.data.model.SubscriptionPlan
import com.wicupp.safetymap.dashboard.error.ErrorManager

/**
 * [SubscriptionContract.Presenter] implementation.
 */
class SubscriptionPresenterImpl(
    private val handler: Handler,
    private val oauth: OAuth,
    private val subscriptionApi: SubscriptionApi,
    private val userApi: UserApi
): SubscriptionContract.Presenter {

    private var view: SubscriptionContract.View? = null

    override fun attachView(view: SubscriptionContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
    }

    override fun detachView(view: SubscriptionContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun requestSubscription(subscriptionPlan: SubscriptionPlan) {
        // TODO check if already subscription has been done, trigger error if yes
        handler.post {
            view?.showLoadingIndicator(true)
        }
        oauth.isLogged { isLogged ->
            handler.post {
                if (isLogged) {
                    openCheckoutSession(subscriptionPlan)
                } else {
                    view?.showLoadingIndicator(false)
                    view?.showSignIn()
                }
            }
        }
    }

    private fun openCheckoutSession(subscriptionPlan: SubscriptionPlan) {
        subscriptionApi.openCheckoutSession(
            subscriptionPlan,
            object : ApiListener<String> {
                override fun onSuccess(apiResponse: ApiResponse<String>) {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        view?.showSubscriptionView(apiResponse.body)
                    }
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        ErrorManager.formatRequestError(status, error)?.let {
                            view?.showError(it)
                        }
                    }
                    return true
                }
            })
    }
}
