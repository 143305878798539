package com.wicupp.safetymap.dashboard.settings

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.beaver.core.task.Handler
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.api.SubscriptionApi
import com.wicupp.safetymap.dashboard.data.api.UserApi
import com.wicupp.safetymap.dashboard.data.flux.GlobalDispatcher
import com.wicupp.safetymap.dashboard.data.flux.action.GetUserAction
import com.wicupp.safetymap.dashboard.data.flux.notifier.UserListener
import com.wicupp.safetymap.dashboard.data.flux.notifier.UserNotifier
import com.wicupp.safetymap.dashboard.data.model.User
import com.wicupp.safetymap.dashboard.error.ErrorManager

/**
 * [SettingsContract.Presenter] implementation.
 */
class SettingsPresenterImpl(
    private val globalDispatcher: GlobalDispatcher,
    private val userNotifier: UserNotifier,
    private val handler: Handler,
    private val userApi: UserApi,
    private val subscriptionApi: SubscriptionApi
): SettingsContract.Presenter {

    private var view: SettingsContract.View? = null

    override fun attachView(view: SettingsContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
    }

    override fun detachView(view: SettingsContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun registerToUser() {
        userNotifier.register(userListener())
    }

    override fun unregisterFromUser() {
        userNotifier.unregister(userListener())
    }

    override fun requestUserData() {
        view?.showLoadingIndicator(true)

        globalDispatcher.prepare()
            .onSuccess {
                Logger.d("Success !")
                handler.post { view?.showLoadingIndicator(false) }
            }.onError { _, _ ->
                Logger.e("Error !")
                handler.post { view?.showLoadingIndicator(false) }
            }.dispatch(GetUserAction())
    }

    override fun setSettingOptions(enableNewsletter: Boolean,
                                   helpUs: Boolean
    ) {
        userApi.setOptions(enableNewsletter, helpUs,
            object : ApiListener<Unit> {
                override fun onSuccess(apiResponse: ApiResponse<Unit>) {
                    handler.post { view?.showEditDone() }
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    handler.post {
                        ErrorManager.formatRequestError(status, error)?.let {
                            view?.showError(it)
                        }
                    }
                    return true
                }
            })
    }

    override fun deleteAccount() {
        view?.showDeleteAccount()
    }

    override fun requestSubscriptionDashboard() {
        subscriptionApi.getSubscriptionDashboard(object : ApiListener<String> {
            override fun onSuccess(apiResponse: ApiResponse<String>) {
                handler.post {
                    view?.showLoadingIndicator(false)
                    view?.showSubscriptionDashboard(apiResponse.body)
                }
            }

            override fun onError(status: Int, error: ErrorRequest): Boolean {
                handler.post {
                    view?.showLoadingIndicator(false)
                    ErrorManager.formatRequestError(status, error)?.let {
                        view?.showError(it)
                    }
                }
                return true
            }
        })
    }

    private fun userListener() : UserListener {
        return object : UserListener {
            override fun onDone(user: User) {
                handler.post {
                    view?.showUser(user)
                }
            }
        }
    }
}
