package com.wicupp.beaver.js.core.language

import com.wicupp.beaver.core.Language

class LanguageImpl: Language {

    val language = LanguageJSImpl()

    override fun getLanguage(): String {
        return language.getLanguage()
    }
}