package com.wicupp.timezone

class TimeZoneFinderImpl: TimeZoneFinder {

    val timeZoneFinder = TimeZoneFinderJSImpl()

    override fun fromLatLong(
        latitude: Double,
        longitude: Double,
        callback: (hadError: Boolean, timezone: String) -> Unit
    ) {
        return timeZoneFinder.fromLatLong(latitude, longitude, callback)
    }
}