package com.wicupp.beaver.js.storage

import com.wicupp.beaver.storage.KeyValueStorage

class KeyValueStorageImpl: KeyValueStorage {

    private val storageJSImpl = KeyValueStorageJSImpl()

    override fun init(tables: Array<String>, onSuccess: () -> Unit) {
        storageJSImpl.init(tables, onSuccess)
    }

    override fun removeAll(onSuccess: () -> Unit) {
        storageJSImpl.removeAll(onSuccess)
    }

    override fun removeAllItems(table: String, onCompleted: (error: Boolean) -> Unit) {
        storageJSImpl.removeAllItems(table, onCompleted)
    }

    override fun setItemToTable(table: String,
                                key: String,
                                value: String?,
                                expirationSeconds: Long?,
                                onCompleted: ((error: Boolean) -> Unit)?) {
        storageJSImpl.setItemToTable(table, key, value, expirationSeconds, onCompleted)
    }

    override fun getItemFromTable(table: String,
                                  key: String,
                                  onCompleted: (value: String?, error: Boolean) -> Unit) {
        storageJSImpl.getItemFromTable(table, key, onCompleted)
    }
}