package com.wicupp.safetymap.dashboard.sign

import com.wicupp.beaver.core.Local
import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.request.ApiRequest
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.beaver.request.OAuth
import com.wicupp.beaver.request.Parameter
import com.wicupp.beaver.utils.JsonUtils
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.api.ApiUrl
import com.wicupp.safetymap.dashboard.data.api.UserApi
import com.wicupp.safetymap.dashboard.error.AppError
import com.wicupp.safetymap.dashboard.error.AppErrorId
import com.wicupp.safetymap.dashboard.error.ErrorManager
import com.wicupp.safetymap.dashboard.utils.StringUtils
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject

/**
 * [SignContract.Presenter] implementation.
 */
class SignPresenterImpl(
    private val handler: Handler,
    private val oauth: OAuth,
    private val apiRequest: ApiRequest,
    private val userApi: UserApi
): SignContract.Presenter {

    private var view: SignContract.View? = null
    private var firstSignin: Boolean? = null
    private var email: String? = null

    override fun attachView(view: SignContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
    }

    override fun detachView(view: SignContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun requestVerificationCode(email: String) {
        requestVerificationCode(email, null)
    }

    private fun requestVerificationCode(
        email: String,
        acceptLegals: Boolean?
    ) {
        if (!StringUtils.isValidEmailAddress(email)) {
            view?.showError(AppError(AppErrorId.EMAIL_NOT_VALID))
            return
        }

        this.email = email

        view?.showLoadingIndicator(true)

        val parameterBuilder = Parameter.Builder()
        parameterBuilder.addParameter("email", email)
        parameterBuilder.addParameter("language", Local.getDefault().getLanguage())
        acceptLegals?.let {
            parameterBuilder.addParameter("legals_accepted", acceptLegals)
        }

        apiRequest.post(
            ApiUrl.REQUEST_VERIFICATION_CODE,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    val firstSignin = JsonUtils.getBooleanOrNull(body.jsonObject["first_signin"])

                    if (firstSignin == null) {
                        Logger.e("first_signin is missing.")
                    }

                    view?.showLoadingIndicator(false)
                    if (firstSignin == true) {
                        this@SignPresenterImpl.email = email
                        this@SignPresenterImpl.firstSignin = true
                        view?.showLegals()
                    } else {
                        view?.showVerificationCodeInputView()
                    }
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        ErrorManager.formatRequestError(status, error)?.let {
                            view?.showError(it)
                        }
                    }
                    return true
                }
            }, parameterBuilder.build(), null, unsecured = true
        )
    }

    override fun acceptLegals() {
        email?.also {
            requestVerificationCode(it, true)
        } ?: run {
            Logger.e("acceptLegals failed, email null.")
        }
    }

    override fun requestLogin(verificationCode: String) {
        email?.also { email ->
            view?.showLoadingIndicator(true)

            oauth.login(
                email,
                verificationCode,
                object : OAuth.Listener {
                    override fun onSuccess(body: String) {
                        handler.post {
                            view?.apply {
                                showLoadingIndicator(false)
                                if (this@SignPresenterImpl.firstSignin == true) {
                                    showTrackingFormView()
                                } else {
                                    showFinish()
                                }
                            }
                        }
                    }

                    override fun onError(status: Int, error: ErrorRequest): Boolean {
                        handler.post {
                            view?.apply {
                                showLoadingIndicator(false)
                                ErrorManager.formatRequestError(status, error)?.let {
                                    view?.showError(it)
                                }
                            }
                        }
                        return true
                    }
                }
            )
        } ?: run {
            Logger.e("no email provided before.")
        }
    }

    override fun updateMarketing(enableNewsletter: Boolean, helpUs: Boolean) {
        view?.showLoadingIndicator(true)
        userApi.setOptions(enableNewsletter, helpUs,
            object : ApiListener<Unit> {
                override fun onSuccess(apiResponse: ApiResponse<Unit>) {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        view?.showFinish()
                    }
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    handler.post {
                        ErrorManager.formatRequestError(status, error)?.let {
                            view?.showError(it)
                        }
                        view?.showLoadingIndicator(false)
                    }
                    return true
                }
            })
    }
}
