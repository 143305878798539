package com.wicupp.safetymap.dashboard.sign

import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.component.SwitchView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout

class SignMarketingView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "app-sign-marketing-view"
    }

    override fun layoutName() = "view-sign-marketing"
    var listener: Listener? = null
    private var buttonView: ButtonView? = null

    override fun onCreate() {
        super.onCreate()

        buttonView = findViewById<ButtonView>("view-signup-signup-button").apply {
            onClick {
                listener?.onUpdate(
                    findViewById<SwitchView>("view-signup-newsletter").value(),
                    findViewById<SwitchView>("view-signup-help-us").value()
                )
            }
        }

        findViewById<ButtonView>("view-sign-marketing-ignore-button").onClick {
            listener?.onIgnore()
        }
    }

    fun showLoadingIndicator(value: Boolean) {
        if (value) {
            buttonView?.startLoading()
        } else {
            buttonView?.stopLoading()
        }
    }

    interface Listener {
        fun onIgnore()
        fun onUpdate(
            acceptNewsletter: Boolean,
            acceptHelpUs: Boolean
        )
    }
}