package com.wicupp.safetymap.dashboard.board.refuge

import com.wicupp.beaver.core.task.Handler
import com.wicupp.safetymap.dashboard.data.flux.GlobalDispatcher
import com.wicupp.safetymap.dashboard.data.flux.action.DeleteRefugeAction
import com.wicupp.safetymap.dashboard.data.flux.action.GetRefugesAction
import com.wicupp.safetymap.dashboard.data.flux.action.UpdateProjectAction
import com.wicupp.safetymap.dashboard.data.flux.handler.UpdateProjectHandler
import com.wicupp.safetymap.dashboard.data.flux.notifier.RefugesNotifier
import com.wicupp.safetymap.dashboard.data.flux.notifier.UserNotifier
import com.wicupp.safetymap.dashboard.error.ErrorManager

/**
 * [RefugesContract.Presenter] implementation.
 */
class RefugesPresenterImpl(
    private val handler: Handler,
    private val globalDispatcher: GlobalDispatcher,
    private val refugesNotifier: RefugesNotifier,
    private val userNotifier: UserNotifier
) : RefugesContract.Presenter {

    private var view: RefugesContract.View? = null

    override fun attachView(view: RefugesContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
    }

    override fun detachView(view: RefugesContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun deleteRefuge(refugeId: String) {
        view?.showLoadingIndicator(true)

        globalDispatcher.prepare()
            .onSuccess {
                handler.post {
                    view?.showLoadingIndicator(false)
                    userNotifier.user?.projects?.firstOrNull()?.refugeNetworkName?.let { refugeNetworkName ->
                        refugesNotifier.get()?.let { refuges ->
                            view?.showRefuges(refugeNetworkName, refuges)
                        }
                    }
                }
            }
            .onError { status, error ->
                handler.post {
                    view?.showLoadingIndicator(false)
                    ErrorManager.formatRequestError(status, error)?.let {
                        view?.showError(it)
                    }
                }
            }
            .dispatch(DeleteRefugeAction(refugeId))
    }

    override fun requestSharingView() {
        // TODO
        userNotifier.user?.projects?.firstOrNull()?.id?.let { projectId ->
            view?.showSharingView(projectId)
        }
    }

    override fun requestCreateRefugeView() {
        userNotifier.user?.projects?.firstOrNull()?.id?.let { projectId ->
            view?.showCreateRefugeView(projectId)
        }
    }

    override fun requestRefuges() {
        view?.showLoadingIndicator(true)

        val projectId = userNotifier.user?.projects?.firstOrNull()?.id

        if (projectId == null) {
            view?.showLoadingIndicator(false)
            view?.showEmptyView(true)
            return
        }

        globalDispatcher.prepare()
            .onSuccess {
                updateRefuges()
            }
            .onError { status, error ->
                handler.post {
                    view?.showLoadingIndicator(false)
                    ErrorManager.formatRequestError(status, error)?.let {
                        view?.showError(it)
                    }
                }
            }
            .dispatch(GetRefugesAction(projectId))
    }

    private fun updateRefuges() {
        handler.post {
            view?.showLoadingIndicator(false)
            refugesNotifier.get()?.also { refuges ->
                if (refuges.isNotEmpty()) {
                    userNotifier.user?.projects?.firstOrNull()?.refugeNetworkName?.let { refugeNetworkName ->
                        view?.showRefuges(refugeNetworkName, refuges)
                        view?.showEmptyView(false)
                    }
                } else {
                    view?.showEmptyView(true)
                }
            }
        }
    }
}
