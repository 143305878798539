package com.wicupp.beaver.injection

class Inject {
    companion object {
        var objects: MutableList<Any> = mutableListOf()

        fun provide(t: (c: Companion) -> Any) {
            val o = t(this)
            if (isAlreadyDefined(o)) {
                throw Error("This object ${o::class.simpleName} is already defined.")
            }
            objects.add(o)
        }

        private fun isAlreadyDefined(newObject: Any): Boolean {
            for (o in objects) {
                if (o::class == newObject::class) return true
            }
            return false
        }

        inline fun <reified T> get(): T {
            for (o in objects) {
                if (o is T) return o
            }
            throw Error("This should not happend, this object ${T::class.simpleName} need to be defined.")
        }
    }
}