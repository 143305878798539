package com.wicupp.safetymap.dashboard.ui

import com.wicupp.beaver.web.view.component.*
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.smartview.ButtonOption
import com.wicupp.beaver.web.view.smartview.SmartView
import com.wicupp.beaver.web.view.smartview.SmartViewType

class EditableTextView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "editable-text-view"
    }

    override fun layoutName() = "view-editable-text"

    private var textView: TextView? = null
    private var editButton: SmartIcon? = null

    var placeholder: String = ""
    var value: String? = null
        set(value) {
            value?.let {
                textView?.setText(it)
            }
            field = value
        }
    var listener: Listener? = null

    override fun onCreate() {
        super.onCreate()

        textView = findViewById("view-editable-text-view")
        value?.let {
            textView?.setText(it)
        }
        editButton = findViewById("view-switch-item-edit-button")
        editButton?.onClick {
            showEditText()
        }
    }

    private fun showEditText() {
        val inputEditableTextView = createLayout(InputEditableTextView::class.js)
        inputEditableTextView.value = value
        inputEditableTextView.placeholder = placeholder
        val smartView = SmartView(
            this,
            "input-editable-text",
            inputEditableTextView
        )
        smartView
            .hideTopBar(true)
            .setType(SmartViewType.BOTTOM_SHEET)
            .setPositiveButton(ButtonOption.builder().run {
                text(getString("common-save"))
                build()
            }) {
                inputEditableTextView.textView?.getValue()?.let {
                    value = it
                }
                smartView.hide {
                    value?.let {
                        listener?.onChanged(it)
                    }
                }
                true
            }
            .create()
            .show()
    }

    interface Listener {
        fun onChanged(text: String)
    }
}