package com.wicupp.safetymap.dashboard.routing

import com.wicupp.beaver.core.routing.CustomRoute
import com.wicupp.beaver.core.routing.PendingRoute
import com.wicupp.beaver.storage.KeyValueStorage
import com.wicupp.beaver.core.utils.Utils
import com.wicupp.beaver.core.Local
import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.push.Push
import com.wicupp.beaver.request.*
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.api.ApiUrl
import com.wicupp.safetymap.dashboard.data.api.UserApi
import com.wicupp.safetymap.dashboard.data.flux.GlobalDispatcher
import com.wicupp.safetymap.dashboard.data.flux.action.GetUserAction
import com.wicupp.safetymap.dashboard.data.flux.notifier.UserNotifier
import com.wicupp.safetymap.dashboard.data.model.SubscriptionPlan
import com.wicupp.safetymap.dashboard.data.model.User
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorManager
import com.wicupp.safetymap.dashboard.storage.StorageKey.Companion.USER_STORAGE_KEY
import kotlinx.serialization.json.JsonElement

class RoutingManager(
    private val globalDispatcher: GlobalDispatcher,
    private val userNotifier: UserNotifier,
    private val push: Push,
    private val oauth: OAuth,
    private val apiRequest: ApiRequest,
    private val storage: KeyValueStorage,
    private val userApi: UserApi
) {

    companion object {
        private const val APP_INSTALLATION_ID_KEY = "appInstallationId"
        private const val APP_INSTALLATION_ID_LIMIT_SECONDS: Long = 3 * 365 * 24 * 60 * 60 // 3 years

        fun getAppInstallationId(storage: KeyValueStorage, onDone: (appInstallationId: String) -> Unit) {
            storage.getItemFromTable(USER_STORAGE_KEY, APP_INSTALLATION_ID_KEY) { appInstallationId, _ ->
                appInstallationId?.let {
                    onDone(appInstallationId)
                } ?: run {
                    val newAppInstallationId = Utils.randomUUID()
                    storage.setItemToTable(USER_STORAGE_KEY, APP_INSTALLATION_ID_KEY, newAppInstallationId,
                        APP_INSTALLATION_ID_LIMIT_SECONDS
                    )
                    onDone(newAppInstallationId)
                }
            }
        }
    }

    fun requestRouting(listener: Listener) {
        oauth.isLogged { isLogged ->
            Logger.d("isLogged: $isLogged")
            if (isLogged) {
                // push registration not implemented for web
                push.register { token, onTokenConsumed ->
                    Logger.d("Push token: $token")
                    token?.let { registerPushToken(it, onTokenConsumed) }
                }
                requestUserModel(listener) { user ->
                    getCustomRoute()?.let {
                        listener.onCustomRoute(it, true)
                    } ?: run {
                        if (user.subscription == SubscriptionPlan.NONE) {
                            listener.onRouteToPricing()
                        } else {
                            listener.onDashboard()
                        }
                    }
                }
            } else {
                getCustomRoute(needSignIn = true)?.also {
                    listener.onCustomRoute(it, false)
                } ?: run {
                    listener.onSignin()
                }
            }
        }

        /**
         * TODO
         * getUserInfo, checkFcmRegistration, checkPendingUserConsent, checkUserLang
         */
    }

    fun requestUserModel(listener: Listener, onDone: (User) -> Unit) {
        Logger.d("Request user model")
        globalDispatcher.prepare()
            .onSuccess {
                val user = userNotifier.get()!!
                if (user.language != Local.getDefault().getLanguage()) {
                    updateLanguage(Local.getDefault().getLanguage()) {
                        onDone(user)
                    }
                } else {
                    onDone(user)
                }
            }.onError { status, error ->
                ErrorManager.formatRequestError(status, error)?.let {
                    listener.onError(it)
                }
            }.dispatch(GetUserAction())
    }

    private fun getCustomRoute(needSignIn: Boolean = false): CustomRoute? {
        PendingRoute.getRoute()?.let {
            if (it.needAuthentication && needSignIn) {
                return null
            }
            return it
        }
        return null
    }

    private fun registerPushToken(token: String, onTokenConsumed: () -> Unit) {
        getAppInstallationId(storage) { appInstallationId ->
            val parameterBuilder = Parameter.Builder()
            parameterBuilder.addParameter("app_installation_id", appInstallationId)
            parameterBuilder.addParameter("push_token", token)

            apiRequest.post(
                ApiUrl.REGISTER_PUSH,
                object : ApiRequest.Listener {
                    override fun onSuccess(body: JsonElement) {
                        onTokenConsumed()
                    }

                    override fun onError(status: Int, error: ErrorRequest): Boolean {
                        Logger.e("error registering push: $error")
                        return true
                    }
                }, parameterBuilder.build(), null)
        }
    }

    private fun updateLanguage(language: String, onDone: () -> Unit) {
        userApi.setLanguage(language,
            object : ApiListener<Unit> {
                override fun onSuccess(apiResponse: ApiResponse<Unit>) {
                    onDone()
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    Logger.e("error registering language: $language")
                    onDone()
                    return true
                }
            }
        )
    }

    interface Listener {
        fun onSignin()
        fun onCustomRoute(customRoute: CustomRoute, isAuthenticated: Boolean)
        fun onDashboard()
        fun onRouteToPricing()
        fun onError(error: Error)
    }
}