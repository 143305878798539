package com.wicupp.safetymap.dashboard.menu

import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.request.OAuth
import com.wicupp.safetymap.dashboard.data.flux.notifier.UserListener
import com.wicupp.safetymap.dashboard.data.flux.notifier.UserNotifier
import com.wicupp.safetymap.dashboard.data.model.User

/**
 * [MenuContract.Presenter] implementation.
 */
class MenuPresenterImpl(
    private val oAuth: OAuth,
    private val userNotifier: UserNotifier,
    private val handler: Handler
): MenuContract.Presenter {

    private var view: MenuContract.View? = null
    private var userListener = userListener()

    override fun attachView(view: MenuContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
    }

    override fun detachView(view: MenuContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun registerToUser() {
        userNotifier.register(userListener)
    }

    override fun unregisterFromUser() {
        userNotifier.unregister(userListener)
    }

    private fun userListener(): UserListener {
        return object : UserListener {
            override fun onDone(user: User) {
                oAuth.isLogged { isLogged ->
                    handler.post { view?.showLogout(isLogged) }
                }
            }
        }
    }
}
