package com.wicupp.safetymap.dashboard.data.flux.handler

import com.wicupp.beaver.flux.*
import com.wicupp.safetymap.dashboard.data.flux.action.ClearUserAction
import com.wicupp.safetymap.dashboard.data.flux.action.GetUserAction
import com.wicupp.safetymap.dashboard.data.model.Model
import com.wicupp.safetymap.dashboard.data.model.User

class ClearUserHandler(
    dispatcher: Dispatcher,
    rootNotifier: RootNotifier
)
    : Handler<ClearUserAction, Model>
    (dispatcher, rootNotifier) {

    override fun execute(model: Model, action: ClearUserAction): ClearUserHandler {
        success(
            GetUserAction::class, model.toBuilder()
            .user(User.Builder().build())
            .build())
        return this
    }
}