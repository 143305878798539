package com.wicupp.safetymap.dashboard.error

import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.smartview.AlertDialog
import com.wicupp.safetymap.dashboard.error.AppErrorId
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.NetworkError

class ErrorViewFactory {
    companion object {
        fun showError(layout: Layout,
                      error: String) {
            buildDialog(
                layout,
                error
            )
        }

        fun showError(layout: Layout,
                      error: Error
        ) {
            buildDialog(
                layout,
                getString(layout, error)
            )
        }

        private fun buildDialog(layout: Layout, text: String) {
            AlertDialog.Builder(layout)
                .setTitle(layout.getString("common-error"))
                .setMessage(text)
                .setPositiveButton(layout.getString("common-ok")) { dialog ->
                    dialog.hide()
                }
                .create()
                .show()
        }

        fun getString(l: Layout, error: Error): String {
            return when (error.errorId) {
                AppErrorId.NETWORK_LIMIT_REACHED -> l.getString("reached-limit")
                AppErrorId.EMAIL_NOT_CONFIRMED -> l.getString("unvalidated-email-error")
                AppErrorId.EMAIL_NOT_VALID -> l.getString("email-not-valid")
                AppErrorId.SIGN_IN_ERROR -> l.getString("error-code-not-correct")
                AppErrorId.RATE_TOO_LARGE_AREA -> l.getString("too-large-area-rate")
                AppErrorId.CONNECTION_ISSUE,
                AppErrorId.SERVER_ERROR -> throw IllegalArgumentException("${error.errorId} not implemented.")
                AppErrorId.BAD_AUTHENT_ERROR -> l.getString("error-code-not-correct")
                AppErrorId.UNKNOWN_ERROR -> l.getString("unknown-error")
                AppErrorId.UNKNOWN_NETWORK_ERROR -> l.getString("network-error")
                AppErrorId.UNKNOWN_NETWORK_ERROR_WITH_CODE -> {
                    (error as NetworkError).let {
                        l.getString("unknown-error-with-code",
                            error.errorRequest?.message,
                            error.errorRequest?.code?.toString())
                    }
                }
                AppErrorId.REGION_NOT_AVAILABLE -> l.getString("region-not-available-error")
            }
        }
    }
}