package com.wicupp.beaver.js.core

import com.wicupp.beaver.core.*
import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.core.storage.StoredPreferencesManager
import com.wicupp.beaver.web.WebApplication
import com.wicupp.beaver.js.core.storage.StoredPreferencesManagerImpl
import com.wicupp.beaver.js.core.string.FormatStringImpl
import com.wicupp.beaver.js.core.string.ResourcesStringImpl
import com.wicupp.beaver.web.logger.BeaverLog

abstract class Application: WebApplication() {
    abstract val appConfig: AppConfig
    private val appDependencies = AppDependencies()
    open var storedPreferencesManager : StoredPreferencesManager = StoredPreferencesManagerImpl()
    open lateinit var context: Context

    init {
        appDependencies.provide()
    }

    override fun onConfigure() {
        Platform.define(Platform.Type.WEB)
        defineOperatingSystem()
        context = Context(FormatStringImpl(), ResourcesStringImpl(), storedPreferencesManager)
    }

    override fun onDefineStorage(storageInitialized: () -> Unit) {
        storedPreferencesManager.init {
            storageInitialized()
        }
    }

    override fun onBeaverLog(beaverLog: BeaverLog) {
        when (beaverLog.priority) {
            BeaverLog.Priority.VERBOSE -> Logger.v(beaverLog.message)
            BeaverLog.Priority.DEBUG -> Logger.d(beaverLog.message)
            BeaverLog.Priority.INFO -> Logger.i(beaverLog.message)
            BeaverLog.Priority.WARNING -> Logger.w(beaverLog.message)
            BeaverLog.Priority.ERROR -> Logger.e(beaverLog.message)
        }
    }

    private fun defineOperatingSystem() {
        if (com.wicupp.beaver.web.OperatingSystem.android()) {
            OperatingSystem.define(OperatingSystem.Type.ANDROID)
        } else if (com.wicupp.beaver.web.OperatingSystem.ios()) {
            OperatingSystem.define(OperatingSystem.Type.IOS)
        } else {
            OperatingSystem.define(OperatingSystem.Type.UNKNOWN)
        }
    }
}