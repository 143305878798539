package com.wicupp.safetymap.dashboard.ui

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.web.view.component.SwitchView
import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout

class SwitchItemView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "switch-item-view"
    }

    override fun layoutName() = "view-switch-item"

    private lateinit var textView: TextView
    private lateinit var switchView: SwitchView

    var text: String? = null
    var selected: Boolean = false
    var listener: Listener? = null
    var enable: Boolean = true

    override fun onCreate() {
        super.onCreate()

        Logger.d("text: $text")
        textView = findViewById("view-switch-item-text")

        val textInput = getCustomData("data-text")
        textInput?.let {
            var text = it
            if (it.startsWith("@string/")) {
               text = getString(it.replace("@string/", ""))
            }
            textView.setText(text)
        }
        text?.let {
            textView.setText(it)
        }
        switchView = findViewById("view-switch-item-value")
        switchView.enable(selected)

        switchView.onChanged {
            if (enable) {
                selected = it
                listener?.onChanged(it)
            } else if (it) {
                switchView.enable(false)
                listener?.onClickWhenDisabled()
            }
        }
    }

    interface Listener {
        fun onChanged(selected: Boolean)
        fun onClickWhenDisabled()
    }
}