package com.wicupp.beaver.web.view.smartview

import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.layout.Layout

class AlertDialog private constructor(val smartView: SmartView) {

    fun show() {
        smartView.show()
    }

    fun hide(onHidden: (() -> Unit)? = null) {
        smartView.hide {
            onHidden?.invoke()
        }
    }

    fun onHidden(onHidden: (() -> Unit)) {
        smartView.onHidden {
            onHidden()
        }
    }

    data class Builder(private var context: Layout) {

        private lateinit var title: String
        private var view: View? = null
        private var message: String? = null
        private var cancelable: Boolean = true
        private var positiveText: String? = null
        private var positiveClick: ((dialog: AlertDialog) -> Unit)? = null
        private var negativeText: String? = null
        private var negativeClick: ((dialog: AlertDialog) -> Unit)? = null
        private var dialog: AlertDialog? = null

        fun setTitle(title: String) = apply { this.title = title }

        fun setMessage(message: String) = apply { this.message = message }

        fun setPositiveButton(text: String, onClick: (dialog: AlertDialog) -> Unit) = apply {
            positiveText = text
            positiveClick = onClick
        }

        fun setNegativeButton(text: String, onClick: (dialog: AlertDialog) -> Unit) = apply {
            negativeText = text
            negativeClick = onClick
        }

        fun setCancelable(cancelable: Boolean) = apply {
            this.cancelable = cancelable
        }

        fun setView(view: View) = apply { this.view = view }

        fun create(): AlertDialog {
            val dialogView = view ?: let {
                Layout.createLayout(DefaultDialogView::class.js).apply {
                    text = message
                }
            }

            val builder = SmartView(context, title, dialogView)
            builder.setType(SmartViewType.DIALOG)

            if (!cancelable) {
                builder.disableCancelOnBackground()
            }

            positiveText?.also { text ->
                builder.setPositiveButton(
                    ButtonOption.builder()
                        .text(text)
                        .build()){
                    dialog?.also {
                        positiveClick?.invoke(it)
                    }
                    return@setPositiveButton true
                }
            }

            negativeText?.also { text ->
                builder.setNegativeButton(
                    ButtonOption.builder()
                        .text(text)
                        .build()){
                    dialog?.also {
                        negativeClick?.invoke(it)
                    }
                    return@setNegativeButton true
                }
            }

            if (view == null) {
                builder.addPadding()
            }

            return AlertDialog(builder
                .hideTopBar(true)
                .useLightButton()
                .wrapContent()
                .create()).apply {
                dialog = this
            }
        }
    }
}