package com.wicupp.beaver.js.core.utils

import com.wicupp.beaver.core.utils.UtilsInterface

class UtilsImpl: UtilsInterface {

    override fun generateHash(value: String): String {
        return UtilsJSImpl.generateHash(value)
    }

    override fun encodeURI(decodedURI: String): String {
        return encodeURIComponent(decodedURI)
    }

    override fun getRelativeTimeSpanString(time: Long, now: Long): String {
        return UtilsJSImpl.getRelativeTimeSpanString(time, now)
    }
}