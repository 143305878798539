package com.wicupp.safetymap.dashboard.data.flux.handler

import com.wicupp.beaver.flux.*
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.safetymap.dashboard.data.api.AdminApi
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.flux.action.DeleteRefugeAction
import com.wicupp.safetymap.dashboard.data.flux.action.GetRefugesAction
import com.wicupp.safetymap.dashboard.data.flux.notifier.RefugesNotifier
import com.wicupp.safetymap.dashboard.data.model.Model

class DeleteRefugeHandler(
    dispatcher: Dispatcher,
    rootNotifier: RootNotifier,
    private val adminApi: AdminApi,
    private val refugesNotifier: RefugesNotifier
) : Handler<DeleteRefugeAction, Model>(dispatcher, rootNotifier) {

    override fun execute(model: Model, action: DeleteRefugeAction): DeleteRefugeHandler {

        val refuges = refugesNotifier.get()?.toMutableList()?.apply {
            removeAll { it.id == action.refugeId }
        } ?: listOf()

        adminApi.deleteRefuge(
            action.refugeId,
            object : ApiListener<Unit> {
                override fun onSuccess(apiResponse: ApiResponse<Unit>) {
                    success(GetRefugesAction::class, model.toBuilder()
                        .refuges(refuges)
                        .build())
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    // Do nothing, executed on background
                    error(status, error)
                    return true
                }
            }
        )

        return this
    }
}