package com.wicupp.beaver.core

import com.wicupp.beaver.core.task.Task
import com.wicupp.beaver.core.task.TaskInterface
import com.wicupp.beaver.core.utils.Utils
import com.wicupp.beaver.core.utils.UtilsInterface

abstract class CoreDependencies {
    abstract var language: Language
    abstract var utils: UtilsInterface
    abstract var task: TaskInterface
    abstract var buildConfig: BuildConfigInterface

    fun provide() {
        BuildConfig.provideImpl(buildConfig)
        Utils.provideUtilsImpl(utils)
        Local.provideLanguage(language)
        Task.provideTaskImpl(task)
    }
}