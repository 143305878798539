package com.wicupp.safetymap.dashboard.board.refuge.edit.opening

import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.layout.Adapter
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.safetymap.dashboard.utils.Day
import com.wicupp.safetymap.dashboard.utils.OpeningHoursPeriod

class OpenHoursAdapter : Adapter() {

    var listener: Listener? = null

    fun setOpeningHours(
        openingHours: List<OpeningHoursPeriod>
    ) {
        removeAllViews()
        for (openingHour: OpeningHoursPeriod in openingHours) {
            addView(buildView(openingHour))
        }
    }

    private fun buildView(
        openingHours: OpeningHoursPeriod
    ): View {
        val item = Layout.createLayout(OpeningHoursItemView::class.js)
        item.openingHours = openingHours
        item.listener = object : OpeningHoursItemView.Listener {
            override fun onDeleteRequested() {
                listener?.onDeleteRequested(openingHours)
            }
        }

        return item
    }

    interface Listener {
        fun onDeleteRequested(openingHoursPeriod: OpeningHoursPeriod)
    }
}

val String.format24hTime: String
    get() {
        return this.substring(0, 2) + ":" + this.substring(2, 4)
    }