package com.wicupp.beaver.js.request.http

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.request.http.Blob
import com.wicupp.beaver.request.http.Http
import com.wicupp.beaver.request.http.Request
import com.wicupp.beaver.web.utils.UtilsJS
import org.w3c.xhr.FormData
import org.w3c.xhr.XMLHttpRequest

class HttpImpl: Http {

    companion object {
        const val REQUEST_TIMEOUT_MS = 30_000
    }

    private var requestTimeoutMs = REQUEST_TIMEOUT_MS

    override fun setRequestTimeout(timeoutMs: Int) {
        requestTimeoutMs = timeoutMs
    }

    override fun newAsyncCall(request: Request,
                              onResponse: (response: Http.Response) -> Unit) {
        val xmlHttpRequest = XMLHttpRequest()
        xmlHttpRequest.open(request.method, request.url, true)

        for ((key, value) in request.headers) {
            xmlHttpRequest.setRequestHeader(key, value)
        }

        xmlHttpRequest.timeout = requestTimeoutMs
        xmlHttpRequest.onreadystatechange = {
            if (xmlHttpRequest.readyState == XMLHttpRequest.DONE) {
                onResponse(Http.Response(xmlHttpRequest.responseText, xmlHttpRequest.status.toInt()))
            }
        }

        if (request.method == "GET") {
            xmlHttpRequest.setRequestHeader("Content-type", "application/x-www-form-urlencoded")
        }

        if (request.requestBody?.contentType == "multiPart/form-data") {
            request.formData?.map?.also {
                val formData = FormData()
                for ((key, value) in it) {
                    if (value is String) {
                        formData.append(key, value)
                    } else if (value is Blob) {
                        formData.append(key,
                            UtilsJS.dataURItoBlob("data:${value.type};base64,${value.base64Data}"))
                    } else {
                        Logger.e("FormData value for $key must be string or blob.")
                    }
                }
                xmlHttpRequest.send(formData)
            } ?: Logger.e("FormData must be defined.")
        } else {
            request.requestBody?.contentType?.let {
                xmlHttpRequest.setRequestHeader("Content-type", it)
            }
            xmlHttpRequest.send(request.requestBody?.postBody)
        }
    }

    override fun newSyncCall(request: Request): Http.Response {
        TODO("Not yet implemented")
    }
}

