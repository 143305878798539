package com.wicupp.safetymap.dashboard.ui

import com.wicupp.beaver.web.view.component.*
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout

class InputEditableTextView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "input-editable-text-view"
    }

    override fun layoutName() = "view-input-editable-text"

    var textView: EditText? = null

    var placeholder: String = ""
        set(value) {
            textView?.setPlaceholder(value)
            field = value
        }
    var value: String? = null
        set(value) {
            value?.let {
                textView?.setValue(it)
            }
            field = value
        }

    override fun onCreate() {
        super.onCreate()

        textView = findViewById("view-input-editable-text-edit")
        value?.let {
            textView?.setValue(it)
        }
        textView?.setPlaceholder(placeholder)
    }
}