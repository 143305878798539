package com.wicupp.safetymap.dashboard.data.model

import com.wicupp.safetymap.dashboard.utils.OpeningHoursPeriod
import kotlinx.serialization.Serializable

@Serializable
class PlaceInformation(
    val name: String,
    val refugeCategories: List<Refuge.Category>,
    val openingHoursPeriods: List<OpeningHoursPeriod>
)