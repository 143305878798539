package com.wicupp.beaver.request.http

class Request private constructor(val url: String,
                                  val headers: MutableMap<String, String>,
                                  val requestBody: RequestBody?,
                                  val method: String,
                                  val formData: FormData?) {

    data class Builder(var url: String) {
        private val headers = mutableMapOf<String, String>()
        private var requestBody: RequestBody? = null
        private var method: String = "GET"
        private var formData: FormData? = null

        fun url(url: String) = apply { this.url = url }

        fun addHeader(key: String, value: String) = apply { this.headers[key] = value }

        fun post(requestBody: RequestBody) = apply {
            this.requestBody = requestBody
            this.method = "POST"
        }

        fun put(requestBody: RequestBody) = apply {
            this.requestBody = requestBody
            this.method = "PUT"
        }

        fun formData(formData: FormData) = apply {
            this.formData = formData
        }

        fun get() = apply { this.method = "GET" }

        fun delete(requestBody: RequestBody) = apply {
            this.requestBody = requestBody
            this.method = "DELETE"
        }

        fun build(): Request {
            return Request(url, headers, requestBody, method, formData)
        }
    }
}