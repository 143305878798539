package com.wicupp.beaver.js.core.task

import com.wicupp.beaver.core.task.Executor
import com.wicupp.beaver.core.task.Runnable
import com.wicupp.beaver.core.task.TaskInterface

class TaskImpl: TaskInterface {
    override fun async(runnable: () -> Unit) {
        ExecutorImpl().execute(object : Runnable {
            override fun run() {
                runnable()
            }
        })
    }

    override fun delay(runnable: () -> Unit, delay: Int) {
        BackgroundTaskJS.requestIdleCallback({
            runnable()
        }, delay)
    }

    private class ExecutorImpl: Executor {
        override fun execute(command: Runnable?) {
            BackgroundTaskJS.requestIdleCallback({
                command?.run()
            }, null)
        }
    }
}