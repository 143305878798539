package com.wicupp.safetymap.dashboard.menu

import com.wicupp.beaver.web.view.component.SmartMenu
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout

class MobileMenuView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "app-menu-mobile-view"
    }

    override fun layoutName() = "view-mobile-menu"
    var listener: Listener? = null
    private var smartMenu: SmartMenu? = null
    private var menuView: MenuView? = null

    override fun onCreate() {
        super.onCreate()

        smartMenu = findViewById("view-menu-smart")
        menuView = findViewById("view-mobile-menu-view")
        menuView?.listener = object : MenuView.Listener {
            override fun onSettingsRequested() {
                listener?.onSettingsRequested()
            }

            override fun onLogoutRequested() {
                listener?.onLogoutRequested()
            }

            override fun onLoginRequested() {
                listener?.onLoginRequested()
            }

            override fun onLegalsRequested() {
                listener?.onLegalsRequested()
            }

            override fun onHelpCenterRequested() {
                listener?.onHelpCenterRequested()
            }

            override fun onRefugesRequested() {
                listener?.onRefugesRequested()
            }

            override fun onReportIncidentsRequested() {
                listener?.onReportIncidentsRequested()
            }

            override fun onApiRequested() {
                listener?.onApiRequested()
            }

            override fun onProjectSettingsRequested() {
                listener?.onProjectSettingsRequested()
            }
        }
    }

    fun show() {
        smartMenu?.show()
    }

    fun hide() {
        smartMenu?.hide()
    }

    interface Listener {
        fun onSettingsRequested()
        fun onLogoutRequested()
        fun onLoginRequested()
        fun onLegalsRequested()
        fun onHelpCenterRequested()
        fun onRefugesRequested()
        fun onReportIncidentsRequested()
        fun onApiRequested()
        fun onProjectSettingsRequested()
    }
}
