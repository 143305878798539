package com.wicupp.safetymap.dashboard.utils

class StringUtils {
    companion object {
        private val EMAIL_REGEX = "^(([\\w-]+\\.)+[\\w-]+|([a-zA-Z]|[\\w-]{2,}))@((([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])\\.([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])\\.([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])\\.([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9]))|([a-zA-Z]+[\\w-]+\\.)+[a-zA-Z]{2,4})$".toRegex()

        fun isValidEmailAddress(email: String): Boolean {
            return email.matches(EMAIL_REGEX) || email.contains("""\+(.*)?"*(@)""".toRegex())
        }
    }
}