package com.wicupp.beaver.js.core.string

import com.wicupp.beaver.core.string.FormatString

class FormatStringImpl: FormatString {

    val formatStringJSImpl = FormatStringJSImpl()

    override fun format(value: String, args: Array<out String?>?): String {
        return formatStringJSImpl.format(value, args)
    }
}