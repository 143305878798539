package com.wicupp.safetymap.dashboard.data.flux.notifier

import com.wicupp.beaver.flux.CoreModel
import com.wicupp.beaver.flux.Notifier
import com.wicupp.safetymap.dashboard.data.model.Model
import com.wicupp.safetymap.dashboard.data.model.User
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class UserNotifier: Notifier() {
    var listeners: MutableList<UserListener> = mutableListOf()
    var user: User? = null

    override fun updateModel(oldModel: CoreModel, newModel: CoreModel) {
        val newUserModel = getUsersModelChange(oldModel as Model, newModel as Model)
        newUserModel?.let {
            user = it
            notify(it)
        }
    }

    override fun clean() {
        user = null
    }

    override fun get(): User? {
        return user
    }

    fun register(listener: UserListener) {
        listeners.add(listener)
        user?.let {
            listener.onDone(it)
        }
    }

    fun unregister(listener: UserListener) {
        listeners.remove(listener)
    }

    private fun getUsersModelChange(oldModel: Model, newModel: Model): User? {

        if (Json.encodeToString(user) != Json.encodeToString(newModel.user) ) {
            notify(newModel.user)
            return newModel.user
        }
        return null
    }

    private fun notify(user: User) {
        for (listener in listeners) {
            listener.onDone(user)
        }
    }
}