package com.wicupp.beaver.core.logger

import kotlin.native.concurrent.ThreadLocal

class Logger {

    @ThreadLocal
    companion object: LoggerInterface {

        var consumers = mutableListOf<Consumer>()

        override fun v(message: String) {
            consumers.forEach {
                it.log("v", message)
            }
        }

        override fun d(message: String) {
            consumers.forEach {
                it.log("d", message)
            }
        }

        override fun i(message: String) {
            consumers.forEach {
                it.log("i", message)
            }
        }

        override fun w(message: String) {
            consumers.forEach {
                it.log("w", message)
            }
        }

        override fun e(message: String) {
            consumers.forEach {
                it.log("e", message)
            }
        }

        fun addConsumer(consumer: Consumer) {
            consumers.add(consumer)
        }
    }

    interface Consumer {
        fun log(priority: String, message: String)
    }
}