package com.wicupp.beaver.request

import com.wicupp.beaver.utils.JsonUtils.Companion.getStringOrNull
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject

class Parameter private constructor(val parameters: Map<String, Any?>) {

    companion object {
        fun mapToJson(parameters: Map<String, Any?>): String {
            val uniqueParameters = mutableListOf<String>()
            for ((key, value) in parameters) {
                if (value is Parameter) {
                    uniqueParameters.add("\"$key\" : ${mapToJson(value.parameters)}")
                } else if (value is String) {
                    uniqueParameters.add("\"$key\" : \"$value\"")
                } else if (value is Array<*>) {
                    val stringArray = mutableListOf<String>().run {
                        value.forEach {
                            when (it) {
                                is String -> add("\"$it\"")
                                is Parameter -> add(mapToJson(it.parameters))
                                else -> add("$it")
                            }
                        }
                        toTypedArray()
                    }
                    uniqueParameters.add("\"$key\" : [${stringArray.joinToString(",")}]")
                } else {
                    uniqueParameters.add("\"$key\" : $value")
                }
            }
            return "{" + uniqueParameters.joinToString(",") + "}"
        }
    }

    fun toJson(): String {
        return mapToJson(parameters)
    }

    class Builder {
        private val parameters = mutableMapOf<String, Any?>()

        fun addParameter(key: String, value: Array<*>) {
            parameters[key] = value
        }

        fun addParameter(key: String, value: JsonArray?) {
            parameters[key] = getStringOrNull(value)
        }

        fun addParameter(key: String, value: JsonObject?) {
            parameters[key] = getStringOrNull(value)
        }

        fun addParameter(key: String, value: Any?) {
            parameters[key] = value
        }

        fun addParameter(key: String, value: Parameter) {
            parameters[key] = value
        }

        fun build(): Parameter {
            return Parameter(parameters)
        }
    }
}