package com.wicupp.safetymap.dashboard.board.api

import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.view.component.SpinnerBar
import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory

class ApiView(cv: ContextView): Layout(cv), ApiContract.View {

    companion object {
        const val TAG_NAME = "app-api"
    }

    override fun layoutName() = "view-api"

    private val presenter: ApiContract.Presenter = Inject.get()
    private var loadingView: SpinnerBar? = null

    override fun onCreate() {
        super.onCreate()

        loadingView = findViewById("view-refuges-loading")
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
        presenter.requestApiStatus()
    }

    override fun onDetached() {
        presenter.detachView(this)
        super.onDetached()
    }

    override fun showLoadingIndicator(show: Boolean) {
        loadingView?.setVisibility(if (show) View.VISIBLE else View.GONE)
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    override fun showApiStatus() {

    }
}