package com.wicupp.safetymap.dashboard.data.model

import kotlinx.serialization.Serializable

@Serializable
data class Project(
    val id: String,
    val name: String,
    val refugeNetworkName: String
) {
    data class Builder(
        var id: String? = null,
        var name: String? = null,
        var refugeNetworkName: String? = null
    ) {
        fun build() = Project(
            id!!, name!!, refugeNetworkName!!
        )
    }

    fun toBuilder(): Builder {
        return Builder(id, name, refugeNetworkName)
    }
}