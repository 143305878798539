package com.wicupp.safetymap.dashboard.sign

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.layout.PagerLayout
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory

class SignView(cv: ContextView): Layout(cv), SignContract.View {

    companion object {
        const val TAG_NAME = "app-sign-view"
    }

    override fun layoutName() = "view-sign"
    var listener: Listener? = null

    private val presenter: SignContract.Presenter = Inject.get()
    private lateinit var errorView: TextView
    private lateinit var pagerLayout: PagerLayout
    private lateinit var signEmailView: SignEmailView
    private lateinit var signLegalsView: SignLegalsView
    private lateinit var signVerifCodeView: SignVerifCodeView
    private lateinit var signMarketingView: SignMarketingView

    override fun onCreate() {
        super.onCreate()

        errorView = findViewById("view-sign-error-text")
        pagerLayout = findViewById("activity-signin-pager")

        signEmailView = findViewById("activity-sign-email-view")
        signEmailView.listener = object : SignEmailView.Listener {
            override fun onNext(email: String) {
                presenter.requestVerificationCode(email)
                signVerifCodeView.email = email
                //showLegals()
            }
        }

        signLegalsView = findViewById("activity-sign-legals-view")
        signLegalsView.listener = object : SignLegalsView.Listener {
            override fun onRefuse() {
                pagerLayout.selectPage(signEmailView)
            }

            override fun onAccept() {
                presenter.acceptLegals()
                //showVerificationCodeInputView()
            }
        }

        signVerifCodeView = findViewById("activity-sign-verif-code-view")
        signVerifCodeView.listener = object : SignVerifCodeView.Listener {
            override fun onCancel() {
                pagerLayout.selectPage(signEmailView)
            }

            override fun onCodeProvided(code: String) {
                presenter.requestLogin(code)
                //showTrackingFormView()
            }
        }

        signMarketingView = findViewById("activity-sign-marketing-view")
        signMarketingView.listener = object : SignMarketingView.Listener {
            override fun onIgnore() {
                showFinish()
            }

            override fun onUpdate(acceptNewsletter: Boolean, acceptHelpUs: Boolean) {
                presenter.updateMarketing(acceptNewsletter, acceptHelpUs)
            }
        }

        pagerLayout.selectPage(signEmailView)
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
    }

    override fun onDetached() {
        super.onDetached()
        presenter.detachView(this)
    }

    override fun showFinish() {
        listener?.onSignInDone()
    }

    override fun showLoadingIndicator(show: Boolean) {
        if (show) errorView.setVisibility(View.GONE)
        when (val view = pagerLayout.getSelectedPage()) {
            is SignEmailView -> {
                view.showLoadingIndicator(show)
            }
            is SignLegalsView -> {
                view.showLoadingIndicator(show)
            }
            is SignVerifCodeView -> {
                view.showLoadingIndicator(show)
            }
            is SignMarketingView -> {
                view.showLoadingIndicator(show)
            }
        }
    }

    override fun showLegals() {
        Logger.d("showLegals")
        pagerLayout.selectPage(signLegalsView)
    }

    override fun showVerificationCodeInputView() {
        Logger.d("showVerificationCodeInputView")
        pagerLayout.selectPage(signVerifCodeView)
    }

    override fun showTrackingFormView() {
        Logger.d("showTrackingFormView")
        pagerLayout.selectPage(signMarketingView)
    }

    override fun showError(error: Error) {
        Logger.d("showError: ${error.errorId}")
        errorView.setText(ErrorViewFactory.getString(this, error))
        errorView.setVisibility(View.VISIBLE)
    }

    interface Listener {
        fun onSignInDone()
        fun onSignUpDone()
    }
}