package com.wicupp.safetymap.dashboard.board.refuge

import com.wicupp.beaver.web.view.layout.Adapter
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.component.View
import com.wicupp.safetymap.dashboard.data.model.Refuge

class RefugeAdapter : Adapter() {

    var listener: Listener? = null

    fun setRefuges(refuges: List<Refuge>) {
        removeAllViews()

        for (address: Refuge in refuges) {
            addView(buildView(address))
        }
    }

    private fun buildView(refuge: Refuge): View {
        val item = Layout.createLayout(RefugeItemView::class.js)
        item.refuge = refuge
        item.listener = object : RefugeItemView.Listener {
            override fun onEditRequested() {
                listener?.onRefugeSelected(refuge)
            }

            override fun onDeleteRequested() {
                listener?.onDeleteRequested(refuge)
            }
        }
        return item
    }

    interface Listener {
        fun onRefugeSelected(refuge: Refuge)
        fun onDeleteRequested(refuge: Refuge)
    }
}