package com.wicupp.safetymap.dashboard.board.refuge.edit.opening

import com.wicupp.artifice.time.TimePickerView
import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.layout.LinearLayout
import com.wicupp.safetymap.dashboard.ui.DayPickerView
import com.wicupp.safetymap.dashboard.ui.SwitchItemView
import com.wicupp.safetymap.dashboard.utils.Day
import com.wicupp.safetymap.dashboard.utils.OpeningHoursPeriod
import com.wicupp.safetymap.dashboard.utils.OpeningHoursPeriodDetail

class AddOpeningHoursItemView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "add-open-hours-item-view"
    }

    override fun layoutName() = "view-add-open-hours-item"

    var listener: Listener? = null

    private lateinit var startTimePicker: TimePickerView
    private lateinit var endTimePicker: TimePickerView
    private lateinit var addButton: ButtonView
    private lateinit var timePickers: LinearLayout
    private lateinit var daysInfoView: TextView

    private var previousOpeningHours: OpeningHours = OpeningHours()
    private var openingHours: OpeningHours = OpeningHours()
    private var selectedDays: MutableList<Day> = mutableListOf()

    override fun onCreate() {
        super.onCreate()

        timePickers = findViewById("view-add-open-hours-item-time-pickers")

        daysInfoView = findViewById("view-add-open-hours-item-day-info")

        startTimePicker = findViewById("view-add-open-hours-item-start-date")
        startTimePicker.onTimeChanged { hour, minute ->
            openingHours.startTime = "${hour.convertTo2Digits()}${minute.convertTo2Digits()}"
            enableButtonIfNeeded()
        }

        endTimePicker = findViewById("view-add-open-hours-item-end-date")
        endTimePicker.onTimeChanged { hour, minute ->
            openingHours.endTime = "${hour.convertTo2Digits()}${minute.convertTo2Digits()}"
            enableButtonIfNeeded()
        }

        addButton = findViewById<ButtonView>("view-add-open-hours-item-add-button").apply {
            onClick {
                if (openingHours.isValid && selectedDays.isNotEmpty()) {
                    var openingHoursPeriods = buildList {
                        selectedDays.forEach {
                            add(OpeningHoursPeriod(
                                open = OpeningHoursPeriodDetail(
                                    day = it.openingDay,
                                    time = openingHours.startTime
                                ),
                                close = OpeningHoursPeriodDetail(
                                    day = openingHours.getEndDay(it).openingDay,
                                    time = openingHours.endTime
                                )
                            ))
                        }
                    }
                    listener?.onNewOpenHours(openingHoursPeriods)
                }
            }
            disable(true)
        }

        findViewById<SwitchItemView>("view-add-open-hours-item-whole-day").apply {
            listener = object : SwitchItemView.Listener {
                override fun onChanged(selected: Boolean) {
                    if (selected) {
                        timePickers.setVisibility(View.GONE)
                        previousOpeningHours = openingHours
                        openingHours.startTime = "0000"
                        openingHours.endTime = "2359"
                        openingHours.endTimeForTomorrow = false
                    } else {
                        timePickers.setVisibility(View.VISIBLE)
                        openingHours = previousOpeningHours
                    }
                    enableButtonIfNeeded()
                }

                override fun onClickWhenDisabled() {
                    // Do nothing
                }
            }
        }

        findViewById<SwitchItemView>("view-add-open-hours-item-finish-tomorrow").apply {
            listener = object : SwitchItemView.Listener {
                override fun onChanged(selected: Boolean) {
                    openingHours.endTimeForTomorrow = selected
                }

                override fun onClickWhenDisabled() {
                    // Do nothing
                }
            }
        }

        handleDayPickers()
    }

    fun Int.convertTo2Digits(): String{
        return if(this in 0..9) "0$this" else this.toString()
    }

    private fun enableButtonIfNeeded() {
        if (openingHours.isValid && selectedDays.isNotEmpty()) {
            addButton.disable(false)
        } else {
            addButton.disable(true)
        }
    }

    /**
     * Format is same than [OpeningHoursPeriod]
     */
    private data class OpeningHours(
        var startTime: String = "",
        var endTime: String = "",
        var endTimeForTomorrow: Boolean = false
    ) {
        val isValid: Boolean
            get() = startTime.isNotEmpty() && endTime.isNotEmpty()

        fun getEndDay(day: Day): Day {
            return if (endTimeForTomorrow) day.nextDay else day
        }
    }

    private fun handleDayPickers() {
        findViewById<DayPickerView>("view-add-open-hours-item-monday").handleDayPicker()
        findViewById<DayPickerView>("view-add-open-hours-item-tuesday").handleDayPicker()
        findViewById<DayPickerView>("view-add-open-hours-item-wednesday").handleDayPicker()
        findViewById<DayPickerView>("view-add-open-hours-item-thursday").handleDayPicker()
        findViewById<DayPickerView>("view-add-open-hours-item-friday").handleDayPicker()
        findViewById<DayPickerView>("view-add-open-hours-item-saturday").handleDayPicker()
        findViewById<DayPickerView>("view-add-open-hours-item-sunday").handleDayPicker()
    }

    private fun DayPickerView.handleDayPicker() {
        onDayClick = { selected ->
            if (selected) selectedDays.add(day!!) else selectedDays.removeAll { it.openingDay == day?.openingDay }
            enableButtonIfNeeded()

            val days = selectedDays.sortedBy { it.openingDay }
            daysInfoView.setText(StringBuilder(getString("project-occurs-every")).run {
                days.forEach {
                    append(getString(it.stringKey))
                    if (it != days.last()) append(", ")
                }
                toString()
            })
        }
    }

    interface Listener {
        fun onNewOpenHours(openingHoursPeriods: List<OpeningHoursPeriod>)
    }
}