package com.wicupp.beaver.js.core.string

import com.wicupp.beaver.core.string.ResourcesString

class ResourcesStringImpl: ResourcesString {

    private val resourcesStringJSImpl = ResourcesStringJSImpl()

    override fun get(key: String, args: Array<out String?>?): String {
        return resourcesStringJSImpl.get(key, args)
    }
}