package com.wicupp.safetymap.dashboard.data.flux

import com.wicupp.beaver.flux.CoreModel
import com.wicupp.beaver.flux.Notifier
import com.wicupp.beaver.flux.RootNotifier

class GlobalNotifier(initModel: CoreModel, private val notifiers: List<Notifier>)
    : RootNotifier(initModel) {

    override fun updateModel(oldModel: CoreModel, newModel: CoreModel) {
        super.updateModel(oldModel, newModel)
        notifiers.forEach {
            it.updateModel(oldModel, newModel)
        }
    }

    override fun clean() {
        super.clean()
        notifiers.forEach {
            it.clean()
        }
    }
}