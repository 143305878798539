package com.wicupp.beaver.core

import com.wicupp.beaver.core.storage.StoredPreferences
import com.wicupp.beaver.core.storage.StoredPreferencesManager
import com.wicupp.beaver.core.string.FormatString
import com.wicupp.beaver.core.string.ResourcesString

class Context(val formatString: FormatString,
              private val resourcesString: ResourcesString,
              private val storedPreferencesManager: StoredPreferencesManager) {
    fun getString(key: String, vararg args: String?): String {
        return resourcesString.get(key, args)
    }

    fun formatString(value: String, vararg args: String?): String {
        return formatString.format(value, arrayOf(*args))
    }

    fun getStoredPreferences(name: String): StoredPreferences {
        return storedPreferencesManager.getByName(name)
    }
}