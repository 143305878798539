package com.wicupp.beaver.web

import com.wicupp.beaver.web.language.StringLanguage
import com.wicupp.beaver.web.logger.BeaverLog
import com.wicupp.beaver.web.logger.LoggerJS
import com.wicupp.beaver.web.view.LayoutDefine
import com.wicupp.beaver.web.view.activity.Activity
import com.wicupp.beaver.web.view.activity.ActivityContext
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.smartview.DefaultDialogView

abstract class WebApplication {
    val activityContext = ActivityContext()
    abstract val launchActivity: JsClass<out Activity>
    open var webContext: WebContext = WebContextImpl()
    abstract val supportedLanguages: Array<String>

    fun run() {
        onConfigure()
        WebCore.initialize(webContext, StringLanguage(supportedLanguages)) {
            onDefineStorage {
                defineViews()
                onDefineLayout().apply {
                    define()
                }
                onPrepareInjection()
                onCreated()
                Activity.declareLauncher(activityContext, launchActivity)
            }
        }
    }
    abstract fun onConfigure()
    abstract fun onDefineStorage(storageInitialized: () -> Unit)
    abstract fun onDefineLayout(): Layout.LayoutDefinition
    abstract fun onPrepareInjection()
    abstract fun onCreated()
    abstract fun onBeaverLog(beaverLog: BeaverLog)

    private fun defineViews() {
        LayoutDefine.add(
            DefaultDialogView::class.js,
            DefaultDialogView.TAG_NAME,
            preload = true
        )
    }

    private fun linkLoggerJS() {
        LoggerJS.init(object : LoggerJS.Listener {
            override fun v(message: String) {
                onBeaverLog(BeaverLog(BeaverLog.Priority.VERBOSE, message))
            }

            override fun d(message: String) {
                onBeaverLog(BeaverLog(BeaverLog.Priority.DEBUG, message))
            }

            override fun i(message: String) {
                onBeaverLog(BeaverLog(BeaverLog.Priority.INFO, message))
            }

            override fun w(message: String) {
                onBeaverLog(BeaverLog(BeaverLog.Priority.WARNING, message))
            }

            override fun e(message: String) {
                onBeaverLog(BeaverLog(BeaverLog.Priority.ERROR, message))
            }
        })
    }
}