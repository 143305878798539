package com.wicupp.safetymap.dashboard.sign

import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.component.EditText
import com.wicupp.beaver.web.view.component.EditTextType
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout

class SignEmailView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "app-sign-email-view"
    }

    override fun layoutName() = "view-sign-email"
    var listener: Listener? = null
    private var buttonView: ButtonView? = null

    override fun onCreate() {
        super.onCreate()

        val emailEditText = findViewById<EditText>("view-sign-email-input")

        emailEditText.setType(EditTextType.TYPE_TEXT) // Do not use email, since it disable enterkeyhint...
        emailEditText.onEnterPressed {
            listener?.onNext(emailEditText.getValue())
        }

        buttonView = findViewById<ButtonView>("view-sign-email-button").apply {
            onClick {
                listener?.onNext(emailEditText.getValue())
            }
        }
    }

    fun showLoadingIndicator(value: Boolean) {
        if (value) {
            buttonView?.startLoading()
        } else {
            buttonView?.stopLoading()
        }
    }

    interface Listener {
        fun onNext(email: String)
    }
}