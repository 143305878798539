package com.wicupp.beaver.js.core.logger

import com.wicupp.beaver.core.logger.Logger

class DefaultLogConsumer : Logger.Consumer {

    private val defaultLogConsumerImpl = DefaultLogConsumerImpl()

    override fun log(priority: String, message: String) {
        defaultLogConsumerImpl.log(priority, message)
    }
}