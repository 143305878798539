package com.wicupp.safetymap.dashboard.board.refuge

import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout

class RefugesEmptyView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "app-refuges-empty"
    }

    override fun layoutName() = "view-refuges-empty"

    var listener: Listener? = null

    override fun onCreate() {
        super.onCreate()

        findViewById<ButtonView>("view-refuges-empty-add").onClick {
            listener?.onCreateRefugeRequested()
        }
    }

    interface Listener {
        fun onCreateRefugeRequested()
    }
}