package com.wicupp.beaver.js.core.task

import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.core.task.Looper
import com.wicupp.beaver.core.task.MessageDelayed
import kotlinx.browser.window

class HandlerImpl(private val looper: Looper? = null): Handler {
    override fun post(runnable: () -> Unit) {
        when (looper?.type) {
            Looper.Type.MAIN -> postMain(runnable)
            else -> postMain(runnable)
        }
    }

    override fun postDelayed(runnable: () -> Unit, milliseconds: Int): MessageDelayed {
        return when (looper?.type) {
            Looper.Type.MAIN -> postMainDelayed(runnable, milliseconds)
            else -> postMainDelayed(runnable, milliseconds)
        }
    }

    override fun removeCallbacks(messageDelayed: MessageDelayed) {
        window.clearTimeout(messageDelayed.messageId)
    }

    private fun postMain(runnable: () -> Unit) {
        BackgroundTaskJS.requestAnimationFrame {
            runnable()
        }
    }

    private fun postMainDelayed(runnable: () -> Unit, milliseconds: Int): MessageDelayed {
        val callback = {
            BackgroundTaskJS.requestAnimationFrame(runnable)
        }

        val messageId = window.setTimeout(callback, milliseconds)

        return MessageDelayed(messageId, callback, milliseconds, looper?.type)
    }
}