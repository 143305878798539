package com.wicupp.safetymap.dashboard.sign.dialog

import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout

class SignInDialog(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "app-signin-dialog-view"
    }

    override fun layoutName() = "view-signin-dialog"
    var listener: Listener? = null

    override fun onCreate() {
        super.onCreate()

        findViewById<TextView>("view-signin-dialog-no-button").onClick {
            listener?.onCancelRequested()
        }

        findViewById<TextView>("view-signin-dialog-yes-button").onClick {
            listener?.onSignInRequested()
        }
    }

    interface Listener {
        fun onSignInRequested()
        fun onCancelRequested()
    }
}