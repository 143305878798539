package com.wicupp.safetymap.dashboard

import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.safetymap.dashboard.legals.LegalView
import com.wicupp.safetymap.dashboard.loading.LoadingView
import com.wicupp.safetymap.dashboard.menu.MenuView
import com.wicupp.safetymap.dashboard.board.MainView
import com.wicupp.safetymap.dashboard.board.api.ApiView
import com.wicupp.safetymap.dashboard.board.project.ProjectSettingsView
import com.wicupp.safetymap.dashboard.board.refuge.*
import com.wicupp.safetymap.dashboard.board.refuge.edit.opening.AddOpeningHoursItemView
import com.wicupp.safetymap.dashboard.board.refuge.edit.EditRefugeView
import com.wicupp.safetymap.dashboard.board.refuge.edit.opening.OpeningHoursItemView
import com.wicupp.safetymap.dashboard.board.refuge.edit.categories.RefugeCategoriesView
import com.wicupp.safetymap.dashboard.board.refuge.edit.address.AddAddressView
import com.wicupp.safetymap.dashboard.board.refuge.edit.address.item.SearchAddressItemView
import com.wicupp.safetymap.dashboard.board.report.ReportIncidentView
import com.wicupp.safetymap.dashboard.menu.MobileMenuView
import com.wicupp.safetymap.dashboard.onboarding.OnboardingView
import com.wicupp.safetymap.dashboard.subscription.SubscriptionView
import com.wicupp.safetymap.dashboard.settings.SettingsView
import com.wicupp.safetymap.dashboard.settings.delete.DeleteAccountView
import com.wicupp.safetymap.dashboard.settings.email.EditEmailView
import com.wicupp.safetymap.dashboard.sign.*
import com.wicupp.safetymap.dashboard.sign.dialog.SignInDialog
import com.wicupp.safetymap.dashboard.subscription.cancel.SubscriptionCancelView
import com.wicupp.safetymap.dashboard.subscription.success.SubscriptionSuccessView
import com.wicupp.safetymap.dashboard.toolbar.ToolbarView
import com.wicupp.safetymap.dashboard.ui.*

class AppLayoutDefinition: Layout.LayoutDefinition() {
    override val layouts = listOf(
        Layout.Def(SignView::class.js, SignView.TAG_NAME),
        Layout.Def(MobileMenuView::class.js, MobileMenuView.TAG_NAME),
        Layout.Def(MenuView::class.js, MenuView.TAG_NAME),
        Layout.Def(ToolbarView::class.js, ToolbarView.TAG_NAME),
        Layout.Def(SettingsView::class.js, SettingsView.TAG_NAME),
        Layout.Def(EditEmailView::class.js, EditEmailView.TAG_NAME),
        Layout.Def(SignInDialog::class.js, SignInDialog.TAG_NAME),
        Layout.Def(MainView::class.js, MainView.TAG_NAME),
        Layout.Def(LoadingView::class.js, LoadingView.TAG_NAME),
        Layout.Def(LegalView::class.js, LegalView.TAG_NAME),
        Layout.Def(DeleteAccountView::class.js, DeleteAccountView.TAG_NAME),
        Layout.Def(SignEmailView::class.js, SignEmailView.TAG_NAME),
        Layout.Def(SignLegalsView::class.js, SignLegalsView.TAG_NAME),
        Layout.Def(SignVerifCodeView::class.js, SignVerifCodeView.TAG_NAME),
        Layout.Def(SignMarketingView::class.js, SignMarketingView.TAG_NAME),
        Layout.Def(RefugeItemView::class.js, RefugeItemView.TAG_NAME),
        Layout.Def(EditRefugeView::class.js, EditRefugeView.TAG_NAME),
        Layout.Def(RefugeCategoriesView::class.js, RefugeCategoriesView.TAG_NAME),
        Layout.Def(SwitchItemView::class.js, SwitchItemView.TAG_NAME),
        Layout.Def(AddOpeningHoursItemView::class.js, AddOpeningHoursItemView.TAG_NAME),
        Layout.Def(OpeningHoursItemView::class.js, OpeningHoursItemView.TAG_NAME),
        Layout.Def(AddAddressView::class.js, AddAddressView.TAG_NAME),
        Layout.Def(SearchAddressItemView::class.js, SearchAddressItemView.TAG_NAME),
        Layout.Def(RefugesView::class.js, RefugesView.TAG_NAME),
        Layout.Def(ApiView::class.js, ApiView.TAG_NAME),
        Layout.Def(EditableTextView::class.js, EditableTextView.TAG_NAME),
        Layout.Def(RefugeNetworkSharingView::class.js, RefugeNetworkSharingView.TAG_NAME),
        Layout.Def(SubscriptionView::class.js, SubscriptionView.TAG_NAME),
        Layout.Def(SubscriptionCancelView::class.js, SubscriptionCancelView.TAG_NAME),
        Layout.Def(SubscriptionSuccessView::class.js, SubscriptionSuccessView.TAG_NAME),
        Layout.Def(ReportIncidentView::class.js, ReportIncidentView.TAG_NAME),
        Layout.Def(RefugesEmptyView::class.js, RefugesEmptyView.TAG_NAME),
        Layout.Def(OnboardingView::class.js, OnboardingView.TAG_NAME),
        Layout.Def(ProjectSettingsView::class.js, ProjectSettingsView.TAG_NAME),
        Layout.Def(RefugeMenuView::class.js, RefugeMenuView.TAG_NAME),
        Layout.Def(CategoryIconView::class.js, CategoryIconView.TAG_NAME),
        Layout.Def(InputEditableTextView::class.js, InputEditableTextView.TAG_NAME),
        Layout.Def(DayPickerView::class.js, DayPickerView.TAG_NAME)
    )
}