package com.wicupp.safetymap.dashboard.ui

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.layout.LinearLayout
import com.wicupp.safetymap.dashboard.utils.Day

class DayPickerView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "app-day-picker-view"
    }

    override fun layoutName() = "view-day-picker"

    var onDayClick: ((selected: Boolean) -> Unit)? = null
    var day: Day? = null
        set(value) {
            field = value?.also {
                dayTextView?.setText(getString(it.shortStringKey))
            }
        }
    var selected: Boolean = false
        set(value) {
            field = value
            updateView()
        }
    var clickable: Boolean = true
        set(value) {
            field = value
            updateClickable()
        }
    private var dayTextView: TextView? = null
    private var iconButton: LinearLayout? = null

    override fun onCreate() {
        super.onCreate()

        dayTextView = findViewById("view-day-picker-button-text")

        val dayDataKey = getCustomData("data-day")
        dayDataKey?.let {
            day = Day.getByDataKey(it)
        }
        day?.let {
            dayTextView?.setText(getString(it.shortStringKey))
        }

        iconButton = findViewById<LinearLayout>("view-day-picker-button").apply {
            onClick {
                if (clickable) {
                    selected = !selected
                    updateView()
                    onDayClick?.invoke(selected)
                }
            }
        }
        updateView()
        updateClickable()
    }

    private fun updateView() {
        iconButton?.apply {
            if (selected) {
                setBackground("var(--color-accent)")
                addClass("selected")
            } else {
                setBackground("var(--color-button-icon-unselected)")
                removeClass("selected")
            }
        }
    }

    private fun updateClickable() {
        if (clickable) {
            iconButton?.removeClass("no-clickable")
        } else {
            iconButton?.addClass("no-clickable")
        }
    }
}