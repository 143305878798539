package com.wicupp.artifice.toast

@JsName("Toast")
open external class Toast {
    fun show(message: String, durationMillisecond: Long)
}

class ToastDuration {
    companion object {
        const val SHORT = 2000L
    }
}