package com.wicupp.beaver.core

class OperatingSystem {
    companion object {
        private var operatingSystemType: Type? = null

        val ios: Boolean
            get() = operatingSystemType == Type.IOS

        val android: Boolean
            get() = operatingSystemType == Type.ANDROID

        val unknown: Boolean
            get() = operatingSystemType == Type.UNKNOWN

        fun define(type: Type) {
            operatingSystemType = type
        }
    }

    enum class Type(value: String) {
        IOS("IOS"),
        ANDROID("ANDROID"),
        WEB("WEB"),
        UNKNOWN("CUSTOM")
    }
}