package com.wicupp.safetymap.dashboard.data.api

import com.wicupp.beaver.request.ApiRequest
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.beaver.request.Parameter
import com.wicupp.beaver.utils.JsonUtils
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject

class UserApi(private val apiRequest: ApiRequest) {

    fun setLanguage(language: String, listener: ApiListener<Unit>) {
        val parameterBuilder = Parameter.Builder()
        parameterBuilder.addParameter("language", language)

        apiRequest.post(
            ApiUrl.SET_LANGUAGE,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    listener.onSuccess(UnitResponse())
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }
            }, parameterBuilder.build(), null)
    }

    fun setOptions(acceptNewsletters: Boolean,
                   isTrackingEnabled: Boolean,
                   listener: ApiListener<Unit>
    ) {
        val parameterBuilder = Parameter.Builder()
        parameterBuilder.addParameter("authorise_newsletter", acceptNewsletters)
        parameterBuilder.addParameter("authorise_tracking", isTrackingEnabled)

        apiRequest.post(
            ApiUrl.SET_SETTING_OPTIONS,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    listener.onSuccess(UnitResponse())
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }
            }, parameterBuilder.build(), null)
    }

    fun editEmailRequest(email: String,
                         listener: ApiListener<String>
    ) {

        val parameterBuilder = Parameter.Builder()
        parameterBuilder.addParameter("email", email)

        apiRequest.post(
            ApiUrl.REQUEST_NEW_EMAIL,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    val token = body.jsonObject["token"]

                    listener.onSuccess(
                        ResetPasswordResponse(
                            JsonUtils.getString(
                                token
                            )
                        )
                    )
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }
            }, parameterBuilder.build(), null)
    }

    fun setEmail(email: String,
                 token: String,
                 code: Int,
                 listener: ApiListener<Unit>
    ) {

        val parameterBuilder = Parameter.Builder()
        parameterBuilder.addParameter("token", token)
        parameterBuilder.addParameter("code", code)
        parameterBuilder.addParameter("email", email)

        apiRequest.post(
            ApiUrl.SET_EMAIL,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    listener.onSuccess(UnitResponse())
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }
            }, parameterBuilder.build(), null)
    }

    fun deleteAccount(listener: ApiListener<String>) {
        apiRequest.post(
            ApiUrl.DELETE_ACCOUNT,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    val token = body.jsonObject["token"]
                    listener.onSuccess(
                        DeleteAccountResponse(
                            JsonUtils.getString(
                                token
                            )
                        )
                    )
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }
            }, Parameter.Builder().build(), null)
    }

    fun confirmAccountDeletion(
        token: String,
        code: Int,
        listener: ApiListener<Unit>
    ) {

        val parameterBuilder = Parameter.Builder()
        parameterBuilder.addParameter("token", token)
        parameterBuilder.addParameter("code", code)

        apiRequest.post(
            ApiUrl.CONFIRM_DELETE_ACCOUNT,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    listener.onSuccess(UnitResponse())
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }
            }, parameterBuilder.build(), null)
    }

    class ResetPasswordResponse(override var body: String) : ApiResponse<String>()
    class DeleteAccountResponse(override var body: String) : ApiResponse<String>()
}