package com.wicupp.beaver.core.utils

import com.benasher44.uuid.uuid4
import kotlin.native.concurrent.ThreadLocal

class Utils {

    @ThreadLocal
    companion object: UtilsInterface {

        private var utilsInstance: UtilsInterface? = null

        internal fun provideUtilsImpl(utils: UtilsInterface) {
            utilsInstance = utils
        }

        /**
         * Generate a random uuid string
         * This method uses com.benasher44.uuid
         * To get more feature please use -> com.benasher44.uuid.Uuid
         * @return the uuid string
         */
        fun randomUUID(): String {
            return uuid4().toString()
        }

        override fun generateHash(value: String): String {
            return getInstance().generateHash(value)
        }

        override fun encodeURI(decodedURI: String): String {
            return getInstance().encodeURI(decodedURI)
        }

        override fun getRelativeTimeSpanString(time: Long, now: Long): String {
            return getInstance().getRelativeTimeSpanString(time, now)
        }

        private fun getInstance(): UtilsInterface {
            return utilsInstance ?: throw IllegalStateException("Missing implementation for UtilsInterface")
        }
    }
}