package com.wicupp.beaver.flux

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.request.ErrorRequest
import kotlin.reflect.KClass

abstract class Handler<A: Action, M: CoreModel>(val dispatcher: Dispatcher,
                                                val rootNotifier: RootNotifier) {
    private var triggerSuccess: (() -> Unit)? = null
    private var triggerError: ((status: Int, error: ErrorRequest) -> Unit)? = null
    private var action: A? = null

    init {
        dispatcher.subscribeToStore {
            dispatcher.getStateFromStore()?.let {
                this.rootNotifier.updateModel(rootNotifier.oldModel, it)
            }
        }
    }

    fun action(action: A): Handler<A, M> {
        this.action = action
        return this
    }

    fun dispatch() {
        dispatcher.executeHandlerWhenAvailable {
            try {
                action?.also {
                    execute(getOldModel(), it)
                } ?: throw IllegalArgumentException("action must be defined")
            } catch (e: Exception) {
                Logger.e("error during execute: $e")
                triggerError?.invoke(9999, ErrorRequest(-1, "error during execute: $e"))
            }
        }
    }

    abstract fun execute(model: M, action: A): Handler<A, M>

    fun success(cls: KClass<out Action>, model: CoreModel) {
        dispatcher.dispatch(cls, model)
        try {
            triggerSuccess?.invoke()
        } catch (e: Exception) {
            Logger.e("error handling success: $e")
        }
        dispatcher.finishExecution()
    }

    fun error(status: Int, error: ErrorRequest) {
        try {
            triggerError?.invoke(status, error)
        } catch (e: Exception) {
            Logger.e("error handling error callback: $e")
        }
        dispatcher.finishExecution()
    }

    fun onSuccess(onSuccess: () -> Unit): Handler<A, M> {
        triggerSuccess = onSuccess
        return this
    }

    fun onError(onError: (status: Int, error: ErrorRequest) -> Unit): Handler<A, M> {
        triggerError = onError
        return this
    }

    private fun getOldModel(): M {
        return (dispatcher.getStateFromStore() as M?) ?: dispatcher.createModelInit() as M
    }
}