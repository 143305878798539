package com.wicupp.safetymap.dashboard.routing

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.core.routing.CustomRoute
import com.wicupp.beaver.core.routing.RouteConfig

class AppRouteConfig: RouteConfig {
    private val CHECKOUT_SUCCESS_REGEX = "^(checkout/success/.*?\$)".toRegex()
    private val CHECKOUT_CANCEL_REGEX = "^(checkout/cancel\$)".toRegex()
    private val SUBSCRIPTION_STARTER_REGEX = "^(subscription/starter\$)".toRegex()
    private val SUBSCRIPTION_PREMIUM_REGEX = "^(subscription/premium\$)".toRegex()

    override fun routeUrl(url: String): CustomRoute? {
        val pathname = url.replace("^.*//[^/]+/".toRegex(), "")
        Logger.d("Route url: $url & pathname: $pathname")

        if (CHECKOUT_SUCCESS_REGEX.matches(pathname)) {
            val path = pathname.split("/")
            return CheckoutSuccessRoute(path[2])
        } else if (CHECKOUT_CANCEL_REGEX.matches(pathname)) {
            return CheckoutCancelRoute()
        } else if (SUBSCRIPTION_STARTER_REGEX.matches(pathname)) {
            return SubscriptionStarterRoute()
        } else if (SUBSCRIPTION_PREMIUM_REGEX.matches(pathname)) {
            return SubscriptionPremiumRoute()
        }
        return null
    }

    private fun getParams(url: String): Map<String, String> {

        val params = mutableMapOf<String, String>()
        val paramArray = url.split("?")[1].split("&")

        for (value in paramArray) {
            val pair = value.split("=")
            params[pair[0]] = pair[1]
        }

        return params
    }
}
