package com.wicupp.beaver.web.view.smartview

import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout

class DefaultDialogView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "beaver-web-default-dialog-view"
    }

    override fun layoutName() = "beaver-web-default-dialog"
    var text: String? = null

    override fun onCreate() {
        super.onCreate()

        val textView = findViewById<TextView>("beaver-web-default-dialog-text")
        textView.setText(text)
    }
}