package com.wicupp.safetymap.dashboard.subscription

import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.WebContext
import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.safetymap.dashboard.data.model.SubscriptionPlan
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory

class SubscriptionView(cv: ContextView): Layout(cv), SubscriptionContract.View {

    companion object {
        const val TAG_NAME = "app-subscription-view"
    }

    override fun layoutName() = "view-subscription"

    var subscriptionPlan: SubscriptionPlan? = null
    var listener: Listener? = null

    private val presenter: SubscriptionContract.Presenter = Inject.get()
    private val webContext: WebContext = Inject.get()

    override fun onCreate() {
        super.onCreate()
        // Do nothing
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
        subscriptionPlan?.let {
            presenter.requestSubscription(it)
        }
    }

    override fun onDetached() {
        presenter.detachView(this)
        super.onDetached()
    }

    override fun showLoadingIndicator(show: Boolean) {
        // TODO
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    override fun showSignIn() {
        listener?.onSignInRequested()
    }

    override fun showSubscriptionView(url: String) {
        webContext.getWebView().open(
            url,
            target = "_self"
        )
    }

    interface Listener {
        fun onSignInRequested()
        fun onSubscriptionDone()
    }
}