package com.wicupp.beaver.web.view.smartview

import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.layout.Layout

class BottomSheet private constructor(val smartView: SmartView) {

    companion object {
        fun from(context: Layout, view: View): BottomSheet {
            val builder = SmartView(context, "", view)
                .setType(SmartViewType.BOTTOM_SHEET)
                .hideTopBar(true)
            return BottomSheet(builder.create())
        }
    }

    fun onHidden(onHidden : () -> Unit): BottomSheet {
        smartView.onHidden(onHidden)
        return this
    }

    fun show() {
        smartView.show()
    }

    fun hide(onHidden: (() -> Unit)? = null) {
        smartView.hide {
            onHidden?.invoke()
        }
    }
}