package com.wicupp.safetymap.dashboard.subscription.cancel

import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.request.OAuth
import com.wicupp.safetymap.dashboard.data.api.SubscriptionApi

/**
 * [SubscriptionCancelContract.Presenter] implementation.
 */
class SubscriptionCancelPresenterImpl(
    private val handler: Handler,
    private val oauth: OAuth,
    private val subscriptionApi: SubscriptionApi
): SubscriptionCancelContract.Presenter {

    private var view: SubscriptionCancelContract.View? = null

    override fun attachView(view: SubscriptionCancelContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
    }

    override fun detachView(view: SubscriptionCancelContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }
}
