package com.wicupp.beaver.js.core

import com.wicupp.beaver.core.BuildConfigInterface
import com.wicupp.beaver.core.CoreDependencies
import com.wicupp.beaver.core.Language
import com.wicupp.beaver.core.task.TaskInterface
import com.wicupp.beaver.core.utils.UtilsInterface
import com.wicupp.beaver.js.core.build_config.BuildConfigImpl
import com.wicupp.beaver.js.core.language.LanguageImpl
import com.wicupp.beaver.js.core.task.TaskImpl
import com.wicupp.beaver.js.core.utils.UtilsImpl

class AppDependencies(
    override var language: Language = LanguageImpl(),
    override var utils: UtilsInterface = UtilsImpl(),
    override var task: TaskInterface = TaskImpl(),
    override var buildConfig: BuildConfigInterface = BuildConfigImpl()
) : CoreDependencies()