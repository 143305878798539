package com.wicupp.beaver.core.routing

class PendingRoute {
    companion object {

        private var pendingRoute: CustomRoute? = null
        private var routeConfig: RouteConfig? = null
        private var url: String = ""

        fun provide(routeConfig: RouteConfig, routeUrl: RouteUrl) {
            this.routeConfig = routeConfig
            url = routeUrl.getUrl()
        }

        fun route(customRoute: CustomRoute) {
            pendingRoute = customRoute
        }

        fun getRoute(): CustomRoute? {
            val customRoute = pendingRoute ?: routeConfig?.routeUrl(url)
            pendingRoute = null
            url = ""
            return customRoute
        }
    }
}