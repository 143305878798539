package com.wicupp.safetymap.dashboard.data.api

import com.wicupp.beaver.core.BuildConfig
import com.wicupp.beaver.request.OAuthConfig

class OAuthConfigImpl(
    override val apiUrl: String = BuildConfig.getField("api_url"),
    override val loginUrl: String = "/oauth/token",
    override val logoutUrl: String = "/oauth/revoke",
    override val clientId: String = "project_safetymap",
    override val secretId: String = "1b6458a4-5ddd-4a6c-85ac-017db94909ca"
) : OAuthConfig