package com.wicupp.beaver.js.core.storage

import com.wicupp.beaver.core.storage.StoredPreferences
import com.wicupp.beaver.core.storage.StoredPreferencesManager

class StoredPreferencesManagerImpl: StoredPreferencesManager() {
    private val storage = StoredPreferencesJS()

    override fun init(onSuccess: () -> Unit) {
        storage.init {
            onSuccess()
        }
    }

    override fun getByName(name: String): StoredPreferences {
        return StoredPreferencesImpl(storage, name)
    }
}