package com.wicupp.safetymap.dashboard.data.flux

import com.wicupp.beaver.flux.Action
import com.wicupp.beaver.request.ErrorRequest

class PrepareDispatcher(private val globalDispatcher: com.wicupp.safetymap.dashboard.data.flux.GlobalDispatcher) {
    private var onSuccess: (() -> Unit)? = null
    private var onError: ((status: Int, error: ErrorRequest) -> Unit)? = null

    fun onSuccess(onSuccess: () -> Unit): PrepareDispatcher {
        this.onSuccess = onSuccess
        return this
    }

    fun onError(onError: (status: Int, error: ErrorRequest) -> Unit): PrepareDispatcher {
        this.onError = onError
        return this
    }

    fun dispatch(action: Action) {
        globalDispatcher.dispatch(action, {
            onSuccess?.invoke()
        }, { status, error ->
            onError?.invoke(status, error)
        })
    }
}