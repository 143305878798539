package com.wicupp.safetymap.dashboard.subscription

import com.wicupp.beaver.web.view.activity.Activity
import com.wicupp.beaver.web.view.activity.ActivityContext
import com.wicupp.beaver.web.view.activity.Intent
import com.wicupp.beaver.web.view.component.View
import com.wicupp.safetymap.dashboard.board.MainActivity
import com.wicupp.safetymap.dashboard.data.model.SubscriptionPlan
import com.wicupp.safetymap.dashboard.sign.SignActivity
import com.wicupp.safetymap.dashboard.subscription.cancel.SubscriptionCancelView
import com.wicupp.safetymap.dashboard.subscription.success.SubscriptionSuccessView

class SubscriptionActivity(activityContext: ActivityContext)
    : Activity(activityContext, "activity-subscription", "subscription") {

    companion object {
        private const val ROUTE_EXTRA = "ROUTE_EXTRA"
        private const val SUBSCRIPTION_PLAN_ROUTE_KEY = "SUBSCRIPTION_PLAN_ROUTE_KEY"
        private const val SUBSCRIPTION_SUCCESS_ROUTE_KEY = "SUBSCRIPTION_SUCCESS_ROUTE_KEY"
        private const val SUBSCRIPTION_CANCEL_ROUTE_KEY = "SUBSCRIPTION_CANCEL_ROUTE_KEY"
        private const val SESSION_ID_EXTRA = "SESSION_ID_EXTRA"
        private const val SUBSCRIPTION_PLAN_EXTRA = "SUBSCRIPTION_PLAN_EXTRA"

        fun startActivityWithPlan(
            activity: Activity,
            subscriptionPlan: SubscriptionPlan
        ) {
            activity.startActivity(Intent(SubscriptionActivity::class.js).apply {
                cleanHistory()
                putExtra(ROUTE_EXTRA, SUBSCRIPTION_PLAN_ROUTE_KEY)
                putExtra(SUBSCRIPTION_PLAN_EXTRA, subscriptionPlan.value)
            })
        }

        fun startActivityWithSuccess(activity: Activity, sessionId: String) {
            activity.startActivity(Intent(SubscriptionActivity::class.js).apply {
                cleanHistory()
                putExtra(ROUTE_EXTRA, SUBSCRIPTION_SUCCESS_ROUTE_KEY)
                putExtra(SESSION_ID_EXTRA, sessionId)
            })
        }

        fun startActivityWithCancel(activity: Activity) {
            activity.startActivity(Intent(SubscriptionActivity::class.js).apply {
                cleanHistory()
                putExtra(ROUTE_EXTRA, SUBSCRIPTION_CANCEL_ROUTE_KEY)
            })
        }
    }

    override fun onCreate(activityContext: ActivityContext) {
        super.onCreate(activityContext)

        val subscriptionView = findViewById<SubscriptionView>("view-subscription-view")
        subscriptionView.listener = object : SubscriptionView.Listener {
            override fun onSignInRequested() {
                SignActivity.startActivity(this@SubscriptionActivity)
            }

            override fun onSubscriptionDone() {
                showDashboard()
            }
        }

        val subscriptionSuccessView = findViewById<SubscriptionSuccessView>("view-subscription-success")
        val subscriptionCancelView = findViewById<SubscriptionCancelView>("view-subscription-cancel")

        val route = getStringExtra(ROUTE_EXTRA)
        when (route) {
            SUBSCRIPTION_SUCCESS_ROUTE_KEY -> {
                val sessionId = getStringExtra(SESSION_ID_EXTRA)
                subscriptionSuccessView.sessionId = sessionId
                subscriptionView.setVisibility(View.GONE)
                subscriptionSuccessView.setVisibility(View.VISIBLE)
                subscriptionSuccessView.listener = object : SubscriptionSuccessView.Listener {
                    override fun onFinishRequested() {
                        showDashboard()
                    }
                }
            }
            SUBSCRIPTION_CANCEL_ROUTE_KEY -> {
                subscriptionView.setVisibility(View.GONE)
                subscriptionCancelView.setVisibility(View.VISIBLE)
                subscriptionCancelView.listener = object : SubscriptionCancelView.Listener {
                    override fun onFinishRequested() {
                        showDashboard()
                    }
                }
            }
            SUBSCRIPTION_PLAN_ROUTE_KEY -> {
                subscriptionView.subscriptionPlan = SubscriptionPlan.fromValue(
                    getStringExtra(SUBSCRIPTION_PLAN_EXTRA) ?: ""
                )
            }
        }
    }

    private fun showDashboard() {
        val intent = Intent(MainActivity::class.js).apply {
            cleanHistory()
        }
        startActivity(intent)
    }
}