package com.wicupp.safetymap.dashboard.subscription.success

import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.component.SpinnerBar
import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.layout.LinearLayout
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory

class SubscriptionSuccessView(cv: ContextView): Layout(cv), SubscriptionSuccessContract.View {

    companion object {
        const val TAG_NAME = "subscription-success-view"
    }

    override fun layoutName() = "view-subscription-success"

    var sessionId: String? = null
        set(value) {
            value?.let {
                presenter.confirmSubscription(it)
            }
            field = value
        }

    var listener: Listener? = null

    private val presenter: SubscriptionSuccessContract.Presenter = Inject.get()
    private var containerView: LinearLayout? = null
    private var loadingView: SpinnerBar? = null

    override fun onCreate() {
        super.onCreate()

        containerView = findViewById("view-subscription-success-container")
        loadingView = findViewById("view-subscription-success-loading")
        findViewById<ButtonView>("view-subscription-success-button").onClick {
            listener?.onFinishRequested()
        }
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
        sessionId?.let {
            presenter.confirmSubscription(it)
        }
    }

    override fun onDetached() {
        presenter.detachView(this)
        super.onDetached()
    }

    override fun showLoadingIndicator(show: Boolean) {
        loadingView?.setVisibility(if (show) View.VISIBLE else View.GONE)
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    override fun showConfirmation() {
        containerView?.setVisibility(View.VISIBLE)
    }

    interface Listener {
        fun onFinishRequested()
    }
}