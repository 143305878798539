package com.wicupp.safetymap.dashboard.subscription.success

import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.beaver.request.OAuth
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.api.SubscriptionApi
import com.wicupp.safetymap.dashboard.error.ErrorManager

/**
 * [SubscriptionSuccessContract.Presenter] implementation.
 */
class SubscriptionSuccessPresenterImpl(
    private val handler: Handler,
    private val oauth: OAuth,
    private val subscriptionApi: SubscriptionApi
): SubscriptionSuccessContract.Presenter {

    private var view: SubscriptionSuccessContract.View? = null

    override fun attachView(view: SubscriptionSuccessContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
    }

    override fun detachView(view: SubscriptionSuccessContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun confirmSubscription(sessionId: String) {
        handler.post {
            view?.showLoadingIndicator(true)
        }

        subscriptionApi.confirmSubscription(
            sessionId,
            object : ApiListener<Unit> {
                override fun onSuccess(apiResponse: ApiResponse<Unit>) {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        view?.showConfirmation()
                    }
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        ErrorManager.formatRequestError(status, error)?.let {
                            view?.showError(it)
                        }
                    }
                    return true
                }
            })
    }
}
