package com.wicupp.safetymap.dashboard.board.refuge.edit.address

import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.view.component.*
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.safetymap.dashboard.board.refuge.edit.address.item.SearchAddressAdapter
import com.wicupp.safetymap.dashboard.board.refuge.edit.address.model.Address
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory

class AddAddressView(cv: ContextView): Layout(cv), AddAddressContract.View {

    companion object {
        const val TAG_NAME = "add-address-view"
    }

    override fun layoutName() = "view-add-address"

    var listener: Listener? = null

    private val presenter: AddAddressContract.Presenter = Inject.get()

    private lateinit var inputText: EditText
    private lateinit var searchButton: ButtonView
    private lateinit var resultList: ListView
    private var searchAddressAdapter: SearchAddressAdapter = SearchAddressAdapter()

    override fun onCreate() {
        super.onCreate()

        searchButton = findViewById("view-add-address-search")
        searchButton.onClick {
            presenter.searchAddress(inputText.getValue())
        }

        inputText = findViewById("view-add-address-input")
        inputText.onChanged {
            if (it.isEmpty()) {
                searchButton.setVisibility(View.GONE)
            } else {
                searchButton.setVisibility(View.VISIBLE)
            }
        }

        resultList = findViewById("view-add-address-result-list")
        resultList.setAdapter(searchAddressAdapter)
        searchAddressAdapter.listener = object : SearchAddressAdapter.Listener {
            override fun onAddressSelected(address: Address) {
                presenter.requestOpeningHours(address)
            }
        }
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
    }

    override fun onDetached() {
        presenter.detachView(this)
        super.onDetached()
    }

    override fun showLoadingIndicator(show: Boolean) {
        // TODO
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    override fun showAddresses(addresses: List<Address>) {
        searchAddressAdapter.setAddresses(addresses)
        resultList.setAdapter(searchAddressAdapter)
    }

    override fun showSelectedAddress(address: Address) {
        listener?.onAddressDefined(address)
    }

    interface Listener {
        fun onAddressDefined(
            address: Address
        )
    }
}