package com.wicupp.safetymap.dashboard.board.report

import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.view.component.*
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.smartview.AlertDialog
import com.wicupp.beaver.web.view.smartview.ButtonOption
import com.wicupp.beaver.web.view.smartview.SmartView
import com.wicupp.beaver.web.view.smartview.SmartViewType
import com.wicupp.safetymap.dashboard.board.refuge.RefugeNetworkSharingView
import com.wicupp.safetymap.dashboard.data.model.Refuge
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory

class ReportIncidentView(cv: ContextView): Layout(cv), ReportIncidentContract.View {

    companion object {
        const val TAG_NAME = "app-report-incident"
    }

    override fun layoutName() = "view-report-incident"

    private val presenter: ReportIncidentContract.Presenter = Inject.get()
    private var loadingView: SpinnerBar? = null
    private var addressView: EditText? = null
    private var messageView: EditText? = null


    override fun onCreate() {
        super.onCreate()

        loadingView = findViewById("view-report-incident-loading")

        addressView = findViewById("view-report-incident-address")
        messageView = findViewById("view-report-incident-message")

        findViewById<ButtonView>("view-report-incident-button").onClick {
            messageView?.getValue()?.let { message ->
                presenter.reportIncident(
                    0.0,//TODO
                    0.0,
                    message
                )
            }
        }
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
    }

    override fun onDetached() {
        presenter.detachView(this)
        super.onDetached()
    }

    override fun showLoadingIndicator(show: Boolean) {
        loadingView?.setVisibility(if (show) View.VISIBLE else View.GONE)
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    override fun showSharingView(projectId: String) {
        val refugeNetworkSharingView = createLayout(RefugeNetworkSharingView::class.js)
        refugeNetworkSharingView.projectId = projectId
        val smartView = SmartView(
            this,
            "refuge-network-sharing-dialog-view",
            refugeNetworkSharingView
        )
            .hideTitle()
            .setType(SmartViewType.DEFAULT)
            .setCloseButton(
                ButtonOption.Builder()
                    .icon("none")
                    .build()) {
                // Do nothing
            }
            .hideTopBar(true)
            .create()
            .show()
    }

    override fun showReportIncidentsSent() {
        AlertDialog.Builder(this)
            .setTitle("__Sent")
            //.setView(createLayout(LoadingView::class.js))
            .setMessage("_Your message has been sent successfully !")
            .create()
            .show()
    }
}