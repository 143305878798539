package com.wicupp.safetymap.dashboard.data.flux.handler

import com.wicupp.beaver.flux.*
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.safetymap.dashboard.data.api.AdminApi
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.flux.action.CreateProjectAction
import com.wicupp.safetymap.dashboard.data.model.Model
import com.wicupp.safetymap.dashboard.data.model.Project

class CreateProjectHandler(dispatcher: Dispatcher,
                           rootNotifier: RootNotifier,
                           private val adminApi: AdminApi
) : Handler<CreateProjectAction, Model>(dispatcher, rootNotifier) {

    override fun execute(model: Model, action: CreateProjectAction): CreateProjectHandler {

        adminApi.createProject(
            action.name,
            object : ApiListener<String> {
                override fun onSuccess(apiResponse: ApiResponse<String>) {
                    success(
                        CreateProjectAction::class, model.toBuilder()
                            .user(model.user.toBuilder().run {
                                projects = listOf(Project(apiResponse.body, action.name, action.name))
                                build()
                            })
                            .build()
                    )
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    // Do nothing, executed on background
                    error(status, error)
                    return true
                }
            }
        )

        return this
    }
}