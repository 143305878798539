package com.wicupp.safetymap.dashboard.board.refuge.edit.address.item

import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.layout.ListViewHolder
import com.wicupp.beaver.web.view.layout.ContextView

class SearchAddressItemView(cv: ContextView): ListViewHolder(cv) {

    companion object {
        const val TAG_NAME = "app-search-address-item"
    }

    override fun layoutName() = "view-search-address-item"
    var address: String? = null

    override fun onCreate() {
        super.onCreate()

        val addressView = findViewById<TextView>("view-search-address-item-text")
        addressView.setText(address)
    }
}