package com.wicupp.safetymap.dashboard.data.model

import com.wicupp.beaver.flux.CoreModel
import kotlinx.serialization.Serializable

@Serializable
class Model private constructor(
    val user: User,
    val refuges: List<Refuge>
) : CoreModel() {

    data class Builder(
        private var user: User = User.Builder().build(),
        private var refuges: List<Refuge> = listOf()
    ) {
        fun user(user: User) = apply { this.user = user }
        fun refuges(refuges: List<Refuge>) = apply { this.refuges = refuges }
        fun build() = Model(user, refuges)
    }

    fun toBuilder(): Builder {
        return Builder(user, refuges)
    }
}