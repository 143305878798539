package com.wicupp.safetymap.dashboard.data.flux.notifier

import com.wicupp.beaver.flux.CoreModel
import com.wicupp.beaver.flux.Notifier
import com.wicupp.safetymap.dashboard.data.model.Model
import com.wicupp.safetymap.dashboard.data.model.Refuge
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json

class RefugesNotifier: Notifier() {
    var listeners: MutableList<RefugesListener> = mutableListOf()
    var refuges: List<Refuge>? = null

    override fun updateModel(oldModel: CoreModel, newModel: CoreModel) {
        val newUserModel = getRefugesModelChange(oldModel as Model, newModel as Model)
        newUserModel?.let {
            refuges = it
            notify(it)
        }
    }

    override fun clean() {
        refuges = null
    }

    override fun get(): List<Refuge>? {
        return refuges
    }

    fun get(id: String): Refuge? {
        return refuges?.firstOrNull { it.id == id }
    }

    fun register(listener: RefugesListener) {
        listeners.add(listener)
        refuges?.let {
            listener.onDone(it)
        }
    }

    fun unregister(listener: RefugesListener) {
        listeners.remove(listener)
    }

    private fun getRefugesModelChange(oldModel: Model, newModel: Model): List<Refuge>? {

        if (Json.encodeToString(refuges) != Json.encodeToString(newModel.refuges) ) {
            notify(newModel.refuges)
            return newModel.refuges
        }
        return null
    }

    private fun notify(refuges: List<Refuge>) {
        for (listener in listeners) {
            listener.onDone(refuges)
        }
    }
}