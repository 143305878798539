package com.wicupp.safetymap.dashboard.sign

import com.wicupp.beaver.core.Context
import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.safetymap.dashboard.SafetymapConfig

class SignLegalsView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "app-sign-legals-view"
        const val LINK_FORMAT = "<a href=\"{0}\" target=\"_blank\">{1}</a>"
    }

    override fun layoutName() = "view-sign-legals"
    var listener: Listener? = null
    private val context: Context = Inject.get()
    private var buttonView: ButtonView? = null

    override fun onCreate() {
        super.onCreate()
        val tosText = findViewById<TextView>("view-sign-legals-tos")

        // TODO Why getString() not working ?
        val tosLink = context.formatString(
            LINK_FORMAT,
            SafetymapConfig.tosUrl,
            getString("common-tos")
        )
        val privacyLink = context.formatString(
            LINK_FORMAT,
            SafetymapConfig.privacyUrl,
            getString("common-privacy")
        )

        tosText.setText(
            "<p>" + context.getString("common-privacy-text", tosLink, privacyLink) + "</p>"
        )

        buttonView = findViewById<ButtonView>("view-sign-legals-signup-button").apply {
            onClick {
                listener?.onAccept()
            }
        }

        findViewById<ButtonView>("view-sign-legals-signup-refuse-button").onClick {
            listener?.onRefuse()
        }
    }

    fun showLoadingIndicator(value: Boolean) {
        if (value) {
            buttonView?.startLoading()
        } else {
            buttonView?.stopLoading()
        }
    }

    interface Listener {
        fun onRefuse()
        fun onAccept()
    }
}