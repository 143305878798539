package com.wicupp.safetymap.dashboard.board

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.core.routing.CustomRoute
import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.request.*
import com.wicupp.beaver.storage.KeyValueStorage
import com.wicupp.safetymap.dashboard.data.flux.GlobalDispatcher
import com.wicupp.safetymap.dashboard.data.flux.action.ClearUserAction
import com.wicupp.safetymap.dashboard.data.flux.notifier.UserNotifier
import com.wicupp.safetymap.dashboard.data.model.SubscriptionPlan
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorManager
import com.wicupp.safetymap.dashboard.routing.*

/**
 * [MainContract.Presenter] implementation.
 */
class MainPresenterImpl(
    private val routingManager: RoutingManager,
    private val handler: Handler,
    private val storage: KeyValueStorage,
    private val oauth: OAuth,
    private val globalDispatcher: GlobalDispatcher,
    private val userNotifier: UserNotifier
) : MainContract.Presenter {

    private var view: MainContract.View? = null
    private var isRouting = true

    init {
        oauth.onUnexpectedDisconnection {
            globalDispatcher.dispatch(ClearUserAction())
        }
    }

    override fun attachView(view: MainContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
    }

    override fun detachView(view: MainContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun requestRouting() {
        handler.post { view?.showLoadingIndicator(true) }

        routingManager.requestRouting(object : RoutingManager.Listener {
            override fun onSignin() {
                //No signin needed
                isRouting = false
                routeToSign()
            }

            override fun onCustomRoute(customRoute: CustomRoute, isAuthenticated: Boolean) {
                isRouting = false
                when (customRoute) {
                    is CheckoutSuccessRoute -> routeToCheckoutSuccess(customRoute.sessionId)
                    is CheckoutCancelRoute -> routeToCheckoutCancel()
                    is SubscriptionPremiumRoute -> routeToSubscription(SubscriptionPlan.PREMIUM)
                    is SubscriptionStarterRoute -> routeToSubscription(SubscriptionPlan.STARTER)
                }
            }

            override fun onDashboard() {
                Logger.d("Route on map")
                isRouting = false
                if (userNotifier.user?.projects?.isEmpty() == true) {
                    routeToOnboarding()
                } else {
                    routeToRefuges()
                }
            }

            override fun onRouteToPricing() {
                routeToPricing()
            }

            override fun onError(error: Error) {
                isRouting = false
                handler.post {
                    view?.showLoadingIndicator(false)
                    view?.showError(error)
                }

            }
        })
    }

    override fun requestLogout() {
        oauth.logout(object : OAuth.Listener {
            override fun onSuccess(body: String) {
                storage.removeAll {
                    globalDispatcher.dispatch(ClearUserAction())
                    routeToSign()
                }
            }

            override fun onError(status: Int, error: ErrorRequest): Boolean {
                handler.post {
                    view?.showLoadingIndicator(false)
                    ErrorManager.formatRequestError(status, error)?.let {
                        view?.showError(it)
                    }
                }
                return true
            }
        })
    }

    private fun routeToRefuges() {
        handler.post {
            view?.showLoadingIndicator(false)
            view?.routeToRefuges()
        }
    }

    private fun routeToPricing() {
        handler.post {
            view?.showLoadingIndicator(false)
            view?.routeToPricing()
        }
    }

    private fun routeToSubscription(subscriptionPlan: SubscriptionPlan) {
        handler.post {
            view?.showLoadingIndicator(false)
            view?.routeToSubscription(subscriptionPlan)
        }
    }

    private fun routeToCheckoutSuccess(sessionId: String) {
        handler.post {
            view?.showLoadingIndicator(false)
            view?.routeToCheckoutSuccess(sessionId)
        }
    }

    private fun routeToCheckoutCancel() {
        handler.post {
            view?.showLoadingIndicator(false)
            view?.routeToCheckoutCancel()
        }
    }

    private fun routeToOnboarding() {
        handler.post {
            view?.showLoadingIndicator(false)
            view?.routeToOnboarding()
        }
    }

    private fun routeToSign() {
        handler.post {
            view?.showLoadingIndicator(false)
            view?.routeToSign()
        }
    }
}
