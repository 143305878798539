package com.wicupp.beaver.js.push

import com.wicupp.beaver.push.Push

class PushImpl: Push {
    val pushJsImpl = PushJsImpl()

    override fun register(onNewToken: (token: String?, onTokenConsumed: () -> Unit) -> Unit) {
        pushJsImpl.register(onNewToken)
    }

    override fun onPushReceived(onReceived: (payload: String) -> Unit) {
        pushJsImpl.onPushReceived(onReceived)
    }
}