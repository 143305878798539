package com.wicupp.safetymap.dashboard.error

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.request.ErrorRequest

class ErrorManager {

    companion object {
        const val REFRESH_TOKEN_NOT_FOUND_ERROR_CODE = 6000
        const val ACCESS_TOKEN_NOT_FOUND_ERROR_CODE = 6001
        const val CONNECTIVITY_ERROR_CODE = 6099

        fun formatRequestError(status: Int,
                               errorRequest: ErrorRequest
        ): Error? {
            when (status) {
                0 -> {
                    return formatNetworkError(status, errorRequest)
                }
                401 -> {
                    return formatUnauthorized(status, errorRequest)
                }
                403 -> {
                    return formatForbidden(status, errorRequest)
                }
                500 -> {
                    return NetworkError(AppErrorId.UNKNOWN_NETWORK_ERROR_WITH_CODE, status, errorRequest)
                }
                501 -> {
                    return formatNotImplementedError(status, errorRequest)
                }
                400 -> {
                    return formatError400(status, errorRequest)
                }
                9999 -> {
                    return formatError9999(status, errorRequest)
                }
            }
            return NetworkError(AppErrorId.UNKNOWN_NETWORK_ERROR_WITH_CODE, status, errorRequest)
        }

        private fun formatError400(status: Int,
                                   errorRequest: ErrorRequest
        ): Error? {
            return when (errorRequest.code) {
                4000, 4002 -> {
                    Logger.e(errorRequest.message)
                    null
                }
                4004, 4005 -> NetworkError(AppErrorId.NETWORK_LIMIT_REACHED, status, errorRequest)
                4001 -> NetworkError(AppErrorId.RATE_TOO_LARGE_AREA, status, errorRequest)
                else -> NetworkError(AppErrorId.UNKNOWN_NETWORK_ERROR_WITH_CODE, status, errorRequest)
            }
        }

        private fun formatUnauthorized(status: Int,
                                       errorRequest: ErrorRequest
        ): Error {
            return when (errorRequest.code) {
                4010 -> NetworkError(AppErrorId.SIGN_IN_ERROR, status, errorRequest)
                4013 -> NetworkError(AppErrorId.BAD_AUTHENT_ERROR, status, errorRequest)
                else -> NetworkError(AppErrorId.UNKNOWN_NETWORK_ERROR_WITH_CODE, status, errorRequest)
            }
        }

        private fun formatForbidden(status: Int,
                                    errorRequest: ErrorRequest
        ): Error? {
            return when (errorRequest.code) {
                4030 -> NetworkError(AppErrorId.EMAIL_NOT_CONFIRMED, status, errorRequest)
                else -> {
                    Logger.e(errorRequest.message)
                    null
                }
            }
        }

        private fun formatNotImplementedError(status: Int,
                                              errorRequest: ErrorRequest
        ): Error? {
            return when (errorRequest.code) {
                5011 -> NetworkError(AppErrorId.REGION_NOT_AVAILABLE, status, errorRequest)
                else -> {
                    Logger.e(errorRequest.message)
                    null
                }
            }
        }

        private fun formatError9999(status: Int,
                                    errorRequest: ErrorRequest
        ): Error? {
            when (errorRequest.code) {
                REFRESH_TOKEN_NOT_FOUND_ERROR_CODE,
                ACCESS_TOKEN_NOT_FOUND_ERROR_CODE -> {
                    Logger.e(errorRequest.message)
                    return null
                }
                CONNECTIVITY_ERROR_CODE ->
                    return NetworkError(AppErrorId.CONNECTION_ISSUE, status, errorRequest)
            }
            return NetworkError(AppErrorId.UNKNOWN_ERROR, status, errorRequest)
        }

        private fun formatNetworkError(status: Int,
                                       errorRequest: ErrorRequest): Error {
            return NetworkError(AppErrorId.UNKNOWN_NETWORK_ERROR, status, errorRequest)
        }
    }
}