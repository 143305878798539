package com.wicupp.safetymap.dashboard

import com.wicupp.beaver.core.AppConfig
import com.wicupp.beaver.core.BuildConfig

object SafetymapConfig: AppConfig() {
    override val baseUrl by lazy {
        BuildConfig.getField("url")
    }
    override val apiUrl by lazy {
        BuildConfig.getField("api_url")
    }
    val privacyUrl = "$baseUrl/privacy"
    val tosUrl = "$baseUrl/tos"
    val legalNoticesUrl = "$baseUrl/legal-notices"
    val faqUrl = "$baseUrl/project/faq"
}