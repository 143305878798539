package com.wicupp.safetymap.dashboard.settings.delete

import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.api.UserApi
import com.wicupp.safetymap.dashboard.error.ErrorManager

/**
 * [DeleteAccountContract.Presenter] implementation.
 */
class DeleteAccountPresenterImpl(
    private val userApi: UserApi,
    private val handler: Handler
) : DeleteAccountContract.Presenter {

    private var view: DeleteAccountContract.View? = null
    private var email: String = ""
    private var token: String = ""

    override fun attachView(view: DeleteAccountContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
        email = ""
    }

    override fun detachView(view: DeleteAccountContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun requestVerificationCode() {
        view?.showLoadingIndicator(true)
        userApi.deleteAccount(
            object : ApiListener<String> {
                override fun onSuccess(apiResponse: ApiResponse<String>) {
                    token = apiResponse.body
                    handler.post {
                        view?.showLoadingIndicator(false)
                        view?.showVerificationCodeView()
                    }
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        ErrorManager.formatRequestError(status, error)?.let {
                            view?.showError(it)
                        }
                    }
                    return true
                }
            })
    }

    override fun setVerificationCode(code: Int) {
        view?.showLoadingIndicator(true)

        userApi.confirmAccountDeletion(token, code, object : ApiListener<Unit> {
            override fun onSuccess(apiResponse: ApiResponse<Unit>) {
                handler.post {
                    view?.showConfirmation()
                    view?.showLoadingIndicator(false)
                }
            }

            override fun onError(status: Int, error: ErrorRequest): Boolean {
                handler.post {
                    view?.showLoadingIndicator(false)
                    ErrorManager.formatRequestError(status, error)?.let {
                        view?.showError(it)
                    }
                }
                return true
            }
        })
    }
}
