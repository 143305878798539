package com.wicupp.safetymap.dashboard.menu

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.WebContext
import com.wicupp.beaver.web.WebCore
import com.wicupp.beaver.web.view.component.JSView
import com.wicupp.beaver.web.view.component.TextView
import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.history.PageHistory
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.layout.LinearLayout
import com.wicupp.beaver.web.view.smartview.AlertDialog
import com.wicupp.safetymap.dashboard.board.MainView.Companion.BOARD_PAGE_HISTORY_TYPE
import com.wicupp.safetymap.dashboard.board.MainView.Companion.PROJECT_SETTINGS_HISTORY_PAGE
import com.wicupp.safetymap.dashboard.board.MainView.Companion.REFUGE_HISTORY_PAGE
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory
import com.wicupp.safetymap.dashboard.loading.LoadingView

class MenuView(cv: ContextView): Layout(cv), MenuContract.View {

    companion object {
        const val TAG_NAME = "app-menu-menu-view"
    }

    override fun layoutName() = "view-menu"
    var listener: Listener? = null
    private val presenter: MenuContract.Presenter = Inject.get()

    private var refugesButton: LinearLayout? = null
    private var apiButton: LinearLayout? = null
    private var loginButton: LinearLayout? = null
    private var logoutButton: LinearLayout? = null
    private var settingsButton: LinearLayout? = null
    private var projectSettingsButton: LinearLayout? = null
    private var loadingView: AlertDialog? = null

    override fun onCreate() {
        super.onCreate()

        refugesButton = findViewById<LinearLayout>("view-menu-item-refuges").apply {
            setVisibility(View.VISIBLE)
            onClick {
                selectItem(this)
                listener?.onRefugesRequested()
            }
        }

        findViewById<LinearLayout>("view-menu-item-report-incident").apply {
            onClick {
                listener?.onReportIncidentsRequested()
            }
        }

        apiButton = findViewById<LinearLayout>("view-menu-item-api").apply {
            onClick {
                listener?.onApiRequested()
            }
        }

        settingsButton = findViewById<LinearLayout>("view-menu-item-settings").apply {
            onClick {
                listener?.onSettingsRequested()
            }
        }

        findViewById<LinearLayout>("view-menu-item-help").apply {
            onClick {
                listener?.onHelpCenterRequested()
            }
        }

        val legalsButton = findViewById<LinearLayout>("view-menu-item-legals")
        legalsButton.onClick {
            listener?.onLegalsRequested()
        }

        loginButton = findViewById<LinearLayout>("view-menu-item-login")
        loginButton?.onClick {
            listener?.onLoginRequested()
        }

        logoutButton = findViewById<LinearLayout>("view-menu-item-logout")
        logoutButton?.onClick {
            listener?.onLogoutRequested()
        }

        projectSettingsButton = findViewById<LinearLayout>("view-menu-item-my-project").apply {
            onClick {
                selectItem(this)
                listener?.onProjectSettingsRequested()
            }
        }

        WebCore.history().onSet { page ->
            if (page.type == BOARD_PAGE_HISTORY_TYPE) {
                when (page.page) {
                    REFUGE_HISTORY_PAGE -> selectItem(refugesButton!!)
                    PROJECT_SETTINGS_HISTORY_PAGE -> selectItem(projectSettingsButton!!)
                }
            }
        }

        WebCore.history().onBack { fromPage, newPage ->
            Logger.d("before selectItem: ${newPage?.page}")
            if (newPage?.type == BOARD_PAGE_HISTORY_TYPE) {
                when (newPage.page) {
                    REFUGE_HISTORY_PAGE -> selectItem(refugesButton!!)
                    PROJECT_SETTINGS_HISTORY_PAGE -> selectItem(projectSettingsButton!!)
                }
            }
        }
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
        presenter.registerToUser()
    }

    override fun onDetached() {
        super.onDetached()
        presenter.detachView(this)
    }

    override fun showLogout(show: Boolean) {
        if (show) {
            logoutButton?.setVisibility(View.VISIBLE)
            loginButton?.setVisibility(View.GONE)
            settingsButton?.setVisibility(View.VISIBLE)
        } else {
            logoutButton?.setVisibility(View.GONE)
            loginButton?.setVisibility(View.VISIBLE)
            settingsButton?.setVisibility(View.GONE)
        }
    }

    override fun showLoadingIndicator(show: Boolean) {
        if (show) {
            loadingView = AlertDialog.Builder(this)
                .setTitle("__Loading")
                .setView(createLayout(LoadingView::class.js))
                .setCancelable(false)
                .create()
            loadingView?.show()
        } else {
            loadingView?.hide()
        }
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    private fun selectItem(view: JSView) {
        if (projectSettingsButton != view) {
            projectSettingsButton?.setBackground("unset")
        }
        if (refugesButton != view) {
            refugesButton?.setBackground("unset")
        }
        view.setBackground("@color/color-menu-item-selected")
    }

    interface Listener {
        fun onSettingsRequested()
        fun onLogoutRequested()
        fun onLoginRequested()
        fun onLegalsRequested()
        fun onHelpCenterRequested()
        fun onRefugesRequested()
        fun onReportIncidentsRequested()
        fun onApiRequested()
        fun onProjectSettingsRequested()
    }
}
