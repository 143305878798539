package com.wicupp.safetymap.dashboard.data.api

import com.wicupp.safetymap.dashboard.data.model.Refuge

interface AdminApi {

    fun getRefuges(
        projectId: String,
        listener: ApiListener<List<Refuge>>
    )

    fun createRefuge(
        projectId: String,
        refuge: Refuge,
        listener: ApiListener<String>
    )

    fun updateRefuge(
        refuge: Refuge,
        listener: ApiListener<Unit>
    )

    fun deleteRefuge(
        refugeId: String,
        listener: ApiListener<Unit>
    )

    fun createProject(
        projectName: String,
        listener: ApiListener<String>
    )

    fun updateProject(
        projectId: String,
        projectName: String,
        listener: ApiListener<Unit>
    )

    fun reportIncident(
        latitude: Double,
        longitude: Double,
        message: String,
        listener: ApiListener<Unit>
    )

    class RefugesResponse(override var body: List<Refuge>) : ApiResponse<List<Refuge>>()
    class CreateProjectResponse(override var body: String) : ApiResponse<String>()

    class CreateRefugeResponse(override var body: String) : ApiResponse<String>()
}