package com.wicupp.safetymap.dashboard.board

import com.wicupp.beaver.injection.Inject
import com.wicupp.beaver.web.WebCore
import com.wicupp.beaver.web.view.component.*
import com.wicupp.beaver.web.view.history.PageHistory
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.FrameLayout
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.smartview.*
import com.wicupp.safetymap.dashboard.board.api.ApiView
import com.wicupp.safetymap.dashboard.board.project.ProjectSettingsView
import com.wicupp.safetymap.dashboard.board.refuge.RefugesView
import com.wicupp.safetymap.dashboard.board.report.ReportIncidentView
import com.wicupp.safetymap.dashboard.data.model.SubscriptionPlan
import com.wicupp.safetymap.dashboard.error.Error
import com.wicupp.safetymap.dashboard.error.ErrorViewFactory
import com.wicupp.safetymap.dashboard.legals.LegalView
import com.wicupp.safetymap.dashboard.settings.SettingsView
import com.wicupp.safetymap.dashboard.sign.SignView

class MainView(cv: ContextView): Layout(cv), MainContract.View {

    companion object {
        const val TAG_NAME = "app-main-view"
        const val BOARD_PAGE_HISTORY_TYPE = "BOARD_PAGE_HISTORY_TYPE"
        const val REFUGE_HISTORY_PAGE = "refuge"
        const val PROJECT_SETTINGS_HISTORY_PAGE = "project/settings"
    }

    override fun layoutName() = "view-main"
    var listener: Listener? = null
    private val presenter: MainContract.Presenter = Inject.get()
    private var loadingView: SpinnerBar? = null
    private var bodyView: FrameLayout? = null

    override fun onCreate() {
        super.onCreate()

        loadingView = findViewById("view-main-loading")
        bodyView = findViewById("view-main-body")

        WebCore.history().onBack { fromPage, newPage ->
            if (newPage?.type == BOARD_PAGE_HISTORY_TYPE) {
                when (newPage.page) {
                    REFUGE_HISTORY_PAGE -> showRefuges()
                    PROJECT_SETTINGS_HISTORY_PAGE -> showProjectSettings()
                }
            } else if (fromPage.type == BOARD_PAGE_HISTORY_TYPE) {
                WebCore.history().back(1)
            }
        }
    }

    override fun onAttached() {
        super.onAttached()
        presenter.attachView(this)
        requestRouting()
    }

    override fun onDetached() {
        super.onDetached()
        presenter.detachView(this)
    }

    fun requestRouting() {
        presenter.requestRouting()
    }

    override fun showLoadingIndicator(show: Boolean) {
        loadingView?.setVisibility(if (show) View.VISIBLE else View.GONE)
        bodyView?.setVisibility(if (show) View.GONE else View.VISIBLE)
    }

    override fun showError(error: Error) {
        ErrorViewFactory.showError(this, error)
    }

    override fun showLogin() {
        val signView = createLayout(SignView::class.js)
        val smartView = SmartView(
            this,
            "sign-view",
            signView
        )
            .hideTitle()
            .setType(SmartViewType.DEFAULT)
            .setCloseButton(
                ButtonOption.Builder()
                    .icon("none")
                    .build()) {
                // Do nothing
            }
            .hideTopBar(true)
            .create()
            .show()

        signView.listener = object : SignView.Listener {
            override fun onSignInDone() {
                smartView.hide()
            }

            override fun onSignUpDone() {
                smartView.hide()
            }
        }
    }

    override fun routeToSign() {
        listener?.onSigninRequested()
    }

    override fun routeToRefuges() {
        WebCore.history().set(PageHistory(
            BOARD_PAGE_HISTORY_TYPE,
            REFUGE_HISTORY_PAGE
        ))
        showRefuges()
    }

    private fun showRefuges() {
        bodyView?.clearViews()
        val refugesView = createLayout(RefugesView::class.js)
        refugesView.addClass("fullscreen-body")
        bodyView?.appendView(
            refugesView
        )
    }

    fun routeToReportIncidents() {
        bodyView?.clearViews()
        val refugesView = createLayout(ReportIncidentView::class.js)
        refugesView.addClass("fullscreen-body")
        bodyView?.appendView(
            refugesView
        )
    }

    override fun routeToPricing() {
        AlertDialog.Builder(this)
            .setTitle(getString("project-subscription"))
            .setMessage(getString("project-no-subscription-disconnect"))
            .setNegativeButton(getString("common-log-out")) { dialog ->
                dialog.hide {
                    presenter.requestLogout()
                }
            }
            .setPositiveButton(getString("common-continue")) { dialog ->
                dialog.hide {
                    listener?.onPricingRequested()
                }
            }
            .create()
            .show()
    }

    override fun routeToSubscription(subscriptionPlan: SubscriptionPlan) {
        listener?.onSubscriptionRequested(subscriptionPlan)
    }

    override fun routeToCheckoutSuccess(sessionId: String) {
        listener?.onCheckoutSuccessRequested(sessionId)
    }

    override fun routeToCheckoutCancel() {
        listener?.onCheckoutCancelRequested()
    }

    override fun routeToApi() {
        bodyView?.clearViews()
        val apiView = createLayout(ApiView::class.js)
        apiView.addClass("fullscreen-body")
        bodyView?.appendView(
            apiView
        )
    }

    override fun routeToOnboarding() {
        listener?.onOnboardingRequested()
    }

    fun routeToProjectSettings() {
        WebCore.history().set(PageHistory(
            BOARD_PAGE_HISTORY_TYPE,
            PROJECT_SETTINGS_HISTORY_PAGE
        ))
        showProjectSettings()
    }

    private fun showProjectSettings() {
        bodyView?.clearViews()
        val view = createLayout(ProjectSettingsView::class.js).apply {
            addClass("fullscreen-body")
        }
        bodyView?.appendView(
            view
        )
    }

    fun onAddToProjectRequested() {
        val view = bodyView?.firstView()

        when (view) {
            is RefugesView -> {
                view.requestCreateRefugeView()
            }
            else -> {
                routeToRefuges()
            }
        }
    }

    fun requestLogout() {
        presenter.requestLogout()
    }

    fun showSettings() {
        val settingsView = createLayout(SettingsView::class.js)

        settingsView.listener = object : SettingsView.Listener {
            override fun onLogoutRequested() {
                requestLogout()
            }
        }

        SmartView(this, getString("common-settings"), settingsView)
            .create()
            .show()
    }

    fun showLegals() {
        BottomSheet.from(this, createLayout(LegalView::class.js))
            .show()
    }

    interface Listener {
        fun onSigninRequested()
        fun onPricingRequested()
        fun onSubscriptionRequested(subscriptionPlan: SubscriptionPlan)
        fun onCheckoutSuccessRequested(sessionId: String)
        fun onCheckoutCancelRequested()
        fun onOnboardingRequested()
    }
}