package com.wicupp.safetymap.dashboard.data.flux.handler

import com.wicupp.beaver.flux.*
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.beaver.request.Parameter
import com.wicupp.beaver.request.ApiRequest
import com.wicupp.beaver.utils.JsonUtils.Companion.getArrayOrNull
import com.wicupp.beaver.utils.JsonUtils.Companion.getBoolean
import com.wicupp.beaver.utils.JsonUtils.Companion.getString
import com.wicupp.beaver.utils.JsonUtils.Companion.getStringOrNull
import com.wicupp.safetymap.dashboard.data.api.ApiUrl
import com.wicupp.safetymap.dashboard.data.flux.action.GetUserAction
import com.wicupp.safetymap.dashboard.data.model.Model
import com.wicupp.safetymap.dashboard.data.model.Project
import com.wicupp.safetymap.dashboard.data.model.SubscriptionPlan
import kotlinx.serialization.json.*

class GetUserHandler(
    dispatcher: Dispatcher,
    rootNotifier: RootNotifier,
    private val apiRequest: ApiRequest
) : Handler<GetUserAction, Model>(dispatcher, rootNotifier) {

    override fun execute(model: Model, action: GetUserAction): GetUserHandler {

        val parameterBuilder = Parameter.Builder()
        parameterBuilder.addParameter("apiVersion", "NA")
        parameterBuilder.addParameter("platform", "web")
        parameterBuilder.addParameter("type", "dashboard")

        apiRequest.get(
            ApiUrl.USER,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {

                    val projectArray = buildList {
                        getArrayOrNull(body.jsonObject["projects"])?.forEach {
                            add(Project.Builder().run {
                                id = getString(it.jsonObject["id"])
                                name = getString(it.jsonObject["name"])
                                refugeNetworkName = getString(it.jsonObject["name"])
                                build()
                            })
                        }
                    }

                    val user = model.user.toBuilder().run {
                        userId = getString(body.jsonObject["id"])
                        name = getString(body.jsonObject["name"])
                        email = getString(body.jsonObject["email"])
                        language = getString(body.jsonObject["language"])
                        acceptNewsletters = getBoolean(body.jsonObject["authorise_newsletter"])
                        isTrackingEnabled = getBoolean(body.jsonObject["authorise_tracking"])
                        subscription = SubscriptionPlan.fromValue(
                            getStringOrNull(body.jsonObject["subscription"])
                        )
                        projects = projectArray
                        build()
                    }

                    success(GetUserAction::class, model.toBuilder()
                        .user(user)
                        .build())
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    // Do nothing, executed on background
                    error(status, error)
                    return true
                }

            },
            parameterBuilder.build(),
            null,
            false,
            version = 2
        )

        return this
    }
}