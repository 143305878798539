package com.wicupp.beaver.core.task

import kotlin.native.concurrent.ThreadLocal

class Task {

    @ThreadLocal
    companion object: TaskInterface {

        var taskInstance: TaskInterface? = null

        internal fun provideTaskImpl(taskImpl: TaskInterface) {
            taskInstance = taskImpl
        }

        override fun async(runnable: () -> Unit) {
            getInstance().async(runnable)
        }

        override fun delay(runnable: () -> Unit, delay: Int) {
            getInstance().delay(runnable, delay)
        }

        private fun getInstance(): TaskInterface {
            return taskInstance ?: throw IllegalStateException("TaskInterface must be declared.")
        }
    }
}