package com.wicupp.beaver.web.view.layout

abstract class ListViewHolder(private val cv: ContextView) : Layout(cv) {
    override fun onLayoutLoaded() {
        cv.replaceIds { id ->
            return@replaceIds id
                .dropLast(1)
                .concat(cv.getInstanceId() + '"')
        }
    }

    override fun <T> findViewById(id: String): T {
        return cv.findViewById(id + cv.getInstanceId()) as? T
            ?: throw NoSuchElementException("The resource $id${cv.getInstanceId()} doesn't exist.")
    }
}