package com.wicupp.safetymap.dashboard.data.model

enum class SubscriptionPlan(val value: String) {
    STARTER("starter"),
    PREMIUM("premium"),
    NONE("none");

    companion object {
        fun fromValue(value: String?): SubscriptionPlan {
            return entries.firstOrNull { it.value == value } ?: NONE
        }
    }
}