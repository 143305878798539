package com.wicupp.safetymap.dashboard.data.api

import com.wicupp.beaver.request.ApiRequest
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.beaver.request.Parameter
import com.wicupp.beaver.utils.JsonUtils
import com.wicupp.beaver.utils.JsonUtils.Companion.getArray
import com.wicupp.beaver.utils.JsonUtils.Companion.getDouble
import com.wicupp.beaver.utils.JsonUtils.Companion.getString
import com.wicupp.safetymap.dashboard.data.model.*
import com.wicupp.safetymap.dashboard.utils.OpeningHoursPeriod
import com.wicupp.safetymap.dashboard.utils.OpeningHoursPeriodDetail
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject

class SearchApiImpl(
    private val apiRequest: ApiRequest
): SearchApi {

    override fun searchStreet(
        street: String,
        listener: ApiListener<List<Street>>
    ) {
        val parameters = Parameter.Builder().run {
            addParameter("street", street)
            build()
        }

        apiRequest.get(ApiUrl.SEARCH_STREET,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    val streets = buildList {
                        getArray(body.jsonObject["streets"]).forEach {
                            add(Street(
                                street = getString(it.jsonObject["street"]),
                                longitude = getDouble(it.jsonObject["longitude"]),
                                latitude = getDouble(it.jsonObject["latitude"])
                            ))
                        }
                    }
                    listener.onSuccess(SearchApi.StreetsResponse(streets))
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }

            }, parameters,
            null,
            isCacheEnabled = true,
            version = 2
        )
    }

    override fun getPlaceInformation(
        description: String,
        listener: ApiListener<PlaceInformation>
    ) {
        val parameters = Parameter.Builder().run {
            addParameter("description", description)
            build()
        }

        apiRequest.get(ApiUrl.GET_PLACE_INFORMATION,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    val openingPeriods = getArray(body.jsonObject["opening_periods"]).map {
                        val open = JsonUtils.getObjectOrNull(it.jsonObject["open"])
                        val close = JsonUtils.getObjectOrNull(it.jsonObject["close"])

                        OpeningHoursPeriod(
                            open = OpeningHoursPeriodDetail(
                                day = JsonUtils.getInt(open!!.jsonObject["day"]),
                                time = getString(open.jsonObject["time"])
                            ),
                            close = close?.let {
                                OpeningHoursPeriodDetail(
                                    day = JsonUtils.getInt(close.jsonObject["day"]),
                                    time = getString(close.jsonObject["time"])
                                )
                            }
                        )
                    }

                    val categories = JsonUtils.getArrayOrNull(body.jsonObject["categories"])?.map {
                        Refuge.Category.fromString(getString(it))
                    } ?: listOf()

                    listener.onSuccess(
                        SearchApi.PlaceInformationResponse(
                            PlaceInformation(
                                name = getString(body.jsonObject["name"]),
                                refugeCategories = categories,
                                openingHoursPeriods = openingPeriods
                            )
                        )
                    )
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }

            }, parameters,
            null,
            isCacheEnabled = true,
            version = 2
        )
    }


}