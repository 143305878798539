package com.wicupp.safetymap.dashboard.board.api

import com.wicupp.beaver.core.task.Handler
import com.wicupp.safetymap.dashboard.data.flux.GlobalDispatcher

/**
 * [ApiContract.Presenter] implementation.
 */
class ApiPresenterImpl(
    private val handler: Handler,
    private val globalDispatcher: GlobalDispatcher
) : ApiContract.Presenter {

    private var view: ApiContract.View? = null

    override fun attachView(view: ApiContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
    }

    override fun detachView(view: ApiContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun requestApiStatus() {

    }
}
