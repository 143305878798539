package com.wicupp.safetymap.dashboard.data.model

import com.wicupp.safetymap.dashboard.utils.OpeningHoursPeriod
import kotlinx.serialization.Serializable

@Serializable
data class Refuge(
    val id: String,
    val name: String,
    val street: String,
    val longitude: Double,
    val latitude: Double,
    val timezone: String,
    val openingPeriods: List<OpeningHoursPeriod>,
    val categories: List<Category>,
    val metropolis: Metropolis
) {

    companion object {
        fun emptyRefuge(): Refuge {
            return Builder().run {
                id = ""
                name = ""
                street = ""
                longitude = 0.0
                latitude = 0.0
                timezone = ""
                openingPeriods = listOf()
                categories = listOf()
                metropolis = Metropolis.UNKNOWN
                build()
            }
        }
    }

    data class Builder(
        var id: String? = null,
        var name: String? = null,
        var street: String? = null,
        var longitude: Double? = null,
        var latitude: Double? = null,
        var timezone: String? = null,
        var openingPeriods: List<OpeningHoursPeriod>? = null,
        var categories: List<Category>? = null,
        var metropolis: Metropolis? = null
    ) {
        fun build() = Refuge(
            id!!, name!!, street!!, longitude!!, latitude!!, timezone!!, openingPeriods!!, categories!!, metropolis!!
        )
    }

    fun toBuilder(): Builder {
        return Builder(id, name, street, longitude, latitude, timezone, openingPeriods, categories, metropolis)
    }

    @Serializable
    enum class Category(val value: String) {
        POLICE_STATION("p"),
        BAR("b"),
        RESTAURANT("r"),
        NIGHT_CLUB("nc"),
        MARKET("m"),
        HOTEL("h"),
        OTHERS("e"),
        UNKNOWN("UNKNOWN");

        override fun toString(): String {
            return value
        }

        companion object {
            fun fromString(value: String): Category {
                entries.forEach {
                    if (it.value == value) {
                        return it
                    }
                }
                return UNKNOWN
            }
        }
    }

    @Serializable
    enum class Metropolis(val value: String) {
        Lyon("Lyon"),
        Bordeaux("Bordeaux"),
        Lille("Lille"),
        Toulouse("Toulouse"),
        Beauvais("Beauvais"),
        Rouen("Rouen"),
        Quimper("Quimper"),
        UNKNOWN("UNKNOWN");

        override fun toString(): String {
            return value
        }

        companion object {
            fun fromString(value: String): Metropolis {
                entries.forEach {
                    if (it.value == value) {
                        return it
                    }
                }
                return UNKNOWN
            }
        }
    }
}