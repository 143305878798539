package com.wicupp.safetymap.dashboard.data.flux.handler

import com.wicupp.beaver.flux.*
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.safetymap.dashboard.data.api.AdminApi
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.flux.action.CreateProjectAction
import com.wicupp.safetymap.dashboard.data.flux.action.UpdateProjectAction
import com.wicupp.safetymap.dashboard.data.model.Model

class UpdateProjectHandler(dispatcher: Dispatcher,
                           rootNotifier: RootNotifier,
                           private val adminApi: AdminApi
) : Handler<UpdateProjectAction, Model>(dispatcher, rootNotifier) {

    override fun execute(model: Model, action: UpdateProjectAction): UpdateProjectHandler {

        adminApi.updateProject(
            action.projectId,
            action.name,
            object : ApiListener<Unit> {
                override fun onSuccess(apiResponse: ApiResponse<Unit>) {
                    val project = model.user.projects.first { it.id == action.projectId }.toBuilder().run {
                        name = action.name
                        refugeNetworkName = action.name
                        build()
                    }
                    val newProjects = model.user.projects.toMutableList().apply {
                        removeAll { it.id == action.projectId }
                        add(project)
                    }
                    success(
                        CreateProjectAction::class, model.toBuilder()
                            .user(model.user.toBuilder().run {
                                projects = newProjects
                                build()
                            })
                            .build()
                    )
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    // Do nothing, executed on background
                    error(status, error)
                    return true
                }
            }
        )

        return this
    }
}