package com.wicupp.beaver.request

import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.core.connectivity.ConnectivityChange
import com.wicupp.beaver.request.http.Http
import com.wicupp.beaver.request.http.Request
import com.wicupp.beaver.request.http.RequestBody
import com.wicupp.beaver.storage.KeyValueStorage
import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.core.utils.Utils
import com.wicupp.beaver.utils.JsonUtils.Companion.getArrayOrNull
import com.wicupp.beaver.utils.JsonUtils.Companion.getIntOrNull
import com.wicupp.beaver.utils.JsonUtils.Companion.getObjectOrNull
import com.wicupp.beaver.utils.JsonUtils.Companion.getStringOrNull
import kotlinx.serialization.json.*

class ApiRequest constructor(private val apiUrl: String,
                             private val oAuth: OAuth,
                             private val storage: KeyValueStorage,
                             val http: Http,
                             connectivityChange: ConnectivityChange,
                             private val handler: Handler
) {

    companion object {
        const val REQUEST_STORAGE_KEY = "requests"

        fun formatGetParams(parameter: Parameter): String {
            val params = mutableListOf<String>()
            for ((key, value) in parameter.parameters) {
                if (value is String) {
                    params.add("\"${Utils.encodeURI(key)}\":\"${Utils.encodeURI(value)}\"")
                } else {
                    params.add("\"${Utils.encodeURI(key)}\":${value}")
                }
            }
            return params.joinToString(separator = ",")
        }

        fun formatGetParamsV2(parameter: Parameter): String {
            return parameter.parameters
                .map { "${it.key}=${it.value}" }
                .joinToString(separator = "&")
        }

        fun parseError(body: JsonElement): ErrorRequest {
            if (body.jsonObject["errors"] != null) {
                val error = getArrayOrNull(body.jsonObject["errors"])?.get(0)?.jsonObject
                val code = getIntOrNull(error?.get("code")) ?: 0
                val message = error?.get("code")?.toString() ?: ""
                return ErrorRequest(code, message)
            } else if (body.jsonObject["error"] != null) {
                return ErrorRequest(-1, getStringOrNull(body.jsonObject["error"]) ?: "")
            } else {
                return ErrorRequest(-1, "")
            }
        }
    }

    var isOnline = true

    init {
        connectivityChange.subscribeToConnectivityChange { connected ->
            isOnline = connected
        }
    }

    fun get(url: String,
            listener: Listener,
            params: Parameter,
            id: String?,
            isCacheEnabled: Boolean,
            useAccessToken: Boolean = true,
            version: Int = 1) {

        val cachedRequest = CachedRequest(
            UniqueRequest(url, id, params, isCacheEnabled),
            null,
            isCacheEnabled,
            listener,
            "GET",
            false
        )

        if (!isOnline || isCacheEnabled) {
            storage.getItemFromTable(REQUEST_STORAGE_KEY, getUniqueRequestHashed(cachedRequest.uniqueRequest)) { value, _ ->
                value?.let {
                    cachedRequest.cache = Utils.generateHash(it)
                    listener.onSuccess(parseToJsonElement(it))
                }
            }
        }

        if (useAccessToken) {
            getSecured(url, cachedRequest, params, listener, id, version)
        } else {
            getUnsecured(url, cachedRequest, params, listener, id, version)
        }
    }

    fun post(url: String,
             listener: Listener,
             params: Parameter,
             id: String?,
             unsecured: Boolean = false) {

        val cachedRequest = CachedRequest(
            UniqueRequest(url, id, params, false),
            null,
            false,
            listener,
            "POST",
            false
        )

        if (unsecured) {
            postUnsecured(url, cachedRequest, params, listener, id)
        } else {
            postSecured(url, cachedRequest, params, listener, id)
        }
    }

    fun delete(url: String,
               listener: Listener,
               params: Parameter,
               id: String?) {

        val cachedRequest = CachedRequest(
            UniqueRequest(url, id, params, false),
            null,
            false,
            listener,
            "DELETE",
            false
        )

        deleteSecured(url, cachedRequest, params, listener, id)
    }

    private fun getSecured(url: String,
                           cachedRequest: CachedRequest,
                           parameter: Parameter,
                           listener: Listener,
                           id: String?,
                           version: Int) {
        val urlFormated = if (version == 1) {
            apiUrl + url.replace("{id}", id ?: "")
                .replace("{0}", "{${formatGetParams(parameter)}}")
        } else {
            "$apiUrl$url?${formatGetParamsV2(parameter)}"
        }

        oAuth.getAccessToken(object : OAuth.RefreshTokenListener {
            override fun onSuccess(accessToken: String) {
                val httpRequest = Request.Builder(urlFormated)
                    .addHeader("Authorization", "Bearer $accessToken")
                    //.post(RequestBody.create("application/json; charset=utf-8", ""))
                    .get()
                    .build()
                http.newAsyncCall(httpRequest) {
                    parseResponse(it, cachedRequest, listener)
                }
            }

            override fun onError(status: Int, error: ErrorRequest): Boolean {
                return listener.onError(status, error)
            }
        })
    }

    private fun getUnsecured(url: String,
                             cachedRequest: CachedRequest,
                             parameter: Parameter,
                             listener: Listener,
                             id: String?,
                             version: Int) {
        val urlFormated = if (version == 1) {
            apiUrl + url.replace("{id}", id ?: "")
                .replace("{0}", "{${formatGetParams(parameter)}}")
        } else {
            "$apiUrl$url?${formatGetParamsV2(parameter)}"
        }

        val httpRequest = Request.Builder(urlFormated)
            .get()
            .build()
        http.newAsyncCall(httpRequest) {
            parseResponse(it, cachedRequest, listener)
        }
    }

    private fun postSecured(url: String,
                            cachedRequest: CachedRequest,
                            parameter: Parameter,
                            listener: Listener,
                            id: String?) {
        oAuth.getAccessToken(object : OAuth.RefreshTokenListener {
            override fun onSuccess(accessToken: String) {
                val httpRequest = Request.Builder( apiUrl + url.replace("{id}", id ?: ""))
                    .addHeader("Authorization", "Bearer $accessToken")
                    .post(RequestBody.create("application/json; charset=utf-8", parameter.toJson()))
                    .build()
                http.newAsyncCall(httpRequest) {
                    parseResponse(it, cachedRequest, listener)
                }
            }

            override fun onError(status: Int, error: ErrorRequest): Boolean {
                return listener.onError(status, error)
            }
        })
    }

    private fun postUnsecured(url: String,
                              cachedRequest: CachedRequest,
                              parameter: Parameter,
                              listener: Listener,
                              id: String?) {
        val httpRequest = Request.Builder( apiUrl + url.replace("{id}", id ?: ""))
            .post(RequestBody.create("application/json; charset=utf-8", parameter.toJson()))
            .build()
        http.newAsyncCall(httpRequest) {
            parseResponse(it, cachedRequest, listener)
        }
    }

    private fun deleteSecured(url: String,
                              cachedRequest: CachedRequest,
                              parameter: Parameter,
                              listener: Listener,
                              id: String?) {
        oAuth.getAccessToken(object : OAuth.RefreshTokenListener {
            override fun onSuccess(accessToken: String) {
                val httpRequest = Request.Builder( apiUrl + url.replace("{id}", id ?: ""))
                    .addHeader("Authorization", "Bearer $accessToken")
                    .delete(RequestBody.create("application/json; charset=utf-8", parameter.toJson()))
                    .build()
                http.newAsyncCall(httpRequest) {
                    parseResponse(it, cachedRequest, listener)
                }
            }

            override fun onError(status: Int, error: ErrorRequest): Boolean {
                return listener.onError(status, error)
            }
        })
    }

    private fun parseResponse(httpResponse: Http.Response,
                              cachedRequest: CachedRequest,
                              listener: Listener) {

        val response = parseToJsonElement(httpResponse.body)
        //val test = Json.parseToJsonElement("{\"status\":null,\"userId\":1,\"fee\":0.0350000000000000033306690738754696212708950042724609375,\"fee_fix\":30,\"isPaymentEnabled\":true,\"user\":\"Francis\",\"username\":\"francis-delaunay\",\"email\":\"francis.delaunay@hotmail.fr\",\"language\":\"en\",\"isNewsletterEnabled\":false,\"isEmailNotificationEnabled\":false,\"isPublicAccount\":false,\"isPrivateParticipation\":true,\"isAutoAcceptContact\":true,\"isTrackingEnabled\":false,\"isPaymentAssociated\":true,\"image\":\"https:\\/\\/media-dev.wicupp.com\\/user\\/0\\/5_d681dfe26c09c17a486b6fc77ab69f0d\",\"score\":{\"points\":23,\"organization\":23,\"participation\":0,\"participant\":0},\"friends\":[],\"api_status\":\"NOK\"}")

        //println("status: ${getStringOrNull(test.jsonObject["status"])}")
        //println("userId: ${test.jsonObject["userId"])}")*/

        try {
            if(getObjectOrNull(response)?.get("isWrongFormat") == null && httpResponse.httpStatus == 200){
                if(cachedRequest.cache != null) {
                    // Return response only if it's different from cache
                    if(Utils.generateHash(httpResponse.body) != cachedRequest.cache) {
                        listener.onSuccess(response)
                    }
                } else {
                    listener.onSuccess(response)
                }

                addRequestToCache(httpResponse.body, cachedRequest)
            } else {
                val isRefreshingToken = handleError(httpResponse, cachedRequest, listener)

                if (isRefreshingToken) {
                    // exit parsing
                    return
                }
                val error = parseError(response.jsonObject)
                val handled = listener.onError(httpResponse.httpStatus, error)

                if (!handled) {
                    // TODO show dialog ?
                }
            }

        } catch (e: Exception) {
            Logger.e(e.message ?: "Issue during http response")
        }
    }

    private fun addRequestToCache(body: String,
                                  cachedRequest: CachedRequest) {
        if(cachedRequest.cache !== null) {
            if(Utils.generateHash(body) === cachedRequest.cache) {
                return
            }
        }

        if(cachedRequest.isCached){
            storage.setItemToTable(REQUEST_STORAGE_KEY, getUniqueRequestHashed(cachedRequest.uniqueRequest), body, null)
        }
    }

    private fun parseToJsonElement(json: String?): JsonElement {
        try {
            if (json != null && json != "") {
                return Json.parseToJsonElement(json)
            } else {
                return Json.parseToJsonElement("{}")
            }
        } catch (e: Exception) {
            return Json.parseToJsonElement("{\"isWrongFormat\": true}")
        }
    }

    private fun handleError(httpResponse: Http.Response,
                            cachedRequest: CachedRequest,
                            listener: Listener): Boolean {
        if (httpResponse.httpStatus == 401) {
            oAuth.refreshToken(object : OAuth.RefreshTokenListener {
                override fun onSuccess(accessToken: String) {
                    executeRequest(cachedRequest)
                }

                override fun onError(status: Int,
                                     error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }
            })
            return true
        }
        return false
    }

    private fun executeRequest(cachedRequest: CachedRequest) {
        when (cachedRequest.type) {
            "GET" -> {
                get(cachedRequest.uniqueRequest.url,
                    cachedRequest.listener,
                    cachedRequest.uniqueRequest.params,
                    cachedRequest.uniqueRequest.id,
                    cachedRequest.uniqueRequest.isCacheEnabled
                )
            }
            "POST" -> {
                post(cachedRequest.uniqueRequest.url,
                    cachedRequest.listener,
                    cachedRequest.uniqueRequest.params,
                    cachedRequest.uniqueRequest.id
                )
            }
            "DELETE" -> {
                delete(cachedRequest.uniqueRequest.url,
                    cachedRequest.listener,
                    cachedRequest.uniqueRequest.params,
                    cachedRequest.uniqueRequest.id
                )
            }
        }
    }

    private fun getUniqueRequestHashed(uniqueRequest: UniqueRequest): String {
        var uniqueParameters = ""
        uniqueRequest.params.parameters.forEach { uniqueParameters += it.key+it.value }
        return Utils.generateHash(uniqueRequest.url +
                uniqueRequest.id + uniqueRequest.isCacheEnabled + uniqueParameters)
    }

    data class CachedRequest(val uniqueRequest: UniqueRequest,
                             var cache: String?,
                             val isCached: Boolean,
                             val listener: Listener,
                             val type: String,
                             val isExpired: Boolean)

    data class UniqueRequest(val url: String,
                        val id: String?,
                        val params: Parameter,
                        val isCacheEnabled: Boolean)

    interface Listener {
        fun onSuccess(body: JsonElement)
        fun onError(status: Int, error: ErrorRequest): Boolean
    }
}