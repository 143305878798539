package com.wicupp.safetymap.dashboard.board.refuge.edit.categories

import com.wicupp.artifice.toast.Toast
import com.wicupp.artifice.toast.ToastDuration
import com.wicupp.beaver.core.logger.Logger
import com.wicupp.beaver.web.view.component.ListView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.safetymap.dashboard.data.model.Refuge

class RefugeCategoriesView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "refuge-categories-view"
    }

    override fun layoutName() = "view-categories-refuge"

    private var categoryList: ListView? = null
    private var adapter: RefugeCategoriesAdapter = RefugeCategoriesAdapter()

    var listener: Listener? = null
    var selectedCategories: List<Refuge.Category>? = null
        set(value) {
            value?.let {
                showCategories(value)
            }
            field = value
        }

    override fun onCreate() {
        super.onCreate()

        categoryList = findViewById("view-categories-refuge-list")

        selectedCategories?.let {
            showCategories(it)
        }

        adapter.listener = object : RefugeCategoriesAdapter.Listener {
            override fun onChanged(category: Refuge.Category, selected: Boolean) {
                selectedCategories = selectedCategories?.toMutableList()?.apply {
                    remove(Refuge.Category.UNKNOWN)
                    remove(category)
                    if (selected) {
                        add(category)
                    }
                    listener?.onChange(this)
                }
            }

            override fun onClickWhenDisabled(category: Refuge.Category) {
                Toast().show("_This category can't be selected with one of the other selected category.", ToastDuration.SHORT)
            }
        }
    }

    private fun showCategories(selectedCategories: List<Refuge.Category>) {
        adapter.setCategories(
            Refuge.Category.entries.filter { it != Refuge.Category.UNKNOWN },
            selectedCategories
        )
        categoryList?.setAdapter(adapter)
    }

    interface Listener {
        fun onChange(selectedCategories: List<Refuge.Category>)
    }
}