package com.wicupp.beaver.web.view.layout

import com.wicupp.beaver.web.view.LayoutDefine
import com.wicupp.beaver.web.view.component.View
import com.wicupp.beaver.web.view.component.JSView

abstract class Layout(private val cv: ContextView) : View {

    @JsName("layoutName")
    abstract fun layoutName(): String

    companion object {
        fun <T: Layout> createLayout(cls: JsClass<T >): T {
            return LayoutDefine.createElement(
                LayoutDefine.getTagName(
                    cls
                )
            ).toView() as T
        }
    }

    @JsName("isViewAttached")
    open fun isViewAttached(): Boolean {
        return cv.isViewAttached()
    }

    @JsName("getJsView")
    open fun getJsView(): JSView {
        return cv
    }

    @JsName("onLayoutLoaded")
    open fun onLayoutLoaded() {

    }

    @JsName("onLayoutLoadFailed")
    open fun onLayoutLoadFailed() {

    }

    @JsName("onCreate")
    open fun onCreate() {
    }

    @JsName("onAttached")
    open fun onAttached() {
    }

    @JsName("onDetached")
    open fun onDetached() {
    }

    override fun collapseHeight(collapse: Boolean, animationDelay: Int) {
        cv.collapseHeight(collapse, animationDelay)
    }

    override fun addClass(className: String) {
        cv.addClass(className)
    }

    override fun removeClass(className: String) {
        cv.removeClass(className)
    }

    override fun isVisible(): Boolean {
        return cv.isVisible()
    }

    override fun onClick(onClick: () -> Unit) {
        return cv.onClick(onClick)
    }

    override fun attach() {
        cv.attach()
    }

    override fun detach() {
        cv.detach()
    }

    override fun setVisibility(value: String) {
        cv.setVisibility(value)
    }

    override fun setVisibility(value: String,
                               askFading: Boolean) {
        cv.setVisibility(value, askFading)
    }

    override fun setVisibility(value: String,
                               askFading: Boolean,
                               onFinished: () -> Unit) {
        cv.setVisibility(value, askFading, onFinished)
    }

    override fun findJsViewById(id: String): JSView {
        return cv.findViewById(id) as? JSView
            ?: throw NoSuchElementException("The resource $id doesn't exist.")
    }

    override fun <T> findViewById(id: String): T {
        return cv.findViewById(id) as? T
            ?: throw NoSuchElementException("The resource $id doesn't exist.")
    }

    @JsName("getString")
    fun getString(key: String, vararg args: String?): String {
        return cv.getString(key, arrayOf(*args))
    }

    @JsName("getColor")
    fun getColor(key: String): String {
        return cv.getColor(key)
    }

    @JsName("getCustomData")
    fun getCustomData(data: String): String? {
        return cv.getCustomData(data)
    }

    abstract class LayoutDefinition {
        abstract val layouts: List<Def>

        fun define() {
            layouts.forEach {
                LayoutDefine.add(it.cls, it.tag)
            }
        }
    }

    data class Def(val cls: JsClass<out Layout>,
                   val tag: String,
                   val preload: Boolean = false)
}