package com.wicupp.safetymap.dashboard.data.flux

import com.wicupp.beaver.flux.Action
import com.wicupp.beaver.flux.CoreModel
import com.wicupp.beaver.flux.Dispatcher
import com.wicupp.beaver.flux.Handler
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.beaver.core.task.Task
import com.wicupp.beaver.request.ApiRequest
import com.wicupp.safetymap.dashboard.data.api.AdminApi
import com.wicupp.safetymap.dashboard.data.flux.action.*
import com.wicupp.safetymap.dashboard.data.flux.handler.*
import com.wicupp.safetymap.dashboard.data.flux.notifier.RefugesNotifier
import com.wicupp.safetymap.dashboard.data.flux.notifier.UserNotifier
import com.wicupp.safetymap.dashboard.data.model.Model

class GlobalDispatcher(
    userNotifier: UserNotifier,
    private val refugesNotifier: RefugesNotifier,
    private val apiRequest: ApiRequest,
    private val adminApi: AdminApi
) : Dispatcher() {
    private val globalNotifier: GlobalNotifier
            = GlobalNotifier(createModelInit(), listOf(userNotifier, refugesNotifier))

    fun prepare(): PrepareDispatcher {
        return PrepareDispatcher(this)
    }

    fun <T: Action> dispatch(action: T) {
        dispatch(action, {}, {_,_ -> })
    }

    fun <T: Action> dispatch(action: T,
                             onSuccess: () -> Unit,
                             onError: (status: Int, error: ErrorRequest) -> Unit): Handler<T, Model> {
        val handler = when (action) {
            is GetUserAction -> {
                GetUserHandler(this, globalNotifier, apiRequest)
                    .action(action)
            }
            is EditUserAction -> {
                EditUserHandler(this, globalNotifier, apiRequest)
                    .action(action)
            }
            is ClearUserAction -> {
                ClearUserHandler(this, globalNotifier)
                    .action(action)
            }
            is GetRefugesAction -> {
                GetRefugesHandler(this, globalNotifier, adminApi)
                    .action(action)
            }
            is DeleteRefugeAction -> {
                DeleteRefugeHandler(this, globalNotifier, adminApi, refugesNotifier)
                    .action(action)
            }
            is CreateProjectAction -> {
                CreateProjectHandler(this, globalNotifier, adminApi)
                    .action(action)
            }
            is UpdateProjectAction -> {
                UpdateProjectHandler(this, globalNotifier, adminApi)
                    .action(action)
            }
            else -> throw ClassCastException("The action $action is not handled.")
        }

        handler.onSuccess {
            onSuccess()
        }

        handler.onError { status, error ->
            onError(status, error)
        }

        Task.async {
            handler.dispatch()
        }
        return handler as Handler<T, Model>
    }

    override fun createModelInit(): CoreModel {
        return Model.Builder().build()
    }
}