package com.wicupp.safetymap.dashboard.board.report

import com.wicupp.beaver.core.task.Handler
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.safetymap.dashboard.data.api.AdminApi
import com.wicupp.safetymap.dashboard.data.api.ApiListener
import com.wicupp.safetymap.dashboard.data.api.ApiResponse
import com.wicupp.safetymap.dashboard.data.flux.GlobalDispatcher
import com.wicupp.safetymap.dashboard.data.flux.notifier.RefugesNotifier
import com.wicupp.safetymap.dashboard.data.flux.notifier.UserNotifier
import com.wicupp.safetymap.dashboard.error.ErrorManager

/**
 * [ReportIncidentContract.Presenter] implementation.
 */
class ReportIncidentPresenterImpl(
    private val handler: Handler,
    private val adminApi: AdminApi,
    private val refugesNotifier: RefugesNotifier,
    private val userNotifier: UserNotifier
) : ReportIncidentContract.Presenter {

    private var view: ReportIncidentContract.View? = null

    override fun attachView(view: ReportIncidentContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
    }

    override fun detachView(view: ReportIncidentContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun reportIncident(
        latitude: Double,
        longitude: Double,
        message: String
    ) {
        view?.showLoadingIndicator(true)
        adminApi.reportIncident(
            latitude,
            longitude,
            message,
            object : ApiListener<Unit> {
                override fun onSuccess(apiResponse: ApiResponse<Unit>) {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        view?.showReportIncidentsSent()
                    }
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    handler.post {
                        view?.showLoadingIndicator(false)
                        ErrorManager.formatRequestError(status, error)?.let {
                            view?.showError(it)
                        }
                    }
                    return true
                }
            }
        )
    }

    override fun requestSharingView() {
        // TODO
        userNotifier.user?.projects?.firstOrNull()?.id?.let { projectId ->
            view?.showSharingView(projectId)
        }
    }
}
