package com.wicupp.safetymap.dashboard.board.refuge

import com.wicupp.beaver.web.view.component.ButtonView
import com.wicupp.beaver.web.view.layout.ContextView
import com.wicupp.beaver.web.view.layout.Layout

class RefugeMenuView(cv: ContextView): Layout(cv) {

    companion object {
        const val TAG_NAME = "app-refuge-menu"
    }

    override fun layoutName() = "view-refuge-menu"

    var listener: Listener? = null

    override fun onCreate() {
        super.onCreate()

        findViewById<ButtonView>("view-refuge-menu-edit-button").onClick {
            listener?.onEditRefugeRequested()
        }

        findViewById<ButtonView>("view-refuge-menu-delete-button").onClick {
            listener?.onDeleteRefugeRequested()
        }
    }

    interface Listener {
        fun onEditRefugeRequested()
        fun onDeleteRefugeRequested()
    }
}