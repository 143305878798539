package com.wicupp.safetymap.dashboard.data.api

import com.wicupp.beaver.request.ApiRequest
import com.wicupp.beaver.request.ErrorRequest
import com.wicupp.beaver.request.Parameter
import com.wicupp.beaver.utils.JsonUtils
import com.wicupp.safetymap.dashboard.data.model.*
import com.wicupp.safetymap.dashboard.utils.OpeningHoursPeriod
import com.wicupp.safetymap.dashboard.utils.OpeningHoursPeriodDetail
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.jsonObject

class AdminApiImpl(
    private val apiRequest: ApiRequest
): AdminApi {

    override fun getRefuges(
        projectId: String,
        listener: ApiListener<List<Refuge>>
    ) {

        val parameters = Parameter.Builder().apply {
            addParameter("project_id", projectId)
        }

        apiRequest.get(ApiUrl.PROJECT_REFUGES,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {

                    val refuges = mutableListOf<Refuge>()

                    JsonUtils.getArrayOrNull(body.jsonObject["refuges"])?.forEach { value ->
                        val openingPeriods = JsonUtils.getArray(value.jsonObject["opening_periods"]).map {
                            val open = JsonUtils.getObjectOrNull(it.jsonObject["open"])
                            val close = JsonUtils.getObjectOrNull(it.jsonObject["close"])

                            OpeningHoursPeriod(
                                open = OpeningHoursPeriodDetail(
                                    day = JsonUtils.getInt(open!!.jsonObject["day"]),
                                    time = JsonUtils.getString(open.jsonObject["time"])
                                ),
                                close = close?.let {
                                    OpeningHoursPeriodDetail(
                                        day = JsonUtils.getInt(close.jsonObject["day"]),
                                        time = JsonUtils.getString(close.jsonObject["time"])
                                    )
                                }
                            )
                        }.toTypedArray()

                        val timezoneId = JsonUtils.getString(value.jsonObject["timezone_id"])

                        val categories = JsonUtils.getArrayOrNull(value.jsonObject["categories"])?.map {
                            Refuge.Category.fromString(JsonUtils.getString(it))
                        }?.filter { it != Refuge.Category.UNKNOWN }
                        ?.toTypedArray() ?: arrayOf()

                        val metropolis = Refuge.Metropolis.fromString(JsonUtils.getString(value.jsonObject["metropolis"]))

                        refuges.add(Refuge(
                            JsonUtils.getString(value.jsonObject["id"]),
                            JsonUtils.getString(value.jsonObject["name"]),
                            JsonUtils.getString(value.jsonObject["street"]),
                            JsonUtils.getDouble(value.jsonObject["longitude"]),
                            JsonUtils.getDouble(value.jsonObject["latitude"]),
                            timezoneId,
                            openingPeriods.toList(),
                            categories.toList(),
                            metropolis
                        ))
                    }

                    listener.onSuccess(
                        AdminApi.RefugesResponse(refuges)
                    )
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }

            }, parameters.build(),
            null,
            isCacheEnabled = false,
            version = 2
        )
    }

    override fun createRefuge(
        projectId: String,
        refuge: Refuge,
        listener: ApiListener<String>
    ) {
        val parameters = getRefugeParameterBuilder(refuge).run {
            addParameter("project_id", projectId)
            build()
        }

        apiRequest.post(ApiUrl.PROJECT_CREATE_REFUGE,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    val refugeId = body.jsonObject["id"]
                    listener.onSuccess(
                        AdminApi.CreateRefugeResponse(
                            JsonUtils.getString(
                                refugeId
                            )
                        )
                    )
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }

            },
            parameters,
            null
        )
    }

    override fun updateRefuge(
        refuge: Refuge,
        listener: ApiListener<Unit>
    ) {

        val parameters = getRefugeParameterBuilder(refuge).run {
            addParameter("refuge_id", refuge.id)
            build()
        }

        apiRequest.post(ApiUrl.PROJECT_UPDATE_REFUGE,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    listener.onSuccess(
                        UnitResponse(Unit)
                    )
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }

            },
            parameters,
            null
        )
    }

    override fun deleteRefuge(
        refugeId: String,
        listener: ApiListener<Unit>
    ) {
        apiRequest.delete(
            ApiUrl.PROJECT_DELETE_REFUGE,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    listener.onSuccess(
                        UnitResponse(Unit)
                    )
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }

            },
            Parameter.Builder().build(),
            refugeId
        )
    }

    override fun createProject(
        projectName: String,
        listener: ApiListener<String>
    ) {
        val parameters = Parameter.Builder().apply {
            addParameter("name", projectName)
        }

        apiRequest.post(ApiUrl.CREATE_DASHBOARD_PROJECT,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    val projectId = body.jsonObject["id"]
                    listener.onSuccess(
                        AdminApi.CreateProjectResponse(
                            JsonUtils.getString(
                                projectId
                            )
                        )
                    )
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }

            }, parameters.build(),
            null
        )
    }

    override fun updateProject(
        projectId: String,
        projectName: String,
        listener: ApiListener<Unit>
    ) {
        val parameters = Parameter.Builder().apply {
            addParameter("name", projectName)
        }

        apiRequest.post(ApiUrl.UPDATE_DASHBOARD_PROJECT,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    listener.onSuccess(
                        UnitResponse(Unit)
                    )
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }

            }, parameters.build(),
            projectId
        )
    }

    override fun reportIncident(
        latitude: Double,
        longitude: Double,
        message: String,
        listener: ApiListener<Unit>
    ) {
        val parameters = Parameter.Builder().apply {
            addParameter("latitude", latitude)
            addParameter("longitude", longitude)
            addParameter("message", message)
        }

        apiRequest.post(ApiUrl.REPORT_INCIDENT,
            object : ApiRequest.Listener {
                override fun onSuccess(body: JsonElement) {
                    listener.onSuccess(UnitResponse())
                }

                override fun onError(status: Int, error: ErrorRequest): Boolean {
                    return listener.onError(status, error)
                }

            }, parameters.build(),
            null
        )
    }

    private fun getRefugeParameterBuilder(
        refuge: Refuge
    ): Parameter.Builder {
        return Parameter.Builder().apply {
            addParameter("name", refuge.name)
            addParameter("street", refuge.street)
            addParameter("latitude", refuge.latitude)
            addParameter("longitude", refuge.longitude)
            addParameter("categories", refuge.categories.map {
                it.value
            }.toTypedArray())
            addParameter("timezone", refuge.timezone)
            addParameter("opening_periods", refuge.openingPeriods.map {
                Parameter.Builder().run {
                    addParameter("open", Parameter.Builder().run {
                        addParameter("day", it.open.day)
                        addParameter("time", it.open.time)
                        build()
                    })

                    it.close?.let { close ->
                        addParameter("close", Parameter.Builder().run {
                            addParameter("day", close.day)
                            addParameter("time", close.time)
                            build()
                        })
                    }
                    build()
                }
            }.toTypedArray())
        }
    }
}