package com.wicupp.safetymap.dashboard.data.flux.handler

import com.wicupp.beaver.flux.*
import com.wicupp.beaver.request.ApiRequest
import com.wicupp.safetymap.dashboard.data.flux.action.EditUserAction
import com.wicupp.safetymap.dashboard.data.model.Model

class EditUserHandler(dispatcher: Dispatcher,
                      rootNotifier: RootNotifier,
                      private val apiRequest: ApiRequest
) : Handler<EditUserAction, Model>(dispatcher, rootNotifier) {

    override fun execute(model: Model, action: EditUserAction): EditUserHandler {

        //TODO

        return this
    }
}