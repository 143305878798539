package com.wicupp.safetymap.dashboard.board.project

import com.wicupp.beaver.core.task.Handler
import com.wicupp.safetymap.dashboard.data.flux.GlobalDispatcher
import com.wicupp.safetymap.dashboard.data.flux.action.UpdateProjectAction
import com.wicupp.safetymap.dashboard.data.flux.notifier.RefugesNotifier
import com.wicupp.safetymap.dashboard.data.flux.notifier.UserNotifier
import com.wicupp.safetymap.dashboard.error.ErrorManager

/**
 * [ProjectSettingsContract.Presenter] implementation.
 */
class ProjectSettingsPresenterImpl(
    private val handler: Handler,
    private val globalDispatcher: GlobalDispatcher,
    private val userNotifier: UserNotifier
) : ProjectSettingsContract.Presenter {

    private var view: ProjectSettingsContract.View? = null

    override fun attachView(view: ProjectSettingsContract.View) {
        this.view?.let {
            detachView(it)
        }
        this.view = view
    }

    override fun detachView(view: ProjectSettingsContract.View) {
        if (this.view === view) {
            this.view = null
        }
    }

    override fun requestProject() {
        userNotifier.user?.projects?.firstOrNull()?.let { project ->
            handler.post {
                view?.showLoadingIndicator(false)
                view?.showProject(project)
            }
        }
    }

    override fun updateProjectName(name: String) {
        view?.showLoadingIndicator(true)

        val projectId = userNotifier.user?.projects?.firstOrNull()?.id ?: throw IllegalStateException("ProjectId is null.")

        globalDispatcher.prepare()
            .onSuccess {
                userNotifier.user?.projects?.firstOrNull()?.let { project ->
                    handler.post {
                        view?.showLoadingIndicator(false)
                        view?.showProject(project)
                    }
                }
            }
            .onError { status, error ->
                handler.post {
                    view?.showLoadingIndicator(false)
                    ErrorManager.formatRequestError(status, error)?.let {
                        view?.showError(it)
                    }
                }
            }
            .dispatch(UpdateProjectAction(projectId, name))
    }
}
