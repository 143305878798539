package com.wicupp.beaver.request.http

interface Http {
    fun setRequestTimeout(timeoutMs: Int)

    fun newAsyncCall(request: Request,
                     onResponse: (response: Response) -> Unit)

    fun newSyncCall(request: Request): Response

    data class Response( val body: String, val httpStatus: Int)
}

