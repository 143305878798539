package com.wicupp.safetymap.dashboard.board.refuge.edit.address.item

import com.wicupp.beaver.web.view.layout.Adapter
import com.wicupp.beaver.web.view.layout.Layout
import com.wicupp.beaver.web.view.component.View
import com.wicupp.safetymap.dashboard.board.refuge.edit.address.model.Address

class SearchAddressAdapter : Adapter() {

    var listener: Listener? = null

    fun setAddresses(addresses: List<Address>) {
        removeAllViews()

        for (address: Address in addresses) {
            addView(buildView(address))
        }
    }

    private fun buildView(address: Address): View {
        val item = Layout.createLayout(SearchAddressItemView::class.js)
        item.address = address.street

        item.onClick {
            listener?.onAddressSelected(address)
        }
        return item
    }

    interface Listener {
        fun onAddressSelected(address: Address)
    }
}